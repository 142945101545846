<template>
  <div class="personal-home">
    <div class="personal-header">
      <div class="avatar-widget">
        <div @click="editUserImgAct" class="switch-company">
          <i style="font-size: 18px; color: #84501e" class="el-icon-upload"></i>
          <span>上传头像</span>
        </div>
        <div class="avatar-block">
          <div class="avatar-image">
            <span class="el-avatar el-avatar--large el-avatar--circle">
              <img
                v-if="userInfo.img && userInfo.img !== ''"
                :src="userInfo.img"
                alt=""
                style="object-fit: cover"
              />
              <img
                v-else
                :src="`${$configOptions.resourcePath}/config/logo/logo.png`"
                alt=""
                style="object-fit: cover"
              />
            </span>
          </div>
          <div class="avatar-content">
            <p :title="userInfo.name" class="company-name">
              {{ userInfo.name }}
            </p>
            <!-- <p>联系人：{{
              userInfo.linkman && userInfo.linkman !== ""
              ? userInfo.linkman
              : "暂无联系人信息"
            }}</p>
            <p>联系电话：{{
              userInfo.phone && userInfo.phone !== ""
              ? userInfo.phone
              : "暂无联系方式"
            }}</p>

            <p>
              业务员：{{
                userInfo.salesmanName && userInfo.salesmanName !== ""
                ? userInfo.salesmanName
                : "暂无绑定业务员"
              }}
            </p> -->
            <div class="links-manage">
              <a
                style="margin-right: 27px"
                href="JavaScript:;"
                class="el-link el-link--default"
              >
                <span @click="editPassWord" class="el-link--inner">
                  修改登录密码<i class="el-icon-arrow-right"></i>
                </span>
              </a>
              <a href="/addressList" class="el-link el-link--default">
                <span class="el-link--inner">
                  地址管理
                  <i class="el-icon-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="avatar-bottom">
          <div class="ca-status">
            <router-link to="/ticketList">
              <span>我的优惠券</span><span class="el-icon-arrow-right"> </span>
            </router-link>
          </div>
          <div class="license-status">
            <p>
              <span class="el-link--inner"
                >联系电话：
                <span class="license-num">{{
                  userInfo.phone ? userInfo.phone : "暂无联系方式"
                }}</span>
              </span>
              <i @click="editBindingPhone" class="el-icon-edit"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="other-info">
        <div class="info-left">
          <div class="arrears">
            <span class="balance-num balance-num2">{{ userIntegral }}</span>
            <div class="mt">
              <span>我的积分</span>
              <router-link to="/integralCenter">
                <span class="btn">去签到</span>
              </router-link>
            </div>
          </div>
          <div class="line"></div>
          <div class="balance">
            <span class="balance-num">{{ balanceData.balanceMoney }}</span>
            <div class="mt">
              <span>钱包余额</span>
              <div>
                <a @click="openTip('功能开发中,尽情期待')" href="javascript:;">
                  <span class="btn">充值</span>
                </a>
                <a @click="openTip('功能开发中,尽情期待')" href="javascript:;">
                  <span class="btn">查看明细</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="no_pingan">
          <div class="share-code">
            <p>
              <span class="el-link--inner"
                >邀请码：<span class="license-num">{{ salesmanInfo.id ? salesmanInfo.id : userInfo.id }}</span>
              </span>
              <i
                title="复制邀请码"
                :data-clipboard-text="salesmanInfo.id ? salesmanInfo.id : userInfo.id"
                @click="onCopyText('el-icon-document-copy')"
                class="el-icon-document-copy"
              ></i>
            </p>
          </div>
          <img src="~@/assets/images/center/userCenter/jinbi.png" alt="" />
        </div>
      </div>
    </div>
    <div class="order-nav">
      <div class="navbar">
        <div
          v-for="(item, index) in orderStatus"
          :key="index"
          @click="routeOrderList(item)"
          class="order-item"
        >
          <img
            v-if="item.status === 1"
            src="~@/assets/images/center/dph.png"
            :alt="item.name"
          />
          <img
            v-if="item.status === 2"
            src="~@/assets/images/center/dcl.png"
            :alt="item.name"
          />
          <img
            v-if="item.status === 3"
            src="~@/assets/images/center/ywc.png"
            :alt="item.name"
          />
          <img
            v-if="item.status === -1"
            src="~@/assets/images/center/shdd.png"
            :alt="item.name"
          />
          <img
            v-if="item.status === 0"
            src="~@/assets/images/center/qbdd.png"
            :alt="item.name"
          />
          <span class="status-name">{{ item.name }}</span>
          <span v-if="item.count && item.count > 0" class="icon-count">{{
            item.count
          }}</span>
        </div>
      </div>
      <div class="receive-info">
        <el-popover
          placement="top-start"
          trigger="hover"
          :content="defaultAddress.contactsTel"
        >
          <span slot="reference"
            >联系电话：{{ defaultAddress.contactsTel }}</span
          >
        </el-popover>
        <el-popover
          placement="top-start"
          trigger="hover"
          :content="defaultAddress.address"
        >
          <span slot="reference">收货地：{{ defaultAddress.address }}</span>
        </el-popover>
        <el-popover
          placement="top-start"
          trigger="hover"
          :content="defaultAddress.contactsName"
        >
          <span slot="reference"
            >收货人：{{ defaultAddress.contactsName }}</span
          >
        </el-popover>
        <el-link class="link-more" href="/addressList" target="_blank"
          >更多<i class="el-icon-arrow-right"></i
        ></el-link>
      </div>
    </div>

    <div class="list-content">
      <div class="query-item">
        <div class="order-detail common-head-text">订单详情</div>
        <div class="order-from common-head-text">订单来源</div>
        <div class="order-status common-head-text">订单状态</div>
        <div class="operate common-head-text">操作</div>
      </div>
      <div v-loading="orderLoading" class="list-table">
        <ul class="ul-cont" v-if="orderList.length > 0">
          <li
            class="li-cont"
            v-for="(item, index) of orderList"
            :key="item.id + '_' + index"
          >
            <div class="order_item_container">
              <div class="order_content">
                <div class="order_detail_content normal_box">
                  <div class="gray_head">
                    <div class="order_time">{{ item.createTime }}</div>
                    <div class="order_code_box">
                      <a class="order_code" @click="navToOrderAct(item)"
                        >订单号：{{ item.id }}</a
                      >
                      <a class="bank-card-copy">
                        <span
                          :data-clipboard-text="item.id"
                          @click="onCopyText('el-icon-document-copy')"
                          class="el-icon-document-copy"
                        ></span
                      ></a>
                    </div>
                  </div>
                  <div class="detail_box">
                    <div v-if="false" class="shop_img">
                      <img
                        src="https://s.yyjzt.com/jzt-item-web/19000101/EJA001033C/EJA001033C.JPG"
                        alt=""
                      />
                    </div>
                    <div class="order_info">
                      <span>实付金额：¥{{ item.payMoney }}</span>
                      <span
                        >支付方式：{{
                          item.feeType == 1 ? "线下支付" : "在线支付"
                        }}</span
                      >
                      <span v-if="false"
                        >共{{ item.saleOrderItemCount }}件商品</span
                      >
                    </div>
                  </div>
                </div>
                <div class="order_source_content normal_box">
                  <div class="gray_head">
                    <div class="store_name_info">
                      <span>
                        <div
                          role="tooltip"
                          id="el-popover-1371"
                          aria-hidden="true"
                          class="el-popover el-popper el-popover--plain"
                          tabindex="0"
                          style="display: none"
                        ></div>
                        <span class="el-popover__reference-wrapper"
                          ><span
                            class="store_name el-popover__reference"
                            aria-describedby="el-popover-1371"
                            tabindex="0"
                            >{{$configOptions.appName}}严选{{ item.sourceName }}端</span
                          ></span
                        >
                      </span>
                    </div>
                  </div>
                  <div class="detail_box">
                    <div class="order_source">线上订单</div>
                  </div>
                </div>
              </div>
              <div class="order_status_content">
                <div class="order_status_inner">
                  <div
                    class="order_status_text common"
                    style="color: rgb(255, 138, 0)"
                  >
                    {{ item.orderStatusName }}
                  </div>
                  <!---->
                  <!-- <div class="lg_btn">
                    <div @click="navToOrderAct(item)" class="nav-to-detail">查看详情</div> <span class="ls"></span>
                    <div><span class="el-popover__reference-wrapper"><span class="el-popover__reference"
                          aria-describedby="el-popover-1423" tabindex="0">查看物流</span></span>
                    </div>
                  </div> -->
                  <!-- <div class="common_btn">
                    下载报告单
                  </div>
                  <div class="common_btn download-style">
                    下载首营资料
                  </div> -->
                </div>
              </div>
              <div class="order_operate_content">
                <div class="order_operate_box">
                  <div class="common">
                    <button
                      v-if="item.nextFlow === 'confirm'"
                      @click.stop="confirmOrderClick(item)"
                      type="button"
                      class="el-button operate-common-btn btn-cont-f"
                    >
                      <span> 确认订单 </span>
                    </button>

                    <button
                      v-if="item.nextFlow === 'pay'"
                      @click.stop="payOrderClick(item)"
                      type="button"
                      class="el-button operate-common-btn btn-cont-f"
                    >
                      <span> 支付订单 </span>
                    </button>
                  </div>

                  <div
                    v-if="item.optCancelFlag === '1'"
                    @click.stop="cencalOrder(item)"
                    class="operate_common_btn"
                  >
                    取消订单
                  </div>
                  <div @click="navToOrderAct(item)" class="operate_common_btn">
                    查看详情
                  </div>
                  <!-- <div class="operate_common_btn">再次购买</div> -->
                  <!-- <div class="operate_common_btn">申请售后</div>
                  <div class="operate_common_btn">
                    评价
                  </div> -->
                </div>
              </div>
            </div>
            <div class="order-remark">
              <el-alert
                :closable="false"
                :title="`订单备注：${item.remark}`"
                type="warning"
              ></el-alert>
            </div>
          </li>
        </ul>
        <div v-else>
          <div class="dataNone">
            <div class="dataImg orderNoneImg"></div>
          </div>
        </div>
      </div>

      <div class="pagination-bottom" style="padding-bottom: 50px">
        <el-pagination
          background
          :hide-on-single-page="true"
          v-if="totalCount > 5"
          layout="prev, pager, next, jumper"
          :current-page="pageNo"
          @current-change="currentChange"
          :page-size="pageSize"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <div v-if="editStatus" class="editMask">
      <div class="mask"></div>
      <div v-if="editType === 1" class="editContentBox">
        <div class="editPwdBox editContent">
          <div class="editHeader">
            修改密码
            <i class="el-icon-close" @click="closeEditPwd"></i>
          </div>
          <div class="changeFlowPwdBox">
            <div class="phoneNumberInput">
              <img
                class="phoneIcon"
                src="../assets/images/login/mobliePhone.png"
                alt=""
              />
              <input
                disabled="true"
                class="phoneNumber"
                v-model="userInfo.phone"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                type="text"
              />
            </div>

            <div v-if="showVerifyImgCode" class="verifyCodeBox">
              <div class="verifyCode">
                <img
                  class="phoneIcon"
                  src="../assets/images/login/msgIcon.png"
                  alt=""
                />
                <input
                  placeholder="请填写图形验证码"
                  ref="verifyCode"
                  @keyup.enter="changeInputFocus"
                  v-model="verifyCode"
                  type="text"
                />
              </div>
              <div
                @click="taggleVerfyImg"
                title="看不清？换一张"
                class="getVerfiyCodeBtn verfy-img"
              >
                <img v-if="verifyImg" :src="verifyImg" alt="" />
                <img
                  v-else
                  src="../assets/images/login/verfiyCodeImg.png"
                  alt=""
                />
              </div>
            </div>
            <div class="verifyCodeBox">
              <div class="verifyCode">
                <img
                  class="phoneIcon"
                  src="../assets/images/login/msgIcon.png"
                  alt=""
                />
                <input
                  v-model="verifyCode2"
                  placeholder="短信验证码"
                  type="text"
                />
              </div>
              <button
                :class="!verfiyState ? 'get-code-btn' : 'disabled-code-btn'"
                :disabled="verfiyState ? true : false"
                @click="phoneVerify"
                class="getVerfiyCodeBtn"
              >
                {{ verfiyState ? `${timeNumber}S再获取` : "获取验证码" }}
              </button>
            </div>

            <div class="passwordInput">
              <img
                class="passwordIcon"
                src="../assets/images/login/passwordIcon.png"
                alt=""
              />
              <input
                v-model="newPassword"
                class="password"
                ref="password"
                placeholder="请输入新密码"
                :type="changeShowPwd ? 'text' : 'password'"
              />
              <div class="changeShowPwd" v-if="!hasIeBrowser">
                <img
                  @click="changeShowPwd = true"
                  title="显示密码"
                  v-if="!changeShowPwd"
                  src="../assets/images/login/closePwd.png"
                  alt="显示密码"
                />
                <img
                  @click="changeShowPwd = false"
                  title="隐藏密码"
                  v-else
                  src="../assets/images/login/openPwd.png"
                  alt="隐藏密码"
                />
              </div>
            </div>

            <div class="passwordInput">
              <img
                class="passwordIcon"
                src="../assets/images/login/confirmPwd.png"
                alt=""
              />
              <input
                v-model="confirmPassword"
                class="password"
                ref="password"
                placeholder="请再次输入新密码"
                :type="changeShowPwd2 ? 'text' : 'password'"
              />
              <div class="changeShowPwd" v-if="!hasIeBrowser">
                <img
                  @click="changeShowPwd2 = true"
                  title="显示密码"
                  v-if="!changeShowPwd2"
                  src="../assets/images/login/closePwd.png"
                  alt="显示密码"
                />
                <img
                  @click="changeShowPwd2 = false"
                  title="隐藏密码"
                  v-else
                  src="../assets/images/login/openPwd.png"
                  alt="隐藏密码"
                />
              </div>
            </div>
          </div>
          <div class="activeBtnBox">
            <a @click="closeEditPwd" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a @click="saveChangePwd" class="saveBtn" href="javascript:;"
              >保存</a
            >
          </div>
        </div>
      </div>

      <div v-else-if="editType === 2" class="editContentBox">
        <div class="editBindingBox editContent">
          <div class="editHeader">
            修改绑定手机
            <i class="el-icon-close" @click="closeBinding"></i>
          </div>
          <div class="changeFlowPwdBox">
            <div class="phoneNumberInput">
              <img
                class="phoneIcon"
                src="../assets/images/login/mobliePhone.png"
                alt=""
              />
              <input
                placeholder="请填写绑定的手机号码"
                class="phoneNumber"
                v-model="userInfoPhone"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                type="text"
              />
            </div>

            <!-- <div v-if="showVerifyImgCode" class="verifyCodeBox">
              <div class="verifyCode">
                <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                <input placeholder="请填写图形验证码" ref="verifyCode" @keyup.enter="changeInputFocus" v-model="verifyCode"
                  type="text" />
              </div>
              <div @click="taggleVerfyImg" title="看不清？换一张" class="getVerfiyCodeBtn verfy-img">
                <img v-if="verifyImg" :src="verifyImg" alt="" />
                <img v-else src="../assets/images/login/verfiyCodeImg.png" alt="" />
              </div>
            </div>
            <div class="verifyCodeBox">
              <div class="verifyCode">
                <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                <input v-model="verifyCode2" placeholder="短信验证码" type="text" />
              </div>
              <button :class="!verfiyState ? 'get-code-btn' : 'disabled-code-btn'"
                :disabled="verfiyState ? true : false" @click="phoneVerify" class="getVerfiyCodeBtn">
                {{ verfiyState ? `${timeNumber}S再获取` : "获取验证码" }}
              </button>
            </div> -->
          </div>
          <div class="activeBtnBox">
            <a @click="closeBinding" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a class="saveBtn" @click="nextBildingClick" href="javascript:;"
              >修改</a
            >
          </div>
        </div>
      </div>
      <div v-else-if="editType === 3" class="editContentBox">
        <div class="editBindingBox editContent">
          <div class="editHeader">
            修改绑定手机
            <i class="el-icon-close" @click="closeBinding"></i>
          </div>
          <div class="changeBindingMain2">
            <div class="labelItem labelItem2" style="margin-top: 25px">
              <p class="labelLeft yzm">手机号:</p>
              <div class="labelRight">
                <input
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  type="text"
                />
              </div>
            </div>
            <div class="labelItem labelItem2" style="margin-top: 25px">
              <p class="labelLeft yzm">验证码:</p>
              <div class="labelRight">
                <input style="width: 80px; margin-right: 10px" type="text" />

                <button class="sendCodeBtn">发送验证码</button>
              </div>
            </div>
          </div>
          <div class="activeBtnBox">
            <a @click="closeBinding" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a @click="saveBildingPhone" class="saveBtn" href="javascript:;"
              >保存</a
            >
          </div>
        </div>
      </div>
    </div>

    <edit-user-img
      @uploadImgSuccess="uploadImgSuccess"
      ref="userImg"
    ></edit-user-img>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { mapActions, mapGetters } from "vuex";
import { getAction, postAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";
import EditUserImg from "../components/common/EditUserImg.vue";
import { SALEMAN_INFO, USER_INFO } from "../store/mutation-types";
export default {
  components: { EditUserImg },
  name: "MyAccount",
  data() {
    return {
      orderStatus: [
        {
          name: "待配货",
          status: 1,
          count: 0,
        },
        {
          name: "待处理",
          status: 2,
          count: 0,
        },
        {
          name: "已完成",
          status: 3,
          count: 0,
        },
        {
          name: "退货/售后",
          status: -1,
          count: 0,
        },
        {
          name: "全部订单",
          status: 0,
          count: 0,
        },
      ],
      editStatus: false,
      editType: 1,
      userInfo: null,
      orderLoading: false,
      userInfoPhone: "",
      orderList: [],
      customerPhone: "",
      changeShowPwd: false,
      changeShowPwd2: false,
      showVerifyImgCode: true,
      hasIeBrowser: false,
      verfiyState: false,
      timeNumber: 120,
      verifyImg: "",
      verifyCode: "", // 图形验证码
      verifyKey: "", // 图形验证码的KEY
      verifyCode2: "", // 短信验证码
      newPassword: "", // 新密码
      confirmPassword: "", // 确认密码
      verifyPwdMsg: {
        tip1: "",
        tip2: "",
        tip3: "",
      },
      userIntegral: 0, // 用户积分
      balanceData: {
        balanceMoney: 0,
        ticketMoney: 0,
      }, // 余额药券数据
      pageNo: 1,
      pageSize: 5,
      totalCount: 0,
      defaultAddress: {},
      salesmanInfo: {}
    };
  },
  created() {
    let userInfo = getUserInfo();
    let salesmanInfoStr = this.$ls.get(SALEMAN_INFO);
    if(salesmanInfoStr && salesmanInfoStr !== '') {
      let salesmanInfo = this.$base64.decode(salesmanInfoStr);
      console.log("当前登录的业务员数据为", salesmanInfo)
      this.salesmanInfo = JSON.parse(salesmanInfo)
    }
    if (!userInfo) {
      this.$router.push("/login");
    } else {
      this.userInfo = userInfo;

      this.getCustomerBalance();
      this.getUserIntegral();
      this.getOrderListData();
      this.getAddressList();
    }

    this.getPendingOrderCount();
  },
  computed: {
    ...mapGetters(["pendingOrderCount"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    openTip(message) {
      this.$message.warning(message);
    },
    getAddressList() {
      let _this = this;
      let url = "/customer/customerAddress/list";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          _this.addressList = res.result;
          if (_this.addressList.length > 0) {
            for (let key of _this.addressList) {
              if (key.isDefault === 1) {
                _this.defaultAddress = key;
              }
            }
          }
        }
      });
    },
    // 复制文字
    onCopyText(className) {
      var clipboard = new Clipboard(`.${className}`);
      clipboard.on("success", () => {
        clipboard.destroy();
        this.$message.success("复制成功");
      });
      clipboard.on("error", () => {
        this.$message.error("该浏览器不支持自动复制");
        clipboard.destroy();
      });
    },
    payOrderClick(item) {
      this.$router.push({
        name: "orderPay",
        query: { payOrderId: item.payOrderId, saleOrderId: item.id },
      });
    },
    cencalOrder(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否取消该笔订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const url =
          "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
        postAction(url, null, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单取消成功",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.getOrderListData();
          }
        });
      });
    },
    navToOrderAct(item) {
      let text = this.$router.resolve({
        name: "orderDetail",
        query: { saleOrderId: item.id },
      });
      window.open(text.href, "_blank");
    },
    // 跳转到指定订单列表
    routeOrderList(item) {
      console.log("item++++++++++", item);
      let status = item.status;
      if (item.status === -1) {
        this.$router.push("/refundList");
      } else {
        this.$router.push({ path: "/orderList", query: { state: status } });
      }
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getOrderListData();
    },
    getPendingOrderCount() {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderNum";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let waitConfirmNum = resData.waitConfirmNum;
          let waitPaymentNum = resData.waitPaymentNum;
          let pendingOrderCount = waitConfirmNum + waitPaymentNum;
          _this.$store.commit("PENDING_ORDER_COUNT", pendingOrderCount);
        }
      });
    },

    // 获取用户的积分数据
    async getUserIntegral() {
      let _this = this;
      let url = "/integral/customerIntegral/queryForIntegral";
      let res = await getAction(url);
      console.log("获取用户的积分数据为+++++", res);
      if (res.code === 200 && res.success) {
        let resData = res.result;
        _this.userIntegral = resData.integral;
      }
    },
    confirmOrderClick(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否确认该笔订单?", "提示", {
        confirmButtonText: "确认订单",
        cancelButtonText: "取消操作",
        type: "info",
      }).then(() => {
        const url = "/saleOrder/saleOrder/confirmSaleOrder";
        let data = {
          saleOrderId: saleOrderId,
        };
        postAction(url, data, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单确认成功!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            that.getOrderListData();
          }
        });
      });
    },
    // 获取用户余额和药券数据
    async getCustomerBalance() {
      let _this = this;
      let url = "/balance/customerBalance/query";
      let res = await getAction(url);
      if (res.code === 200 && res.success) {
        console.log("获取用户余额药券的数据为++++++", res);
        let resData = res.result;
        if (resData && resData !== null) {
          _this.balanceData.balanceMoney = resData.balanceMoney;
          _this.balanceData.ticketMoney = resData.ticketMoney;
        }
      }
    },
    // 修改用户头像
    editUserImgAct() {
      this.$refs.userImg.dialogTableVisible = true;
    },
    // 图片修改成功
    uploadImgSuccess(imgInfo) {
      this.userInfo.img = imgInfo;
      let userInfo = this.userInfo;
      let encodeUserInfo = this.$base64.encode(JSON.stringify(userInfo));
      this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
    },
    // 修改用户绑定的手机号
    editBindingPhone() {
      if (this.userInfo.phone && this.userInfo.phone !== "") {
        this.userInfoPhone = this.userInfo.phone;
      }
      this.editStatus = true;
      this.editType = 2;
    },
    // 修改用户密码
    editPassWord() {
      let _this = this;
      // 检测当前用户是否存在手机号
      if (!this.userInfo.phone || this.userInfo.phone === "") {
        this.$confirm(
          "检测到您账号当前还未绑定手机号码，请先绑定手机号码后再进行修改密码操作?",
          "提示",
          {
            confirmButtonText: "去绑定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          _this.editStatus = true;
          _this.editType = 2;
        });
      } else {
        _this.taggleVerfyImg();
        _this.editStatus = true;
        _this.editType = 1;
      }
    },
    // 取消修改密码
    closeEditPwd() {
      this.editStatus = false;
    },
    // 确认修改密码操作
    async saveChangePwd() {
      let _this = this;
      let verifyCode2 = this.verifyCode2;
      let newPassword = this.newPassword;
      let confirmPassword = this.confirmPassword;
      if (!verifyCode2 || verifyCode2 === "") {
        this.$message.error("请填写短信验证码");
        return false;
      }
      if (!newPassword || newPassword === "") {
        this.$message.error("请填写新密码");
        return false;
      }
      if (!confirmPassword || confirmPassword === "") {
        this.$message.error("请填写确认密码");
        return false;
      }
      if (newPassword !== confirmPassword) {
        this.$message.error("两次输入的密码不一致！");
        return false;
      }
      let url = "/customer/customer/updateCustomerPassword";
      let data = {
        verifyCode: verifyCode2,
        newPassword: _this.$md5(newPassword).substring(8, 24),
        confirmPassword: _this.$md5(confirmPassword).substring(8, 24),
      };
      let res = await postAction(url, data);
      if (res.code === 200 && res.success) {
        this.closeEditPwd();
        this.$alert("密码修改成功，请重新登录！", "提示信息", {
          type: "success",
        });
        setTimeout(() => {
          _this.Logout();
        }, 1500);
      } else {
        this.$message.error(res.message);
      }
    },
    // 回车操作
    enterKeyUp(type) {
      let oldPwd = this.oldPwd;
      let newPwd = this.newPwd;
      let confirmPwd = this.confirmPwd;
      if (oldPwd !== "" && newPwd !== "" && confirmPwd !== "") {
        this.saveChangePwd();
      }
    },
    // 取消修改绑定手机
    closeBinding() {
      this.editStatus = false;
    },
    // 修改绑定手机号下一步操作
    async nextBildingClick() {
      // let _this = this;
      // this.editType = 3;
      let userInfoPhone = this.userInfoPhone;
      if (this.$util.checkStr(userInfoPhone, "mobile")) {
        let url = "/customer/customer/update";
        let data = {
          phone: userInfoPhone,
        };
        let res = await postAction(url, data);
        this.closeBinding();
        if (res.code === 200 && res.success) {
          this.$message.success("手机号码绑定成功！");
          this.userInfo.phone = userInfoPhone;
          let encodeUserInfo = this.$base64.encode(
            JSON.stringify(this.userInfo)
          );
          this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
          this.closeBinding();
        } else {
          this.$message.error(res.message);
        }
      } else {
        this.$message.error("填写的手机号码格式不正确！");
      }
    },

    // 切换验证码图片
    taggleVerfyImg() {
      let _this = this;
      let url = "customer/getVerifyCode";
      getAction(url).then((res) => {
        let data = res.result;
        if (res.code === 200 && res.success === true) {
          _this.verifyKey = data.key;
          _this.verifyImg = `data:image/jpg;base64,${data.img}`;
        }
      });
    },
    // 手机登录发送验证码操作
    async phoneVerify() {
      let _this = this;
      // 检查图形验证码和手机号码是否填写并验证手机格式是否正确
      let customerPhone = this.userInfo.phone;
      let verifyCode = this.verifyCode;
      let verifyKey = this.verifyKey;
      // 验证手机号码格式
      if (_this.$util.checkStr(customerPhone, "mobile") && verifyCode !== "") {
        // 获取短信验证码
        let url = "/customer/customer/updateCustomerPasswordSendPhoneCode";
        let data = {
          phone: customerPhone,
          verifyCode: verifyCode,
          verifyKey: verifyKey,
        };
        let res = await postAction(url, data);
        if (res.code === 200 && res.success) {
          this.verfiyState = true;
          this.changeFlow = 2;
          let timeNumber = this.timeNumber;
          let interVal = setInterval(function () {
            timeNumber--;
            _this.timeNumber = timeNumber;
            if (timeNumber == 0) {
              clearInterval(interVal);
              _this.timeNumber = 120;
              _this.verfiyState = false;
            }
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      } else if (_this.$util.checkStr(customerPhone, "mobile")) {
        this.$message.error("手机号码格式不正确！");
        return false;
      } else if (!verifyCode || verifyCode === "") {
        this.$message.error("请填写图形验证码！");
        return false;
      }
    },
    // 保存更改后的手机号
    saveBildingPhone() {
      this.editStatus = false;
    },

    getOrderListData() {
      let _this = this;
      this.orderLoading = true;
      // 订单编号首尾去空格(防止用户复制粘贴多复制了空白);
      // 分页数据，订单状态
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      // 删除订单日期查询数据
      delete data.createDate;
      console.log("订单搜索的查询条件：", data);
      let url = "/saleOrder/saleOrder/list";
      getAction(url, data).then((res) => {
        _this.orderLoading = false;
        if (res.code == 200 && res.success) {
          let resData = res.result;
          _this.orderList = resData.records;
          _this.pageNo = resData.current;
          _this.totalCount = resData.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/myAccount/myAccount";
</style>
