<template>
  <div
    class="carousel-content container"
    :style="`background: linear-gradient(0deg, rgb(242,242,242), ${carouselBgUrl})`"
  >
    <div class="container_box">
      <el-carousel
        v-if="JSON.stringify(carouselList) !== '[{}]'"
        @change="carouseChange"
        ref="carousel"
        indicator-position="none"
        :interval="5000"
        arrow="always"
        class="carouse-list"
        trigger="click"
        height="340px"
      >
        <el-carousel-item
          v-for="(item, index) of carouselList"
          :key="'indexCarousel' + index"
        >
          <a @click="gotoUrl(item)" href="javascript:;">
            <img :src="$util.resizeImg(item.picUrl, 780, 340)" alt="" />
          </a>
        </el-carousel-item>

        <div class="carousel-bottom">
          <span
            v-for="(item, index) of carouselList"
            :key="'span' + index"
            @click="tagIndex(index)"
            :class="{ active: carouselActiveIndex === index }"
          >
            {{ item.title }}
          </span>
        </div>
      </el-carousel>
      <div v-if="bannerFloorData" class="banner-bottom">
        <a
          v-for="(item, index) of bannerFloorData.floorDetailList"
          :key="index"
          @click="gotoUrl(item)"
          href="javascript:;"
          class="banner-link"
        >
          <img v-lazy="floorDetailImg(item)" alt="" />
        </a>
      </div>
      <div class="fs-main-right">
        <div class="info-act">
          <div class="user-info">
            <img
              v-if="userInfo && userInfo.img && userInfo.img !== ''"
              :src="userInfo.img"
              alt="用户头像"
            />
            <img
              v-else
              :src="`${$configOptions.resourcePath}/config/logo/logo.png`"
              alt="用户头像"
            />
            <p v-if="loginState">
              <span :title="userInfo.name">{{ userInfo.name }}</span>
            </p>
            <p v-else>请登录后操作！</p>
          </div>
          <div v-if="loginState" class="integral-act">
            <!-- <a @click="Logout()" class="logoutLink active" href="javascript:;">退出</a> -->
            <router-link class="sign active" to="/integralCenter">
              <img
                src="~@/assets/images/index/qiandao-icon.png"
                alt="积分签到"
              />
              积分签到</router-link
            >
          </div>
        </div>
        <div class="user-shortcut">
          <div>
            <router-link to="/couponList">
              <div class="shortcut">
                <img src="~@/assets/images/index/lingquan_icon.png" alt="" />
              </div>
              <span>领券中心</span>
            </router-link>
          </div>
          <div>
            <router-link to="/collect">
              <div class="shortcut">
                <img src="~@/assets/images/index/collect-icon2.png" alt="" />
              </div>
              <span>我的收藏</span>
            </router-link>
          </div>
          <div>
            <router-link to="/integralCenter">
              <div class="shortcut">
                <img src="~@/assets/images/index/jfsc-icon.png" alt="" />
              </div>
              <span>积分商城</span>
            </router-link>
          </div>
        </div>
        <hr class="hr" />
        <div class="noticeContent">
          <div class="noticeTop">
            <div class="noticeTitle">
              <img src="@/assets/images/index/noticeIcon.png" alt="通知公告" />
              <p>通知公告</p>
            </div>
            <router-link
              v-if="companyNoticeList && companyNoticeList.length > 0"
              :to="`/notice?id=` + companyNoticeList[0].id"
            >
              更多>>
            </router-link>
          </div>
          <div class="notice-news">
            <router-link
              v-for="(item, index) of newNoticeData"
              :key="'newNotice' + index"
              :to="{
                name: 'notice',
                query: { id: item.noticeId, detailId: item.id },
              }"
              >{{ item.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getAction, postAction } from "../../api/manage";
import { getUserInfo } from "../../assets/js/common/common";
export default {
  name: "Carousel",
  data() {
    return {
      carouselActiveIndex: 0,
      carouselList: [{}],
      newNoticeData: [],
      carouselBgUrl: "", // 轮播图的主题色
      userInfo: null,
      loginState: false,
      bannerFloorData: {},
    };
  },
  created() {
    this.getIndexCarousel();
    this.getBannerBottomFloor();
    let companyNoticeList = this.$ls.get("companyNoticeList");
    if (!companyNoticeList) {
      this.getNewNotice();
    } else {
      this.newNoticeData = companyNoticeList;
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
      this.loginState = true;
    } else {
      this.loginState = false;
    }
  },
  computed: {
    ...mapGetters(["companyConfigData", "companyNoticeList"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    // 楼层详情的图片数据
    floorDetailImg(item) {
      let imgPath = this.$imgPath;
      // 如果用户上传了产品的图片 则用客户上传的图片
      if (item.picUrl && item.picUrl !== "" && item.picUrl !== null) {
        return `${imgPath}${item.picUrl}`;
      } else {
        if (item.product && item.product.picUrl && item.product.picUrl !== "") {
          let picUrl = item.product.picUrl.split(",");
          return this.$util.resizeImg(`${imgPath}${picUrl[0]}`, 175);
        } else {
          return false;
        }
      }
    },
    // 获取轮播图下方的图片楼层
    async getBannerBottomFloor() {
      let _this = this;
      let url = "/homeShow/floor/getFloorList";
      let data = {
        type: 1,
        typeId: 1,
      };
      let res = await postAction(url, data);
      console.log("获取轮播图下方的楼层数据为：", res);
      if (res.code === 200) {
        let resData = res.result[0];
        _this.bannerFloorData = resData;
      }
    },

    gotoUrl(item) {
      let userInfo = this.userInfo;
      let gotoUrlType = item.gotoUrlType || "1";
      let gotoUrl = item.gotoUrl;
      if (gotoUrl && gotoUrl !== "") {
        if (gotoUrlType == "3") {
          window.open(gotoUrl + `?Shopping-Access-Token=${userInfo.token}`);
        } else {
          window.open(gotoUrl);
        }
      } else {
        return false;
      }
    },

    carouseChange(item) {
      let resData = this.carouselList;
      this.carouselActiveIndex = item;
      this.carouselBgUrl = resData[item].bgcolor;
    },
    // 查看当前的公告的数据
    getNewNotice() {
      let _this = this;
      let data = {
        pageNo: 1,
        pageSize: 15,
      };
      let url = "/notice/noticeDetail/list";
      getAction(url, data).then((res) => {
        this.noticeLoading = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.$ls.set("companyNoticeList", resData.records);
          _this.newNoticeData = resData.records;
        }
      });
    },
    getIndexCarousel() {
      // 1. 获取首页轮播图
      const _this = this;
      const dataLun = {
        carouselType: 1,
      };
      const urllun = "/homeShow/carousel/getCarouselList";
      postAction(urllun, dataLun).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          // 判断返回的轮播图数据是否存在
          if (resData.length > 0) {
            _this.carouselList = resData;
            _this.carouselBgUrl = resData[0].bgcolor;
          }
        }
      });
    },
    tagIndex(index) {
      this.$refs.carousel.setActiveItem(index);
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/index/carousel";

::v-deep .el-carousel__indicators {
  display: none !important;
}
</style>