<template>
  <div class="areaContainer" :style="
    brandDetail &&
      brandDetail.backgroundColor &&
      brandDetail.backgroundColor !== ''
      ? `background-color:${brandDetail.backgroundColor}`
      : ''
  ">
    <!-- banner区域 -->
    <div v-if="brandDetail && brandDetail.picUrl && brandDetail.picUrl !== ''" class="areaBanner container">
      <img :src="brandDetail.picUrl" alt="brandDetail.name" />
    </div>
    <!-- end banner区域 -->
    <div v-if="brandDetail" class="screenMain container_box">
      <!-- 药理分类 -->
      <div v-if="
        brandDetail.type == 3 &&
        brandDetail.brandAreaDetailList &&
        brandDetail.brandAreaDetailList.length > 1
      " class="screenContent">
        <div class="screenTop clearfix">
          <div class="screenTitle fl">药理分类</div>
          <div class="screen-container fl">
            <!--默认显示-->
            <div :class="!proTypeSide ? 'autoList' : ''" class="screenListBox">
              <!--点击锁定类目后的样式-->
              <span v-for="(item, index) of brandDetail.brandAreaDetailList" :key="'proType' + index"
                @click="selectAct(item)" :class="{ activeFeilei: proType === item.id }">
                {{ renderProductCategory(item) }}
              </span>
            </div>
          </div>
          <div class="rightbox fr">
            <a @click="tabSideType(false)" v-show="proTypeSide" href="javascript:void(0);" class="zhan-er">
              展开<i class="el-icon-arrow-down"></i>
            </a>
            <a @click="tabSideType(true)" v-show="!proTypeSide" href="javascript:void(0);" class="shou-er">
              收起<i class="el-icon-arrow-up"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- end 药理分类 -->
      <!-- 产品标签分类 -->
      <div v-else-if="
        brandDetail.type == 2 &&
        brandDetail.brandAreaDetailList &&
        brandDetail.brandAreaDetailList.length > 1
      " class="proLabel">
        <el-carousel trigger="click" height="40px" :autoplay="false">
          <el-carousel-item v-for="(item, index) of splitBrandAreaDetailList" :key="'proLabel' + index">
            <div class="proLabelBox">
              <div class="labelItem" v-for="(itemC, indexC) of item" :key="'label' + indexC">
                <p @click="selectProLabel(itemC)" :class="{ selectedLabel: productLabelList == itemC.labelId }">
                  {{ itemC.labelName }}
                </p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- END 产品标签分类 -->
      <!-- 排序条件 -->
      <div class="screen-key-content">
        <div class="screen-container">
          <div class="screen-key" v-for="(item, index) of screenList" :key="'screen-key' + index">
            <p @click="selectScreen(item, index)" :class="{ orderAct: screenType == index }">
              {{ item
              }}{{ screenType == index && screenType > 0 ? sortTypeText : "" }}
            </p>
          </div>
          <div v-if="false" class="pricerange">
            价格区间:
            <div class="price">
              <input type="text" class="price-range start" id="minPrice" @focus="showRangeStatus = true"
                v-model="minPrice" onkeyup="this.value = this.value.replace(/\D/g, '')"
                onchange="this.value = this.value.trim()" maxlength="4" @blur="inputBlur" />
              -
              <input type="text" class="price-range" id="maxPrice" v-model="maxPrice" @keyup.enter="priceSearch"
                @focus="showRangeStatus = true" onkeyup="this.value = this.value.replace(/\D/g, '')"
                onchange="this.value = this.value.trim()" @blur="inputBlur" maxlength="4" />
            </div>
            <div class="range" v-show="showRangeStatus">
              <a href="javascript:;" @click="clearPriceNum" class="ssClear" id="ssClear">清空</a>
              <a href="javascript:;" @click="priceSearch" class="ssConfirm" id="ssConfirm">确认</a>
            </div>
          </div>
          <div class="hasStocket">
            <el-switch v-model="hasStocket" @change="getProductListData" active-text="只显示有货">
            </el-switch>
          </div>
        </div>
        <div class="goods-tips">
          <div class="goods-show-num">
            <p>
              共<span class="goods-aount">{{ totalCount }}</span>个商品，每页展示<span> {{ pageSize }} </span>个
            </p>
          </div>

          <div class="switchBtnBox">
            <div @click="listType = !listType" class="switchBtn">
              <div :style="!listType ? 'left:0px' : 'left:19px'" class="btn">
                {{ !listType ? "大图" : "列表" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end 排序条件 -->
    </div>
    <div class="goods-page-content container_box">
      <div class="proTable">
        <div v-if="productList.length === 0 && !productLoading" class="dataNone container_box">
          <div class="dataImg productNoneImg"></div>
        </div>
        <div v-else v-loading="productLoading" class="cartMain">
          <ul v-if="!listType" class="goods-list-header">
            <li class="activity">
              <p></p>
            </li>
            <li class="name">商品名称</li>
            <li class="format">规格</li>
            <li class="unit">单位</li>
            <li class="manufacturer">生产厂家</li>
            <li class="validity">有效期</li>
            <li class="package">中/大包装</li>
            <li class="price">单价</li>
            <!-- <li class="discountPrice">折扣价</li> -->
            <li class="discountPrice">预计到货</li>
            <li class="inventory">库存</li>
            <li class="amount">数量</li>
            <li class="operation"></li>
          </ul>
          <div class="productListBox">
            <table-pro-list
              :specialAreaMaskImg="brandDetail && brandDetail.productBackgroundPic ? brandDetail.productBackgroundPic : ''"
              v-if="!listType" :productList="productList">
            </table-pro-list>
            <img-pro-list
              :specialAreaMaskImg="brandDetail && brandDetail.productBackgroundPic ? brandDetail.productBackgroundPic : ''"
              v-else :productList="productList"> </img-pro-list>
          </div>
        </div>
      </div>
      <div class="pagination-bottom">
        <el-pagination background v-if="totalCount > 0" :hide-on-single-page="true" layout="prev, pager, next, jumper"
          :current-page="pageNo" @current-change="currentChange" :page-size="pageSize" :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import TableProList from "@/components/product/TableProList.vue";
import { getAction } from "../api/manage";
import { sliceArr } from "@/assets/js/common/common";
import ImgProList from "../components/product/ImgProList.vue";
export default {
  components: { TableProList, ImgProList },
  props: ["searchName"],
  name: "SpecialArea",
  data() {
    return {
      listType: true,
      pageNo: 1,
      pages: 1,
      pageSize: 20,
      totalCount: 1,
      searchVal: "",
      collectFlag: "",
      productLoading: true,
      productList: [],
      manufacturer: "",
      hasStocket: false, // 只展示有库存的
      maxPrice: "",
      minPrice: "",
      showRangeStatus: false,
      proTypeSide: true,
      proType: "", // 选择的药理名称
      productLabelList: null,
      proLabelList: [
        [
          { id: 1, name: "注射剂" },
          { id: 2, name: "抗生素" },
          { id: 3, name: "消炎类" },
          { id: 4, name: "胶囊剂" },
        ],
        [
          { id: 1, name: "注射剂" },
          { id: 2, name: "抗生素" },
          { id: 3, name: "消炎类" },
          { id: 4, name: "胶囊剂" },
        ],
        [
          { id: 1, name: "注射剂" },
          { id: 2, name: "抗生素" },
          { id: 3, name: "消炎类" },
          { id: 4, name: "胶囊剂" },
        ],
      ],
      screenList: ["默认排序", "销量", "价格", "人气"],
      screenType: 0, // 4:销量 2:价格 3:人气
      sortType: 0, // 0:升序 1:降序
      sortTypeText: "由低到高", // 排序提示
      productCategory: "", // 产品分类
      proTypeList: ["高血压", "消化系统用药", "呼吸系统用药"],
      brandAreaId: null,
      brandDetail: {},
    };
  },
  computed: {
    splitBrandAreaDetailList() {
      let brandDetail = this.brandDetail;
      if (brandDetail && JSON.stringify(brandDetail) !== "{}") {
        let brandAreaDetailList = sliceArr(brandDetail.brandAreaDetailList, 5);
        return brandAreaDetailList;
      } else {
        return [];
      }
    },
  },
  created() {
    let id = this.$route.query.id;
    if (id && id !== "") {
      this.brandAreaId = id;
      this.getAreaContent();
    }
  },
  methods: {
    inputBlur() {
      let minPrice = this.minPrice;
      let maxPrice = this.maxPrice;
      if (minPrice == '' && maxPrice == '') {
        this.showRangeStatus = false
      }
    },
    // 选择产品数据应该显示的分类名称
    renderProductCategory(item) {
      let categorySecondId = item.categorySecondId;
      let categoryThirdlyId = item.categoryThirdlyId;
      if (categoryThirdlyId && categoryThirdlyId !== "") {
        return item.categoryThirdlyName;
      } else if (
        categorySecondId &&
        categorySecondId !== "" &&
        (!categoryThirdlyId || categoryThirdlyId == null)
      ) {
        return item.categorySecondName;
      } else {
        return item.categoryFirstName;
      }
    },
    // 选择标签的数据
    selectProLabel(item) {
      this.productLabelList = item.labelId;
      this.pageNo = 1;
      this.getProductListData();
    },
    // 获取专区详情数据
    async getAreaContent() {
      let id = this.brandAreaId;
      let url = "area/brandAreavo/get";
      let res = await getAction(url, { id: id });
      console.log("获取专区的数据为++++++", res);
      let resData = res.result;
      if (res.code == 200 && res.success) {
        this.brandDetail = resData;
        // 根据专区id查询产品列表数据
        this.getProductListData();
      }
    },
    tabSideType() {
      this.proTypeSide = !this.proTypeSide;
    },
    selectAct(item) {
      let proType = this.proType;
      let productCategory = "";
      if (proType == item.id) {
        productCategory = "";
        this.proType = null;
      } else {
        this.proType = item.id;
        let categorySecondId = item.categorySecondId;
        let categorThirdlyId = item.categorThirdlyId;
        if (categorThirdlyId && categorThirdlyId !== "") {
          productCategory = item.categorThirdlyName;
        } else if (
          categorySecondId &&
          categorySecondId !== "" &&
          (!categorThirdlyId || categorThirdlyId == null)
        ) {
          productCategory = item.categorySecondName;
        } else {
          productCategory = item.categoryFirstName;
        }
      }
      console.log("productCategory+++++++++", productCategory);
      this.productCategory = productCategory;
      this.pageNo = 1;
      this.getProductListData();
    },
    // 价格区间查询
    priceSearch() {
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 清空价格区间筛选
    clearPriceNum() {
      this.minPrice = "";
      this.maxPrice = "";
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 选择商品筛选排序条件
    selectScreen(item, index) {
      let sortType = this.sortType;
      let screenType = this.screenType;
      // 人气默认由高到低
      if (screenType == index && index > 0) {
        if (sortType == 1) {
          this.sortTypeText = "由高到低";
          this.sortType = 2;
        } else {
          this.sortTypeText = "由低到高";
          this.sortType = 1;
        }
      } else if (screenType !== index && index > 0) {
        // 初始人气、销量默认从高到低
        if (index == 3 || index == 1) {
          this.sortType = 2;
          this.sortTypeText = "由高到低";
        } else {
          this.sortType = 1;
          this.sortTypeText = "由低到高";
        }
      } else {
        this.sortType = 1;
        this.sortTypeText = "";
      }
      this.screenType = index;
      this.getProductListData();
    },
    // 获取产品列表数据
    getProductListData() {
      let _this = this;
      this.productLoading = true;
      let productCategory = this.productCategory;
      let productLabelList = this.productLabelList;
      let manufacturer = this.manufacturer;
      let searchVal = this.searchVal;
      let brandAreaId = this.brandAreaId;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (brandAreaId && brandAreaId !== "") {
        data.brandAreaId = brandAreaId;
      }
      if (productCategory && productCategory !== "") {
        data.productCategory = productCategory;
      }
      if (manufacturer && manufacturer !== "") {
        data.manufacturer = manufacturer;
      }
      if (productLabelList && productLabelList !== "") {
        data.productLabelList = productLabelList;
      }
      // 判断筛选条件
      // 1. 判断是否只显示有库存的产品数据
      let hasStocket = this.hasStocket;
      if (hasStocket) {
        data["productScreenBO.isInventory"] = 1;
      } else {
        data["productScreenBO.isInventory"] = 0;
      }
      // 2.判断价格区间
      let maxPrice = this.maxPrice;
      let minPrice = this.minPrice;
      if (maxPrice !== "") {
        data["productScreenBO.maxPrice"] = searchVal;
      }
      if (minPrice !== "") {
        data["productScreenBO.minPrice"] = minPrice;
      }
      // 判断筛选条件和升降序
      let screenType = this.screenType;
      let sortType = this.sortType;
      console.log("screenType++++++++", screenType)
      console.log("sortType++++++++", sortType)
      // screenType: 1: 销量 2:价格 3.人气
      // sortType: 1: 升序  2.降序
      if (screenType !== "" && screenType > 0) {
        if (screenType == 1) {
          data["productScreenBO.screenType"] = 4;
        } else {
          data["productScreenBO.screenType"] = screenType;
        }
        data["productScreenBO.sortType"] = sortType;
      }
      let url = "/product/getlistE";
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
            _this.pageNo = pageData.current;
            _this.pages = pageData.pages;
            _this.totalCount = pageData.total;
          } else {
            _this.productList = [];
            _this.productLoading = false;
          }
        })
        .catch((error) => {
          _this.productList = [];
          _this.productLoading = false;
        });
    },
    // 搜索产品的操作
    searchProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.pageNo = 1;
      this.getProductListData();
    },
    // 搜索收藏夹产品的操作
    searchCollectProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.collectFlag = 1;
      this.pageNo = 1;
      this.getProductListData();
    },
    searchProductCategory(productCategory) {
      this.clearSearchUrlData();
      this.productCategory = productCategory;
      this.getProductListData();
    },
    clearSearchUrlData() {
      this.productCategory = "";
      this.productLabelList = "";
      this.manufacturer = "";
      this.collectFlag = "";
      this.searchVal = "";
      this.pageNo = 1;
    },
    currentChange(val) {
      this.backTop();
      this.pageNo = val;
      this.getProductListData();
    },
  },

  watch: {
    $route(to) {
      let routerQuery = to.query;
      let brandAreaId = this.brandAreaId;
      if (brandAreaId && brandAreaId !== "") {
        if (routerQuery.id !== "" && brandAreaId !== routerQuery.id) {
          this.brandAreaId = routerQuery.id;
          this.getAreaContent();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/product/product";
@import "../assets/styles/product/area";
.areaContainer {
  padding-top: 10px;
  background-color: #f2f2f2;
  ::v-deep .goods-page-content {
    background: transparent !important;
  }
}
</style>