<template>
  <div class="feedbackApp">
    <div class="header">意见反馈</div>
    <div class="feedbackContent">
      <h3>您的宝贵意见将是我们不断前进的动力!</h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item label="反馈类型:" prop="backType">
          <el-radio-group v-model="ruleForm.backType">
            <el-radio :label="1">意见建议</el-radio>
            <el-radio :label="2">药品咨询</el-radio>
            <el-radio :label="3">服务投诉</el-radio>
            <el-radio :label="4">商城使用问题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="contact" label="手机/邮箱:" prop="contact">
          <el-input v-model="ruleForm.contact"></el-input>
        </el-form-item>
        <el-form-item class="valBox" label="反馈内容:" prop="val">
          <el-input
            minlength="10"
            maxlength="1000"
            placeholder="内容尽量控制在10~1000字以内，以便我们快速给您回复！"
            type="textarea"
            v-model="ruleForm.val"
          ></el-input>
        </el-form-item>
        <el-form-item class="bottomBtn">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交反馈</el-button
          >
          <el-button type="danger" @click="resetForm('ruleForm')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { postAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";

export default {
  name: "Feedback",
  data() {
    return {
      ruleForm: {
        backType: 1,
        contact: "",
        val: "",
      },
      rules: {
        backType: [
          { required: true, message: "请选择反馈类型", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
        ],
        val: [
          { required: true, message: "请填写你要反馈的内容", trigger: "blur" },
          {
            min: 10,
            max: 1000,
            message: "反馈内容文字应在 10 到 1000 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
      console.log("获取的用户数据为++++++", userInfo);
      this.accountId = userInfo.id;
    }
  },
  computed: {},
  methods: {
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = _this.ruleForm;
          let accountId = _this.accountId;
          if (accountId && accountId !== "") {
            data.customerId = accountId;
          }
          let url = "customer/customerFeedback/add";
          postAction(url, data).then(res => {
            if(res.code == 200 && res.success) {
              _this.$refs[formName].resetFields();
              this.$message({
                message: "您的意见反馈已提交！",
                offset: 400,
                duration: 1000,
                type: "success",
              });
            } else {
              this.$message({
                message: res.message,
                offset: 400,
                duration: 1000,
                type: "error",
              });
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/Feedback";
</style>
