<template>
  <div class="container">
    <iframe
      ref="iframe"
      :width="iframeWidth"
      :height="iframeHeight"
      @load="resizeIframe"
      :src="iframeUrl"
    ></iframe>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      iframeUrl: '',
      queryWidth: "",
      queryHeight: "",
      iframeWidth: "100%",
      iframeHeight: "100vh", // 默认高度，加载时会被重置
    };
  },
  created() {
    let queryWidth = this.$route.query.width;
    let queryHeight = this.$route.query.height;
    let iframeUrl = this.$route.query.url;
    if(iframeUrl && iframeUrl !== '') {
      this.iframeUrl = iframeUrl
    }
    if (queryWidth && iframeUrl) {
      this.queryWidth = queryWidth;
      this.queryHeight = queryHeight;
    }
  },
  methods: {
    resizeIframe() {
      let queryWidth = this.queryWidth;
      let queryHeight = this.queryHeight;
      if (queryWidth && queryHeight) {
          this.iframeHeight = `${queryHeight}`;
          this.iframeWidth = `${queryWidth}`;
      } else {
        const iframe = this.$refs.iframe;
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        this.$nextTick(() => {
          const body = iframeDoc.body;
          this.iframeHeight = `${body.scrollHeight}px`;
          this.iframeWidth = `${body.scrollWidth - 18}px`;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
</style>