<template>
  <el-dialog
    :title="`物流详情:`"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <div class="logistics-modal">
      <div class="logistics-type">
        <span class="logistics-company">{{
          selectedLogistics.logisticsTypeName
        }}</span>
        <p class="line"></p>
        <p class="logistics-company">
          <span>物流单号：</span>
          <span
            >{{
              selectedLogistics.logisticsWaybillId
                ? selectedLogistics.logisticsWaybillId
                : "暂无物流单号信息"
            }}
            <i
              :data-clipboard-text="selectedLogistics.logisticsWaybillId"
              @click="onCopyText('logisticsWaybillId')"
              class="el-icon-document-copy logisticsWaybillId"
            ></i>
          </span>
        </p>
      </div>
      <div class="logistics">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in activities"
            :key="index"
            :timestamp="item.key"
          >
            {{ item.val}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      reverse: true,
      dialogVisible: false,
      showHeaderStatus: false,
      logisticsVoList: [], // 渲染的包裹数组
      logisticsWaybillId: "", // 选中的包裹物流单号
      selectedLogistics: {}, // 选中包裹的物流数据
      activities: [],
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    open(selectedLogistics) {
      if (
        selectedLogistics &&
        selectedLogistics.logisticsInfo &&
        selectedLogistics.logisticsInfo.length > 0
      ) {
        this.selectedLogistics = selectedLogistics;
        let logisticsInfo = [...selectedLogistics.logisticsInfo];
        // 时间倒序
        // 处理物流明细节点,将二维数组转成一维数组渲染
        let logisticsInfoAll = logisticsInfo.reverse();
        let activities = [];
        for(let key1 of logisticsInfoAll) {
          for(let key2 of key1.val.reverse()) {
            let data = {
              val: key2,
              key: key1.key
            }
            activities.push(data)
          }
        }
        this.activities = activities;
        this.dialogVisible = true;
      }
    },
    // 切换包裹操作
    changeLogisticsWaybillId() {},
  },
};
</script>

<style lang="scss" scoped>
.logistics-type {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  span,
  p {
    font-size: 14px;
    color: #585858;
  }

  .el-icon-document-copy {
    color: $act-color;
  }

  .line {
    width: 1px;
    height: 14px;
    background: #e5e5e5;
    margin: 0 12px;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
}
::v-deep .el-dialog__body {
  padding: 20px 30px;
}
::v-deep .el-timeline-item {
  &:first-child {
    .el-timeline-item__node {
      background-color: $act-color;
    }
    .el-timeline-item__content {
      color: $act-color;
    }
  }
}
</style>