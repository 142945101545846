<template>
  <div class="logisticsContent container_box">
    <div class="header">
      <router-link to="/orderList?state=3" href="javascript:;">
        订单列表
      </router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        :to="{ name: 'orderDetail', query: { saleOrderId: saleOrderId } }"
      >
        订单编号：{{saleOrderId}}
      </router-link>
      <i class="el-icon-arrow-right"></i>
      <a href="javascript:;"> 物流信息 </a>
    </div>
    <div class="imgLiucheng container_box">
      <div class="imgBox">
        <div class="imgC" :class="{ active: logisticsStatus >= 1 }">
          <img
            v-if="logisticsStatus >= 1"
            src="../assets/images/order/logistics/1Act.png"
            alt="揽件"
          />
          <img v-else src="../assets/images/order/logistics/1.png" alt="揽件" />
          <p>揽件</p>
        </div>
        <div class="imgC" :class="{ active: logisticsStatus >= 2 }">
          <img
            v-if="logisticsStatus >= 2"
            src="../assets/images/order/logistics/2Act.png"
            alt="运输"
          />
          <img v-else src="../assets/images/order/logistics/2.png" alt="运输" />
          <p>运输</p>
        </div>
        <div class="imgC" :class="{ active: logisticsStatus >= 3 }">
          <img
            v-if="logisticsStatus >= 3"
            src="../assets/images/order/logistics/3Act.png"
            alt="派送"
          />
          <img v-else src="../assets/images/order/logistics/3.png" alt="派送" />
          <p>派送</p>
        </div>
        <div class="imgC" :class="{ active: logisticsStatus >= 4 }">
          <img
            v-if="logisticsStatus >= 4"
            src="../assets/images/order/logistics/4Act.png"
            alt="签收"
          />
          <img v-else src="../assets/images/order/logistics/4.png" alt="签收" />
          <p>签收</p>
        </div>
      </div>
      <div class="orderStatus container_box">
        <p class="orderStatusText">当前状态：派送中</p>
        <p class="logisticsText">
          <span> 运单号码：SF1317356849840 </span>
          <span>物流公司：顺丰速运</span>
        </p>
      </div>
      <div class="logisticsList container_box">
        <div class="logisticsDayList">
          <div
            class="listC"
            v-for="(item, index) of logisticsList"
            :key="'weekDay' + index"
          >
            <p class="weekDay">
              {{ item.logisticsDate }} {{ item.logisticsWeekDay }}
            </p>
            <div class="dayList">
              <p
                :class="{
                  lastNone:
                    index == logisticsList.length - 1 &&
                    indexC == item.list.length - 1,
                }"
                class="dayC"
                v-for="(itemC, indexC) of item.list"
                :key="'weekDay' + index + '-dayItem' + indexC"
              >
                {{ itemC.time }} {{ itemC.message }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="addressContent container_box">
        <p class="deliveryText">
          发货地址： 广东省广州市花都区 金谷北路富力空港物流园P110仓 510800 徐静
          020-28181088
        </p>
        <p class="receiveText">
          收货地址： 河北省保定市满城区 000000 田* 152****7284
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logisticsStatus: 3,
      logisticsList: [
        {
          logisticsDate: "2021-10-03",
          logisticsWeekDay: "周日",
          list: [
            { time: "14:43:59", message: "商品已经下单" },
            { time: "18:01:56", message: "包裹正在等待揽收" },
            { time: "18:28:00", message: "顺丰速运 已收取快件" },
            {
              time: "19:39:00",
              message:
                "快件在【广州花都金谷工业园项目营业部】完成分拣,准备发往 【广州穗佳陆运中转场】",
            },
            { time: "20:06:00", message: "快件到达 【广州穗佳陆运中转场】" },
            {
              time: "21:11:00",
              message:
                "快件在【广州穗佳陆运中转场】完成分拣,准备发往 【广州新石龙铁路站点】",
            },
          ],
        },
        {
          logisticsDate: "2021-10-04",
          logisticsWeekDay: "周一",
          list: [
            { time: "00:29:00", message: "快件到达 【广州新石龙铁路站点】" },
            {
              time: "00:29:00",
              message: "快件在【广州新石龙铁路站点】完成分拣,准备发往下一站",
            },
          ],
        },
        {
          logisticsDate: "2021-10-05",
          logisticsWeekDay: "周二",
          list: [
            { time: "11:51:00", message: "快件到达 【北京黄村集散中心】" },
            {
              time: "11:52:00",
              message:
                "快件在【北京黄村集散中心】完成分拣,准备发往 【华北航空枢纽（北京）】",
            },

            { time: "15:18:00", message: "快件到达 【华北航空枢纽（北京）】" },
            {
              time: "15:18:00",
              message:
                "快件在【华北航空枢纽（北京）】完成分拣,准备发往 【保定北市中转场】",
            },
            { time: "19:32:00", message: "快件到达 【保定北市中转场】" },
          ],
        },

        {
          logisticsDate: "2021-10-06",
          logisticsWeekDay: "周三",
          list: [
            {
              time: "06:23:00",
              message:
                "快件在【保定北市中转场】完成分拣,准备发往 【保定市满城区太行营业点】",
            },
            {
              time: "07:17:00",
              message: "快件到达 【保定市满城区太行营业点】",
            },
          ],
        },
      ],
      saleOrder: "",
    };
  },
  created() {
    let saleOrderId = this.$route.query.id;
    this.saleOrderId = saleOrderId
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/logistics";
</style>