
import '@/assets/styles/base/resetStyle.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import router from './router'
import store from './store'
import Storage from 'vue-ls'
import config from '@/defaultSettings'
import VueLazyLoad from 'vue-lazyload'
import 'babel-polyfill'
import promise from 'es6-promise'
import vshare from 'vshare'
import md5 from 'js-md5'
import {
  date,
  getUnique,
  debounce,
  throttle,
  resizeImg,
  checkStr
} from '@/assets/js/common/util'
import { insetBaiduStatistics,onCopyText } from './assets/js/common/common'
const Base64 = require('js-base64').Base64
promise.polyfill()
// 引入百度统计
// insetBaiduStatistics()

// // 引入TcPlayer
// import { TcPlayer } from './assets/js/common/tcplayer.v4.5.4.min'
// Vue.prototype.TcPlayer = TcPlayer
// end 引入TcPlayer
Vue.config.$ = $
Vue.use(ElementUI)

Vue.use(vshare)
Vue.use(Storage, config.storageOptions)
Vue.config.productionTip = false
Vue.use(VueLazyLoad, {
  error: require('@/assets/images/common/zwtp.png')
})
Vue.prototype.$configOptions = {
  appUrl: process.env.VUE_APP_API_URL,
  h5Url: process.env.VUE_APP_H5_URL,
  appName: process.env.VUE_APP_APP_NAME,
  webTitle: process.env.VUE_APP_WEB_TITLE,
  companyName: process.env.VUE_APP_COMPANY_NAME,
  companyWebUrl: process.env.VUE_APP_COMPANY_WEB_URL,
  copyrightName: process.env.VUE_APP_COPYRIGHT_NAME,
  copyrightValue: process.env.VUE_APP_COPYRIGHT_VALUE,
  resourcePath: process.env.VUE_APP_RESOURCE_PATH,
}
Vue.prototype.$imgPath = ''
Vue.prototype.$store = store
Vue.prototype.onCopyText = onCopyText
Vue.prototype.$md5 = md5
Vue.prototype.$base64 = Base64
Vue.prototype.$util = {
  date,
  getUnique,
  debounce,
  throttle,
  checkStr,
  resizeImg
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
