<template>
  <div class="zone-box">
    <!-- 顶部区域 -->
    <div class="header">
      <div class="logo-box">
        <router-link class="logoLink" to="/index">
          <img v-if="companyConfigData && companyConfigData.pcWhiteLogoUrl && companyConfigData.pcWhiteLogoUrl !== ''"
            :src="companyConfigData.pcWhiteLogoUrl" :alt="$configOptions.webTitle" />
          <img v-else :src="`${$configOptions.resourcePath}/config/logo/company-logo-white.png`" :alt="$configOptions.webTitle" />
        </router-link>
      </div>
      <div class="bg-img-box" :class="{ 'has-acticity-home': $route.name == 'orderPlacing' }">

      </div>
      <!-- <img v-if="$route.name == 'orderPlacing'" class="bg-img" src="@/assets/images/activity/yabang/zone-bg2.png" alt="">
      <img v-else class="bg-img" src="@/assets/images/activity/yabang/zone-bg3.png" alt=""> -->
      <div class="search-content">
        <div class="search-input-content">
          <div class="searchInputBox">
            <input v-model="searchVal" @keyup.enter="searchProduct(searchVal)" type="text" ref="searchInput"
              placeholder="输入商品/厂家汉字或拼音首字母" class="searchInput" />
            <button @click="searchProduct(searchVal)" class="searchBtn">
              <img src="@/assets/images/activity/yabang/search-icon.png" alt="搜索" />
              搜索
            </button>
          </div>
          <div class="cart-btn">
            <router-link class="shoppingCartBtn" to="/shoppingCart">
              <img src="~@/assets/images/activity/yabang/jion-icon.png" alt="">
              <p>我的购物车<span>{{ shoppingCartCount }}</span></p>
            </router-link>
          </div>
        </div>
        <div class="classify-screen">
          <a :class="{ 'activity': productLabelList == 340 }" @click="searchBrandId(340)"
            href="javascript:;">一类金额：<span>{{
              categoryMoney.categoryMoney1 }}元</span></a>
          <a :class="{ 'activity': productLabelList == 341 }" @click="searchBrandId(341)"
            href="javascript:;">二类金额：<span>{{
              categoryMoney.categoryMoney2 }}元</span></a>
          <a :class="{ 'activity': productLabelList == 342 }" @click="searchBrandId(342)"
            href="javascript:;">三类金额：<span>{{
              categoryMoney.categoryMoney3 }}元</span></a>
          <a @click="searchAllProduct"
            href="javascript:;">订货会总金额：<span>{{
              customerIntegral.moneySum  }}元</span></a>
        </div>
      </div>
    </div>
    <!-- END 顶部区域 -->
    <div class="zone-main">
      <div class="left-nav">
        <div class="nav-item">
        </div>
      </div>
      <div class="act-bg">
        <div class="container">
          <router-view @clearSearchInput="searchVal = ''" :categoryMoney="categoryMoney" ref="brandSearch"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction } from '../../api/manage';
export default {
  data() {
    return {
      searchVal: "",
      productLabelList: "",
      categoryMoney: {

      },
      customerIntegral: {

      }
    }
  },
  created() {
    let searchVal = this.$route.query.searchVal || '';
    let productLabelList = this.$route.query.productLabelList || '';
    if (searchVal && searchVal !== '') {
      this.searchVal = searchVal
    }
    if (productLabelList && productLabelList !== '') {
      this.productLabelList = productLabelList
    }
    this.getProductIntegral();
    this.getCustomerIntegral();
  },
  computed: {
    ...mapGetters(["shoppingCartCount", "companyConfigData"]),
  },
  methods: {
    // 搜索专区品牌编码
    searchBrandId(brandId) {
      let routerName = this.$route.name;
      if (brandId === 340 || brandId === 341 || brandId === 342) {
        let routeUrl = this.$router.resolve({
          name: 'classifiedProducts', query: {
            productLabelList: brandId
          }
        })
        if (routerName === 'orderPlacing') {
          window.open(routeUrl.href, '_blank')
        } else {
          location.href = routeUrl.href
        }
      } else {
        this.$router.push({
          name: 'brandProductArea', query: {
            productLabelList: brandId
          }
        })
      }
    },
    // 获取客户三类商品积分
    getProductIntegral() {
      let _this = this;
      let url = '/yushou/yushou/get1';
      getAction(url).then(res => {
        console.log("获取客户三类商品积分为++++++", res)
        if (res.code == 200 && res.success) {
          _this.categoryMoney = res.result;
        } else {
          _this.$message({
            message: '获取定制商品积分失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })
    },
    searchAllProduct() {
      this.productLabelList = "";
      this.searchVal = "";
      if (this.$route.name === "brandProductArea") {
        this.$refs.brandSearch.searchProduct('');
      } else {
        this.$router.push({ path: '/activityZone/brandProductArea', query: { searchVal: '' } })
      }
    },
    // 获取客户订货会积分
    getCustomerIntegral() {
      let _this = this;
      let url = '/yushou/yushou/getJifen';
      getAction(url).then(res => {
        console.log("获取当前用户的订货会积分为++++++", res)
        if (res.code == 200 && res.success) {
          _this.customerIntegral = res.result;
        } else {
          _this.$message({
            message: '请求用户订货会积分失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })
    },
    searchProduct(searchVal) {
      this.searchVal = searchVal.trim();
      let searchName = this.searchVal
      if (this.$route.name === "classifiedProducts") {
        this.$refs.brandSearch.searchProduct(searchName);
      } else if (this.$route.name === "brandProductArea") {
        this.$refs.brandSearch.searchProduct(searchName);
      } else {
        this.$router.push({ path: '/activityZone/brandProductArea', query: { searchVal: searchName } })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.zone-box {

  min-width: 1200px;

  .header {
    background: linear-gradient(91deg, #B10D1E, #E83336, #B10F19);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 1200px;

    .logo-box {
      width: 1180px;
      margin: 0 auto;
      position: absolute;
      top: 30px;
    }

    .bg-img-box {
      width: 100%;
      min-width: 1200px;
      height: 363px;
      background-image: url('https://b2b.jzys.com.cn/resources/images/dhh/yabang/zone-bg3.png');
      background-repeat: no-repeat;
      background-size: 1920 100%;
      background-position: center;
      border-bottom: 13px solid #F23636;
      &.has-acticity-home {
        height: 613px;
        background-image: url('https://b2b.jzys.com.cn/resources/images/dhh/yabang/zone-bg2.png');
      }
    }

    .bg-img {
      width: 100%;
      min-width: 1200px;
      height: auto;
    }

    .search-content {
      width: 1190px;
      position: absolute;
      bottom: 15px;

      .search-input-content {

        width: 1190px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cart-btn {
          width: 226px;
          height: 71px;

          .shoppingCartBtn {
            display: inline-block;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border: 4px solid #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
          }

          img {
            width: 44px;
            height: 44px;
          }

          p {
            font-size: 22px;
            margin: 0 5px;
            font-weight: 400;
            color: #F23636;

            span {
              margin-left: 5px;
              display: inline-block;
              border-radius: 20px;
              background-color: #F23636;
              color: #ffffff;
              font-size: 15px;
              padding: 3px 5px;
              min-width: 28px;
              text-align: center;
            }
          }
        }
      }

      .classify-screen {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;

        a {
          display: inline-block;
          min-width: 200px;
          height: 50px;
          border: 3px solid #F23636;
          background-color: #ffffff;
          color: #F23636;
          font-size: 17px;
          font-weight: bold;
          border-radius: 50px;
          line-height: 44px;
          padding: 0 20px;
          text-align: center;

          &.activity {
            background-color: #F23636;
            color: #ffffff;
            border: 3px solid #ffffff;
          }

          span {
            font-size: 17px;
            font-weight: bold;
          }
        }
      }
    }

    .searchInputBox {
      width: 870px;
      height: 70px;
      @include clear;
      border-radius: 5px;
      overflow: hidden;

      .hot {
        width: 100%;
        @include ellipsis;
        margin-top: 45px;
        font-size: 14px;

        a {
          color: $act-color;
          margin-right: 10px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .searchInput {
        display: block;
        width: 670px;
        height: 70px;
        padding-left: 30px;
        font-size: 24px;
        border: 4px solid #ffffff;
        @include fl;
      }

      .searchBtn {
        cursor: pointer;
        border-radius: 0px;
        width: 202px;
        height: 71px;
        background: linear-gradient(-45deg, #F23636, #FF5A5A);
        @include fr;
        width: 200px;
        height: 70px;
        border: none;
        text-align: center;
        line-height: 40px;
        margin: 0;
        padding: 0;
        font-size: 24px;
        letter-spacing: 5px;
        color: #ffffff;
        border: 4px solid #ffffff;

        img {
          position: relative;
          top: 6px;
          width: 30px;
        }

      }
    }

  }

  .zone-main {
    position: relative;
    min-height: 2500px;
    background: linear-gradient(91deg, #B10D1E, #E83336, #B10F19);

    .left-nav {
      width: 90px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0px;
      background: linear-gradient(91deg, #B10D1E, #E83336, #B10F19);

      border-right: 15px solid#F33434;
      display: flex;
      justify-content: center;

      .nav-item {
        width: 24px;
        height: 100%;
        background: url("~@/assets/images/activity/yabang/left-nav-bg.png");
        background-size: 100% auto;
      }
    }

    .act-bg {
      background-image: url("~@/assets/images/activity/yabang/act-body-bg.png");
      background-size: 100% auto;
      position: relative;
      z-index: 2;
    }

    .container {
      width: 1188px;
      margin: 0 auto;
      min-height: 2500px;
      padding-top: 20px;
    }
  }
}
</style>