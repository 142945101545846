<template>
  <div class="OrderRightBox">
    <div class="orderList">
      <table v-loading="orderLoading" class="orderListTitle">
        <thead>
          <tr class="tableHeader">
            <th class="ddbh">订单编号</th>
            <th class="sqsj">申请时间</th>
            <th class="tklx">退款类型</th>
            <th class="tkje">退款金额</th>
            <th class="tkzt">售后进度</th>
            <th class="sqyy">退款进度</th>
            <th class="shbz">操作</th>
          </tr>
        </thead>
        <tbody v-if="orderList.length > 0">
          <tr
            class="tableList"
            v-for="(item, index) of orderList"
            :key="item.id + '_' + index"
          >
            <td class="ddbh">
              <router-link
                :to="{
                  name: 'applyReturn',
                  query: {
                    refundId: item.id,
                    type: 2,
                  },
                }"
              >
                {{ item.saleOrderId }}
              </router-link>
            </td>
            <td>
              <span class="sqsj">{{ item.createTime }}</span>
            </td>
            <td>
              <span class="tklx">{{ refundType(item.type) }}</span>
            </td>
            <td>
              <span class="tkje">￥{{ item.money }}</span>
            </td>
            <td>
              <span :class="auditStatusClass" class="tkzt">
              {{ item.statusName }}
              </span>
            </td>
            <td>
              <span class="sqyy">{{
                refundStatusText(item.refundStatus)
              }}</span>
            </td>
            <td>
              <span class="shbz">
                <router-link
                  :to="{
                    name: 'applyReturn',
                    query: {
                      refundId: item.id,
                      type: 2,
                    },
                  }"
                  >查看详情</router-link
                >
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <div class="dataNone">
            <div class="dataImg orderNoneImg"></div>
          </div>
        </tbody>
      </table>
    </div>

    <div class="pagination-bottom">
      <el-pagination
        background
        :hide-on-single-page="true"
        v-if="totalCount > 0"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";

export default {
  name: "OrderRight",
  data() {
    return {
      pageNo: 1,
      pages: 20,
      pageSize: 10,
      totalCount: 100,
      searchVal: "",
      orderDate: "",
      orderState: 0,
      orderList: [],
      orderLoading: false,
    };
  },
  created() {
    this.getOrderListData();
  },
  methods: {
    refundType(type) {
      if(type === 1) {
        return "仅退款";
      } else if (type === 2) {
        return "货品退款";
      } else if (type === 3) {
        return "运费退款";
      } else if (type === 4) {
        return "冲红退款";
      }
    },
    refundStatusText(refundStatus) {
      if (refundStatus === "2") {
        return "退款完成";
      } else {
        return "等待退款";
      }
    },
    auditStatusClass(auditStatus) {
      if (auditStatus === 2) {
        return "yitongguo";
      } else if (auditStatus === 3) {
        return "thtd";
      }
    },
    auditStatus(auditStatus) {
      if (auditStatus === 1) {
        return "待审批";
      } else if (auditStatus === 2) {
        return "已通过";
      } else if (auditStatus === 3) {
        return "已驳回";
      }
    },
    changeOrderStatus(num) {
      this.$emit("changeOrderStatus", num);
    },
    searchOrder() {
      this.pageNo = 1;
      this.getOrderListData();
    },
    async getOrderListData() {
      this.orderLoading = true;
      let state = this.orderState;
      // 订单编号首尾去空格(防止用户复制粘贴多复制了空白);
      let searchVal = this.searchVal;
      searchVal = searchVal.replace(/(^\s*)|(\s*$)/g, "");
      // 分页数据，订单状态
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let orderStatus = parseInt(state) - 1;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        orderStatus: orderStatus,
        searchVal: searchVal,
      };
      let url = "saleOrder/saleReturnOrder/list";
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        this.orderLoading = false;
        let resData = res.result;
        console.log("返回的退货申请列表为++++++", resData);
        this.orderList = resData.records;
        this.pageNo = resData.current;
        this.totalCount = resData.total;
      }
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getOrderListData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/order/refund";
::v-deep .el-button--mini {
  padding: 5px 10px;
}
</style>
