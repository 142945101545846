import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import shoppingCart from '@/store/modules/shoppingCart'
import order from '@/store/modules/order'
import common from '@/store/modules/common'
import getters from '@/store/getters'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    shoppingCart,
    order,
    common
  },
  getters
})
