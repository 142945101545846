<template>
  <div class="scroll-text">
    <div class="text" :style="{ animationDuration: duration + 's' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollText',
  props: {
    duration: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.scroll-text {
  width: 185px;
  border-radius: 15px;
  background: #f1f2f4;
  margin: 0 auto;
  margin-top: 3px;
  height: 18px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  display: inline-block;
  padding-left: 100%;
  ;
  animation-name: scroll;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
</style>