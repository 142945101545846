<template>
  <div id="goodsImgBox" class="container">
    <div class="container_box">
      <ul class="goods-list-content">
        <li
          v-for="(item, index) of productList"
          v-bind:key="'imgProduct' + index"
          :class="{
            'offline-sale-item':
              item.offlineSaleFlag && item.offlineSaleFlag == '1',
          }"
        >
          <div class="good-data-content">
            <div class="good-img-content">
              <router-link
                :to="{
                  path: '/productDetail',
                  query: { productId: item.productId },
                }"
                :title="item.name"
                target="_blank"
              >
                <!-- 查看专区产品遮罩图 -->
                <img
                  v-if="specialAreaMaskImg && specialAreaMaskImg !== ''"
                  :src="specialAreaMaskImg"
                  class="maskImg"
                  alt="产品遮罩图"
                />
                <!-- 查看产品标签遮罩图 -->
                <img
                  v-else-if="
                    item.productLabelList &&
                    item.productLabelList.length > 0 &&
                    prolabelMaskImg(item.productLabelList) !== ''
                  "
                  :src="prolabelMaskImg(item.productLabelList)"
                  class="maskImg"
                  alt="产品遮罩图"
                />
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
            <img
              v-if="
                item.policyFlag &&
                item.productPolicyList &&
                item.productPolicyList.length > 0
              "
              class="act-img-icon"
              src="~@/assets/images/product/act-cx.png"
              alt=""
            />
            <img
              v-else-if="specailFlagStatus(item)"
              class="act-img-icon"
              src="~@/assets/images/product/act-tj.png"
              alt=""
            />
            <price-show-text
              :salesPrice="showSalePrice(item)"
              :originalPrice="showOriginalPrice(item)"
              :specailFlag="specailFlagStatus(item)"
            ></price-show-text>
            <div class="goods-name-content">
              <span
                class="goods-tag-list"
                v-if="
                  loginState &&
                  item.inventoryList &&
                  item.inventoryList.length > 0
                "
              >
                <span
                  class="stock-tag"
                  v-if="item.companyName && item.companyName !== ''"
                  :class="item.companyName === '河北仓' ? 'tip3' : 'tip2'"
                  >{{ item.companyName }}</span
                >
                <span
                  v-if="item.medicalType && item.medicalType === '1'"
                  class="yb-tag"
                  >医保</span
                >
                <span v-if="item.inventoryList[0].deliveryTime" class="tip2">{{
                  item.inventoryList[0].deliveryTime
                }}</span>
              </span>
              <span class="goods-name">{{ item.name }} {{ item.format }}</span>
            </div>
            <div
              style="cursor: pointer"
              @click="searchFatory(item.manufacturer)"
              class="factory"
              :title="item.manufacturer"
            >
              {{
                item.manufacturer &&
                item.manufacturer.replace(/\s*/g, "") !== ""
                  ? item.manufacturer
                  : "暂无厂家信息！"
              }}
            </div>
            <div v-if="loginState" class="validity">
              有效期:
              {{
                item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                  ? item.inventoryList[0].endDate
                  : "暂无效期"
              }}
            </div>
            <div v-else class="validity">
              有效期: <span style="color: #fd2c17">会员可见</span>
            </div>
            <!-- 优惠券信息 -->
            <div class="good-discount">
              <p
                class="goods-tag-list"
                v-if="
                  loginState &&
                  item.inventoryList &&
                  item.inventoryList.length > 0
                "
              >
                <span
                  class="tip1"
                  v-if="
                    (item.productActivityPriceStrategyFlag !== 1 ||
                      item.productActivityPriceStrategyDetail.canSaleAmount ===
                        0) &&
                    item.minDiscountTicket &&
                    item.minDiscountTicket.name !== ''
                  "
                >
                  {{ item.minDiscountTicket.name }}
                </span>
              </p>
            </div>
            <!-- 其他标签 -->
            <div class="good-tags">
              <div
                class="goods-tag-list"
                v-if="
                  loginState &&
                  item.inventoryList &&
                  item.inventoryList.length > 0
                "
              >
                <span
                  class="tip2"
                  v-if="
                    item.inventoryList[0].priceTitle &&
                    item.inventoryList[0].priceTitle !== ''
                  "
                  >{{ item.inventoryList[0].priceTitle }}</span
                >

                <el-tooltip
                  v-if="
                    item.policyFlag &&
                    item.productPolicyList &&
                    item.productPolicyList.length > 0
                  "
                  class="item"
                  effect="dark"
                  :content="item.productPolicyList[0].remark"
                  placement="top"
                >
                  <span class="tip1">促销</span>
                </el-tooltip>
                <el-tooltip
                  v-if="item.inventoryList[0].nearDateFlag"
                  class="item"
                  effect="dark"
                  content="该产品为近效期产品"
                  placement="top"
                >
                  <span class="tip4">近效期</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <!-- <div v-if="false" class="inventory"><span :title="`${item.mediumPkgSize}/${item.bigPkgSize}`" class="baozhuang">中/大包装：{{
            item.mediumPkgSize
          }}/{{ item.bigPkgSize }}</span>
          </div> -->
          <transition name="el-zoom-in-bottom">
            <div
              class="goods-bottom-act"
              v-show="showCartActionIndex !== item.id"
            >
              <p class="inventory">
                <span
                  v-if="item.inventoryList && item.inventoryList.length > 0"
                  class="kucun"
                >
                  库存：{{ showInventoryText(item.inventoryNum, item) }}
                </span>
                <span v-else-if="loginState" class="kucun">库存：暂无库存</span>
                <span v-else class="kucun"
                  >库存：<i style="font-style: normal; color: #fd2c17"
                    >会员可见</i
                  ></span
                >
              </p>
              <span @click="showCartActionIndex = item.id" class="cart-icon">
                <img src="~@/assets/images/common/cart-white.png" alt="" />
              </span>
            </div>
          </transition>
          <!-- 添加购物车操作栏 -->
          <transition name="el-zoom-in-bottom">
            <div
              class="cart-input-box transition-box"
              v-show="showCartActionIndex === item.id"
            >
              <div class="input-content">
                <i
                  @click="lessNum(item.inventoryList[0], item)"
                  class="el-icon-minus"
                ></i>
                <input
                  :class="{
                    shoppingHas:
                      item.inventoryList[0].shoppingCartAmount &&
                      item.inventoryList[0].shoppingCartAmount > 0,
                  }"
                  :value="item.inventoryList[0].addCartQuantity"
                  @focus="selectShopAmount($event)"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @keyup.enter="
                    jionShopCart(item.inventoryList[0], item, $event)
                  "
                  @change="changeNum(item.inventoryList[0], item, $event)"
                  type="text"
                />
                <i
                  @click="addNum(item.inventoryList[0], item)"
                  class="el-icon-plus"
                ></i>
              </div>
              <div
                @click="jionShopCart(item.inventoryList[0], item, $event)"
                :style="
                  item.offlineSaleFlag &&
                  item.offlineSaleFlag == '1' &&
                  item.productNotputSaleCreateOrder == '0'
                    ? 'width: 100%'
                    : ''
                "
                class="cart-btn"
              >
                {{
                  item.offlineSaleFlag && item.offlineSaleFlag == "1"
                    ? item.productNotputSaleCreateOrder == "1"
                      ? "开票员订货"
                      : "联系开票员"
                    : "加入购物车"
                }}
              </div>
            </div>
          </transition>
          <!-- <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'"
              class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox"
              :style="(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0') ? 'width: 100%' : ''">
              <img src="~@/assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? item.productNotputSaleCreateOrder == '1' ?
                '开票员订货' : '请联系开票员订货' : '加入购物车' }}
            </div>
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import PriceShowText from "./PriceShowText.vue";
export default {
  components: { PriceShowText },
  name: "GoodsImg",
  props: [
    "productList",
    "isClinicAccount",
    "hyProductStatus",
    "specialAreaMaskImg",
  ],
  mixins: [ProductMixin],
  data() {
    return {
      showMoveDot: [],
      elLeft: 0,
      elTop: 0,
      dropImage: false,
    };
  },
  methods: {
    listTypeChange(num) {
      this.$emit("listTypeChange", num);
    },
    beforeEnter(el) {
      el.style.transform = `translate3d(${this.elLeft + 50}px,${
        this.elTop - 300
      }px , 0)`;
      el.style.opacity = 0;
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/product/img/img";
</style>
