<template>
  <div class="content_box">
    <div class="vip" style="margin-top: 30px">
      <div class="vip_right">
        <div class="vip_right_title">
          <img src="@/assets/images/IntegralShop/dian.png">
          <span>{{navType === 1 ? '礼品列表' : '个人中心'}}</span>
        </div>
        <div class="vip_right_container">
          <div class="account">
            <div class="kyjf clearfix">
              <div class="fl">可用积分：<span class="jf">{{userIntegral}}</span></div>
              <a href="javascript:;" @click="changeNavType" class="dh fl">去兑换礼品</a>
            </div>
            <div class="yyjf clearfix">
              <div class="fl">已用积分：<span class="jf">{{usedIntegral}}</span></div>
              <router-link to="/productList" class="dh zq fl">去获取积分</router-link>
            </div>
          </div>
          <div class="jfjl">
            <div class="jfjl_title">
              {{navType === 1 ? '礼品列表' : '个人中心'}}
            </div>
            <table v-if="navType === 1">
              <thead>
              <tr>
                <th class="lpmc">礼品名称</th>
                <th class="lpsl">礼品数量</th>
                <th class="jfbd">所用积分</th>
                <th class="jfjy">剩余积分</th>
                <th class="time">操作时间</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of userGiftList">
                <td class="lpmc">{{item.integralGiftName}}</td>
                <td class="lpsl">{{item.receiveAmount}}</td>
                <td class="jfjy">{{item.consumeIntegral}}</td>
                <td class="bz">{{item.remainIntegral}}</td>
                <td class="time">{{item.createTime}}</td>
              </tr>
              </tbody>
            </table>
            <table v-else-if="navType === 2">
              <thead>
              <tr>
                <th class="lpmc">积分来源</th>
                <th class="lpsl">积分数量</th>
                <th class="jfbd">获取时间</th>
                <th class="jfjy">剩余积分</th>
                <th class="time">积分备注</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of intergralList" :key="index">
                <td class="lpmc">{{item.integralSourceName}}</td>
                <td :style="item.changeIntegral > 0 ? 'color: #0b51e1' : 'color: #ff4400'" class="lpsl">{{item.changeIntegral}}</td>
                <td class="jfjy">{{item.getTime}}</td>
                <td class="bz">{{item.afterIntegral}}</td>
                <td class="time">{{item.remark ? item.remark : '暂无备注'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="pageBox">
            <el-pagination
              background
              @current-change="pageChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
      <div id="wntj">
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '../../api/manage'

  export default {
    name: "IntegralList",
    props:['userData','navType','userIntegral','usedIntegral'],
    data(){
      return {
        page:1,
        pageSize: 10,
        totalCount:1,
        integral:0,
        userGiftList: [],
        intergralList: [],
      }
    },
    created(){
      let navType = this.navType
      if(navType === 1){
        this.getGiftList();
      }else if(navType === 2){
        this.getUserIntegral()
      }
    },
    methods:{
      changeNavType(){
        this.$emit('changeNavType',0)
      },
      // 获取用户兑换列表
      getGiftList(){
        let _this = this;
        let page = this.page;
        let pageSize = this.pageSize;
        let data = {
          pageNo:page,
          pageSize:pageSize
        };
        let url = '/integral/integralGiftRecord/list';
        console.log('请求的数据为+++++',data)
        getAction(url,data).then(res => {
          console.log('res+++++++',res)
          if(res.code === 200 && res.success){
            let resData = res.result
            _this.userGiftList = resData.records
            _this.page = resData.current
            _this.totalCount = resData.total
          }
        })
      },
      // 页数变动
      pageChange(page){
        console.log('页面变动的数据为++++++', page)
        this.page = page;
        let navType = this.navType
        if(navType === 1){
          this.getGiftList()
        }else {
          this.getUserIntegral()
        }
      },
      // 获取用户的积分记录
      getUserIntegral(){
        let _this = this;
        let page = this.page;
        let pageSize = this.pageSize;
        let data = {
          pageNo:page,
          pageSize:pageSize
        };
        console.log('请求的数据为+++++',data)
        let url = '/integral/customerIntegralRecord/list';
        getAction(url,data).then(res => {
          if(res.code === 200 && res.success){
            let resData = res.result
            _this.intergralList = resData.records
            _this.page = resData.current
            _this.totalCount = resData.total
          }
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/intergralCenter/intergralShop";
  @import "../../assets/styles/intergralCenter/integralList";
</style>
