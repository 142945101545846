<template>
  <div class="SignIntergralBox">
    <div class="qiandao-warp">
      <div class="qiandap-box">
        <div class="qiandao-con clear">
          <div class="qiandao-right">
            <div class="qiandao-top">
              <div
                v-if="qiandaoType"
                class="just-qiandao qiandao-sprits actived"
              >已签到</div>
              <div
                v-else
                @click="qiandaoActive"
                class="just-qiandao qiandao-sprits"
                id="js-just-qiandao"
              >点击签到</div>
              <p v-if="qiandaoType" class="qiandao-notic">
                今日已领<span>{{ qiandaoJf }}</span
                >积分，请明日继续签到
              </p>
            </div>
            <div class="qiandao-bottom">
              <div class="qiandao-rule-list">
                <h4>签到规则：</h4>
                <p v-if="signinRule && signinRule !== ''">每日签到可获得{{signinRule.acquire}}积分</p>
                <p v-if="sustainRule && sustainRule !== ''">连续签到{{sustainRule.total}}天，可额外获得{{sustainRule.acquire}}积分</p>
              </div>
              <div class="qiandao-rule-list">
                <h4>其他说明：</h4>
                <p>
                  {{ manifest }}
                </p>
              </div>
              <!-- <div class="backBox">
                <router-link class="back" to="/integralCenter"
                  >[返回积分商城]</router-link
                >
              </div> -->
            </div>
          </div>
          <div class="qiandao-left">
            <div class="qiandao-left-top clear">
              <div class="current-date">{{ nowDateTime }}</div>
              <div
                v-if="false"
                class="qiandao-history qiandao-tran qiandao-radius"
                id="js-qiandao-history"
              >
                我的签到
              </div>
            </div>
            <div class="qiandao-main" id="js-qiandao-main">
              <ul class="clearfix">
                <li>日</li>
                <li>一</li>
                <li>二</li>
                <li>三</li>
                <li>四</li>
                <li>五</li>
                <li>六</li>
              </ul>
              <ul class="qiandao-list clearfix" id="js-qiandao-list">
                <li
                  v-for="(item, index) of 42"
                  :key="index"
                  :class="classObject(index)"
                >
                {{classIndexText(index)}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="qiandao-layer qiandao-history-layer">
      <div class="qiandao-layer-con qiandao-radius">
        <a href="javascript:;" class="close-qiandao-layer qiandao-sprits"></a>
        <ul class="qiandao-history-inf clear">
          <li>
            <p>连续签到</p>
            <h4>5</h4>
          </li>
          <li>
            <p>本月签到</p>
            <h4>17</h4>
          </li>
          <li>
            <p>总共签到数</p>
            <h4>28</h4>
          </li>
          <li>
            <p>签到累计奖励</p>
            <h4>30</h4>
          </li>
        </ul>
        <div class="qiandao-history-table">
          <table>
            <thead>
              <tr>
                <th>签到日期</th>
                <th>奖励</th>
                <th>说明</th>
              </tr>
            </thead>
            <table>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>分享奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
              <tr>
                <td>2016-1-6 14:23:45</td>
                <td>0.20</td>
                <td>连续签到19天奖励</td>
              </tr>
            </table>
          </table>
        </div>
      </div>
      <div class="qiandao-layer-bg"></div>
    </div>
    <div v-if="false" class="qiandao-layer qiandao-active">
      <div class="qiandao-layer-con qiandao-radius">
        <a href="javascript:;" class="close-qiandao-layer qiandao-sprits"></a>
        <div class="yiqiandao clear">
          <div class="yiqiandao-icon qiandao-sprits"></div>
          您已连续签到<span>2</span>天
        </div>
        <div class="qiandao-jiangli qiandao-sprits">
          <span class="qiandao-jiangli-num">0.55<em>元</em></span>
        </div>
        <a href="#" class="qiandao-share qiandao-tran">分享获取双倍收益</a>
      </div>
      <div class="qiandao-layer-bg"></div>
    </div>
  </div>
</template>

<script>
import { sign } from "crypto";
import { getAction } from "../../api/manage";
import { getUserInfo } from "../../assets/js/common/common";

export default {
  name: "SignIntergral",
  data() {
    return {
      nowDateTime: "",
      monthFirst: 0, // 本月第一天是周几
      totalDay: 0, // 本月一共有多少天
      qiandaoDateList: [],
      qiandaoJf: 0,
      qiandaoType: false,
      signinRule: null, // 每日签到活动规则
      sustainRule: null, // 连续签到积分规则
      manifest: '', // 积分签到说明
    };
  },
  created() {
    // 1. 获取用户获取积分签到记录
    this.getIntegralSignInRecord();
    this.getIntegralSetting();
  },
  methods: {
    // 获取积分设置规则
    async getIntegralSetting() {
    let userInfo = getUserInfo();
      console.log("")
      let companyId = null;
      if(userInfo.company && userInfo.company.id) {
        companyId = userInfo.company.id;
        console.log("获取当前客户的公司编码为++++++", companyId)
      }
      let url = `integralMall/get?companyId=${companyId}`;
      let res = await getAction(url);
      if(res.code == 200 && res.success) {
        let resResult = res.result;
        this.manifest = resResult.manifest;
        if(resResult.integralTaskList && resResult.integralTaskList.length > 0) {
          for(let key of resResult.integralTaskList) {
            if(key.keyword == 'signin') {
              this.signinRule = key;
            } else if(key.keyword == 'sustain') {
              this.sustainRule = key
            }
          }
        }
      }
      console.log("获取当前积分规则为++++++", res)
    },
    // 获取当前系统日期和客户签到天数
    getIntegralSignInRecord() {
      let _this = this;
      let url = "/integralMall/getIntegralSignInRecord";
      getAction(url).then((res) => {
        console.log("签到返回的数据为+++++++", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let currentDate = resData.currentDate;
          if (resData.dateList && resData.dateList.length > 0) {
            _this.qiandaoDateList = resData.dateList;
          }
          if (resData.integral && resData.integral !== "") {
            _this.qiandaoJf = resData.integral;
          }
          if (currentDate && currentDate !== "") {
            _this.renderDateList(currentDate);
          } else {
            _this.renderDateList();
          }
        }
      });
    },
    // 根据系统日期渲染对应的时间
    renderDateList(dateTime) {
      // 1.获取当前时间
      let myDate;
      if (dateTime) {
        myDate = new Date(dateTime);
        let dateTimeArr = dateTime.split("-");
        this.nowDateTime = `${dateTimeArr[0]}年${dateTimeArr[1]}月${dateTimeArr[2]}日`;
      } else {
        myDate = new Date();
        let year = myDate.getFullYear();
        let month = myDate.getMonth();
        let day = myDate.getDate();
        this.nowDateTime = `${year}年${
          month + 1 < 10 ? "0" + (month + 1) : month + 1
        }月${day < 10 ? "0" + day : day}日`;
      }
      // 2.获取当前时间的本月1号是周几
      let monthFirst = new Date(
        myDate.getFullYear(),
        parseInt(myDate.getMonth()),
        1
      ).getDay();
      this.monthFirst = monthFirst;
      let d = new Date(
        myDate.getFullYear(),
        parseInt(myDate.getMonth() + 1),
        0
      );
      // 3.本月一共有多少天
      let totalDay = d.getDate(); //获取当前月的天数
      this.totalDay = totalDay;
      // 4.查看今天是否签到
      let nowDay = myDate.getDate();
      let qiandaoDateList = this.qiandaoDateList;
      console.log("mowDay++++++", nowDay);
      console.log("qiandaoDateList++++++", qiandaoDateList);
      if (qiandaoDateList.indexOf(nowDay) > -1) {
        this.qiandaoType = true;
      } else {
        this.qiandaoType = false;
      }
    },
    // 签到日期的class样式
    classObject(index) {
      let monthFirst = this.monthFirst;
      let qiandaoDateList = this.qiandaoDateList;
      let totalDay = this.totalDay;
      if (
        index >= monthFirst &&
        index < monthFirst + totalDay &&
        qiandaoDateList.indexOf(index + 1 - monthFirst) > -1
      ) {
        return `qiandao`;
      } else if (index >= monthFirst && index < monthFirst + totalDay) {
        return ``;
      } else {
        return "";
      }
    },
    // 签到日期的下标数字样式
    classIndexText(index) {
      let monthFirst = this.monthFirst;
      let qiandaoDateList = this.qiandaoDateList;
      let totalDay = this.totalDay;
      if (
        index >= monthFirst &&
        index < monthFirst + totalDay &&
        qiandaoDateList.indexOf(index + 1 - monthFirst) > -1
      ) {
        return index + 1 - monthFirst;
      } else if (index >= monthFirst && index < monthFirst + totalDay) {
        
        return index + 1 - monthFirst;
      } else {
        return "";
      }
    },
    // 点击签到的操作
    qiandaoActive() {
      let _this = this;
      let url = "/integralMall/signIn";
      let data = {
        customerId: 1,
      };
      getAction(url, data).then((res) => {
        console.log("res签到返回的额数据为+++++++", res);
        if (res.code === 200 && res.success) {
          // 签到成功后重新获取用户已签到的信息
          _this.qiandaoJf = res.result;
          _this.qiandaoType = true;
          _this.getIntegralSignInRecord();
        } else {
          _this.$alert(res.message, "提示信息：", { type: "error" });
        }
      });
      // this.qiandaoJf = 1
      // let myDate = new Date()
      // let nowDate = myDate.getDate()
      // this.qiandaoDateList.push(nowDate)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/intergralCenter/signIntergral";
</style>
