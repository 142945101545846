<template>
  <div>
    <div class="goods-content">
      <div class="goods-list">
        <div class="list-header">
          <div class="checked-box">
            <el-checkbox
              :isIndeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            ></el-checkbox>
          </div>
          <div class="header-item name">
            <span>商品</span>
          </div>
          <div class="header-item batch-number">
            <span>批号</span>
          </div>
          <div class="header-item origin-price">
            <span>商品原价</span>
          </div>
          <div class="header-item discount-price">
            <span>优惠金额</span>
          </div>
          <div class="header-item pay-price">
            <span>实付金额</span>
          </div>
          <div class="header-item refund-amount">
            <span>可退数量</span>

            <el-tooltip
              class="item"
              effect="dark"
              content="可退数量 = 出库中数量 - 申请中数量 - 已退货数量 -已退款数量"
              placement="top"
            >
              <i style="color: #585858" class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="header-item amount">
            <span>申请数量</span>
          </div>
          <div class="header-item refund-money">
            <span>退款金额</span>
          </div>
        </div>
        <el-checkbox-group
          v-model="refundGoodsIds"
          @change="handleCheckedChange"
        >
          <div
            class="list-item"
            v-for="(item, index) in orderItemList"
            :key="index"
          >
            <div class="checked-box">
              <el-checkbox
                v-if="item.canReturnAmount > 0"
                :label="item.id"
                :key="item.id"
              ></el-checkbox>
            </div>
            <div class="item-info name">
              <div class="goods-data">
                <div class="goods-img">
                  <img v-lazy="productDataImg(item)" alt="" />
                </div>
                <div class="good-info">
                  <p :title="item.productName" class="name">
                    {{ item.productName }}
                  </p>
                  <p class="format">{{ item.productFormat }}</p>
                  <p :title="item.productManufacturer" class="manufacturer">
                    {{ item.productManufacturer }}
                  </p>
                </div>
              </div>
            </div>
            <div class="item-info batch-number">
              <span>{{
                item.batchNumber ? item.batchNumber : "暂无批号数据"
              }}</span>
            </div>
            <div class="item-info origin-price">
              <span
                >￥{{
                  item.originalSalePrice ? item.originalSalePrice : "0.00"
                }}</span
              >
            </div>
            <div class="item-info discount-price">
              <span
                >￥{{ item.discountPrice ? item.discountPrice : "0.00" }}</span
              >
            </div>
            <div class="item-info pay-price">
              <span
                >￥{{ item.confirmPrice ? item.confirmPrice : "0.00" }}</span
              >
            </div>
            <div class="item-info refund-amount">
              <span>{{ item.canReturnAmount }}</span>
            </div>
            <div class="item-info amount">
              <div class="amount-box">
                <i
                  @click="lessReturnAmount($event, item)"
                  class="el-icon-minus"
                ></i>
                <input
                  @change="changeReturnAmount($event, item)"
                  onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                  :value="item.applyAmount"
                  class="amount-input"
                  type="text"
                />
                <i
                  @click="addReturnAmount($event, item)"
                  class="el-icon-plus"
                ></i>
              </div>
            </div>
            <div class="item-info refund-money">
              <span>￥{{ refundGoodsMoney(item) }}</span>
            </div>
          </div>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from "../../api/manage";
import { accMuls } from "../../assets/js/common/common";
export default {
  props: {
    refundGoodList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      saleOrderId: "",
      refundGoodsIds: [], // 选中的退货明细id
      canRefundGoodsIds: [], // 订单明细中可以退货的明细id数组
      orderItemList: [], // 銷售退货明细
    };
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    if (saleOrderId && saleOrderId !== "") {
      this.saleOrderId = saleOrderId;
      this.getOrderDeatil();
    } else {
      // 获取退货/售后列表数据
    }
  },
  methods: {
    refundGoodsMoney(item) {
      let confirmPrice = item.confirmPrice;
      let applyAmount = item.applyAmount;
      let money = accMuls(confirmPrice, applyAmount);
      return money;
    },
    // 全选/非全选操作
    handleCheckAllChange(val) {
      this.refundGoodsIds = val ? this.canRefundGoodsIds : [];
      this.isIndeterminate = false;
      this.callbackApplyList();
    },
    // 触发勾选退货商品回调
    callbackApplyList() {
      let refundGoodsIds = this.refundGoodsIds;
      let orderItemList = this.orderItemList;
      let applyList = [];
      for (let key of orderItemList) {
        if (refundGoodsIds.indexOf(key.id) > -1) {
          applyList.push(key);
        }
      }
      this.$emit("callbackApplyList", applyList);
    },
    // 显示产品图片
    productDataImg(item) {
      if (item.productPic && item.productPic !== "") {
        let imgsArr = item.productPic.split(",");
        let imgSrc = this.$util.resizeImg(imgsArr[0], 200);
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },

    // 数量减少操作
    lessReturnAmount(e, item) {
      let applyAmount = 0;
      if (item.applyAmount && item.applyAmount > 0) {
        applyAmount = item.applyAmount;
      }
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      if (applyAmount > 1 && applyAmount <= canReturnAmount) {
        item.applyAmount = applyAmount - 1;
      }
      this.watchApplyAmount(item);
      this.$forceUpdate();
    },
    // 修改输入框数量的操作
    changeReturnAmount(e, item) {
      let applyAmount = parseInt(e.target.value);
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      if (applyAmount > canReturnAmount && canReturnAmount > 0) {
        item.applyAmount = canReturnAmount;
      } else if (applyAmount <= canReturnAmount && applyAmount > 0) {
        item.applyAmount = applyAmount;
      } else {
        this.$message({
          message: "该商品可申请退货数量为0！",
          offset: 400,
          duration: 1000,
          type: "error",
        });
        item.applyAmount = "";
      }
      this.watchApplyAmount(item);
      this.$forceUpdate();
    },
    // 数量添加操作
    addReturnAmount(e, item) {
      let applyAmount = 0;
      if (item.applyAmount && item.applyAmount > 0) {
        applyAmount = item.applyAmount;
      }
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      if (applyAmount >= 0 && applyAmount < canReturnAmount) {
        item.applyAmount = applyAmount + 1;
      }
      this.watchApplyAmount(item);
      this.$forceUpdate();
    },
    // 单个商品勾选
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.canRefundGoodsIds.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.canRefundGoodsIds.length;
      this.refundGoodsIds = value;
      this.callbackApplyList();
    },
    // 申请数量大于0自动选中复选框
    watchApplyAmount(item) {
      let refundGoodsIds = this.refundGoodsIds;
      if (item.applyAmount > 0) {
        let itemId = item.id;
        if (refundGoodsIds.indexOf(itemId) < 0) {
          refundGoodsIds.push(itemId);
        }
      }
      this.handleCheckedChange(refundGoodsIds);
    },
    // 获取订单详情数据
    async getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      let res = await getAction(url, data);
      if (res.code === 200 && res.success) {
        let resData = res.result;
        that.orderItemList = resData.saleOrderItemList;
        let canRefundGoodsIds = [];
        for (let key of resData.saleOrderItemList) {
          if (key.canReturnAmount > 0) {
            canRefundGoodsIds.push(key.id);
          }
        }
        this.canRefundGoodsIds = canRefundGoodsIds;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-content {
  margin-top: 20px;
  .list-header {
    line-height: 47px;
    height: 47px;
    background-color: rgb(248, 248, 248);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .header-item {
      text-align: center;
    }
    span {
      font-size: 13px;
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f5f7fa;
    }
    .item-info {
      padding: 12px 0;
      text-align: center;
      span {
        width: 100px;
        color: #585858;
        text-align: center;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .goods-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        .goods-img {
          img {
            width: 60px;
            height: 60px;
            background: #fff;
            border: 1px solid #f0f0f0;
            margin-right: 8px;
          }
        }
        .good-info {
          text-align: left;
          p {
            font-size: 14px;
            color: #585858;
            width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              color: $act-color;
            }
          }
        }
      }
      &.amount {
        .amount-box {
          width: 120px;
          margin: 0 10px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #dcdfe6;
          user-select: none;
          input {
            width: 60px;
            height: 28px;
            text-align: center;
            border: none;
          }
          i {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f7fa;
            font-size: 14px;
            width: 35px;
            height: 28px;
            &:first-child {
              border-right: 1px solid #dcdfe6;
            }
            &:last-child {
              border-left: 1px solid #dcdfe6;
            }
          }
        }
      }
    }
  }
  .checked-box {
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .el-checkbox__label {
      display: none;
    }
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      color: $act-color !important;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: $act-color;
      border-color: $act-color;
    }
  }
  .name {
    width: 210px;
  }
  .batch-number {
    width: 100px;
  }
  .origin-price,
  .discount-price,
  .pay-price {
    width: 107px;
  }
  .refund-amount {
    width: 100px;
    i {
      margin-left: 3px;
      cursor: pointer;
    }
  }
  .amount {
    width: 140px;
  }
  .refund-money {
    width: 107px;
  }
}
</style>