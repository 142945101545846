<template>
  <div>
    <header-top></header-top>
    <!-- 活动banner图 -->
    <div class="activity-banner">
      <img @error="imgError" :src="imageStatus ? `https://b2b.jzys.com.cn/resources/images/luck-draw-banner/pc/${activityId}.png` : defaultImg" alt="">
    </div>
    <div class="luck-draw-box" ref="drawBox" style="background-color: #e63f36;">
      <div class="container">
        <div class="activity-content">
          <div class="header-box">
            <div class="box-top-bg"></div>
            <div class="box-border-box">
              <div class="box-border-main">{{ activityData.name || '幸运大抽奖' }}</div>
            </div>
          </div>
          <div class="content-box" ref="contentBox">
            <div class="content-top-bg">
            </div>
            <div class="content-border-box">
              <div class="content-border-main">
                <!-- 九宫格抽奖 -->
                <horse-race-lamp :activityData="activityData" v-if="activityData.type === '1'"
                  @winningOpen="winningOpen"></horse-race-lamp>
                <!-- 砸金蛋 -->
                <break-golden-egg :activityData="activityData" v-else-if="activityData.type === '2'"
                  @winningOpen="winningOpen"></break-golden-egg>
                <!-- 刮刮乐 -->
                <scratch-card :activityData="activityData" v-else-if="activityData.type === '3'"
                  @winningOpen="winningOpen"></scratch-card>
              </div>
            </div>
          </div>
        </div>
        <div class="luck-draw-tip">
          <p v-if="activityData.ticketNum && activityData.ticketNum > 0">当前可抽奖次数：<span>{{ activityData.ticketNum }}</span>次</p>
          <p v-else>再购买<span>{{ activityData.needTicketNumber }}</span>元，即可参与抽奖活动</p>
        </div>
        <!-- 本期奖品池 -->
        <current-prize :activityGiftList="activityGiftList"></current-prize>
        <!-- 活动规则 -->
        <activity-rules :activityData="activityData"></activity-rules>
      </div>
      <Footer></Footer>
    </div>
    <winning-modal ref="winning"></winning-modal>
  </div>
</template>

<script>
import HeaderTop from '../components/common/HeaderTop.vue';
import ActivityRules from '../components/activity/ActivityRules.vue';
import CurrentPrize from '../components/activity/CurrentPrize.vue';
import HorseRaceLamp from '../components/activity/HorseRaceLamp.vue';
import BreakGoldenEgg from '../components/activity/BreakGoldenEgg.vue';
import ScratchCard from '../components/activity/ScratchCard.vue';
import WinningModal from '../components/activity/WinningModal.vue';
const defaultImg = require("../assets/images/activity/timeQG.jpg")
import { getAction } from '../api/manage';
export default {
  name: "LuckDrawNew",
  components: {
    CurrentPrize,
    ActivityRules,
    HeaderTop,
    HorseRaceLamp,
    BreakGoldenEgg,
    ScratchCard,
    WinningModal
  },
  data() {
    return {
      activityId: '',
      imageStatus: true,
      defaultImg: defaultImg,
      activityGiftList: [], // 抽奖礼品列表
      activityData: { // 当前抽奖活动数据
      }
    }
  },
  created() {
    this.backTop()
    let activityId = this.$route.query.activityId;
    this.activityId = activityId;
    // 获取奖品数据
    this.getActiveData()
  },
  methods: {
    imgError() {
      this.imageStatus = false;
    },
    // 首次请求,活动数据
    async getActiveData() {
      let _this = this
      let activityId = this.activityId
      let data = {
        id: activityId
      }
      let url = '/activityGamble/activityGamble/queryById'
      let res = await getAction(url, data);
      console.log("获取当前的抽奖活动为++++++", res)
      if (res.code === 200 && res.success) {
        let resData = res.result
        _this.activityData = resData;
        // 处理抽奖礼品池数据，过滤掉空奖数据
        let activityInfo = resData.activityGambleDetailList
        let activityGiftList = activityInfo.filter(item => {
          return item.emptyFlag == 0
        })
        this.activityGiftList = activityGiftList;
      }
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    winningOpen(data) {
      // 中奖后抽奖数量减一
      if(this.activityData.ticketNum > 0) {
        this.activityData.ticketNum  --
      }
      // 开启中奖动画
      this.$refs.winning.open();
      console.log("当前中奖的数据为",data)
      this.$refs.winning.winningData = data;
    }
  },
}
</script>

<style lang="scss" scoped>
.luck-draw-box {
  max-width: 1920px;
  min-width: 1190px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  .luck-draw-tip {
    width: 582px;
    height: 112px;
    background: url("~@/assets/images/activity/luck-draw-num-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 15px auto;

    p {
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #161616;
      line-height: 100px;
      @include ellipsis;
      span {
        color: #e63f36;
        font-size: 22px;
        margin: 0 10px;
      }
    }
  }

  .activity-content {
    width: 1190px;
    margin: 0 auto;
    text-align: center;

    // 活动名称区域样式
    .header-box {
      position: relative;
      width: 550px;
      height: 100px;
      margin: 0 auto;

      .box-top-bg {
        position: absolute;
        bottom: 8px;
        z-index: 1;
        width: 550px;
        height: 80px;
        background: linear-gradient(0deg, #FFA794 0%, #FCB7AB 83%, #FA8064 100%);
        border-radius: 30px;
      }

      .box-border-box {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 550px;
        height: 80px;
        background: linear-gradient(0deg, #FEAEA0 0%, #FFAD99 44%, #FFBFB5 100%);
        border-radius: 16px;

        .box-border-main {
          margin: 9px auto;
          width: 532px;
          height: 62px;
          background: #FE8566;
          border-radius: 16px;
          color: #ffffff;
          font-size: 30px;
          text-align: center;
          line-height: 62px;
          font-weight: 500;
        }
      }
    }

    // 活动外框区域
    .content-box {
      max-width: 1190px;
      min-width: 600px;
      margin: 8px auto;
      position: relative;
      min-height: 530px;
      display: inline-block;
      margin-bottom: 0;

      .content-top-bg {
        max-width: 1190px;
        min-width: 600px;
        min-height: 549px;
        position: relative;
        margin-top: -25px;
        z-index: 1;
        background: linear-gradient(0deg, #FCDFDA 0%, #FD9D8F 100%);
        border-radius: 60px;

      }

      .content-border-box {
        position: relative;
        z-index: 2;
        max-width: 1190px;
        min-width: 600px;
        min-height: 530px;
        background: #FAE3DD;
        border-radius: 30px;
        padding: 15px;
        margin-top: -525px;

        .content-border-main {
          max-width: 1190px;
          min-width: 570px;
          // margin: 15px;
          min-height: 500px;
          background: #FFF9FD;
          border-radius: 20px;
          overflow: hidden;
        }
      }

    }
  }
}

.activity-banner {
  img {
    width: 100%;
    min-width: 1190px;
    height: auto;
  }
}</style>
