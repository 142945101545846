<template>
  <div ref="alertBox" class="alertBox">
    <div class="shuiBox">
      <div class="panel">
        <div ondragstart="return false;" ref="scratchCard" class="scratch-card" unselect>
          <div class="gift-img-box">
            <img v-if="giftData.iconUrl && giftData.iconUrl !==''" :src="giftData.iconUrl" alt="">
            <img v-else src="~@/assets/images/activity/scratchCard/fangzhiBg.png" alt="">
          </div>
          <canvas id="canvas" ref="canvas"></canvas>
          <div v-if="!scratchStart" id="canvas-mask" style="">
            <a @click="startScratch" id="btn" href="javascript:;"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from "../../api/manage";
const guaguaBg = require("../../assets/images/activity/scratchCard/guaguaBg.png")
export default {
  name: "ScratchCard",
  props: ['activityData'],
  data() {
    return {
      scratchStart: false,
      imgStatus: 2,
      moveStatus: 0,
      canvasWidth: 310,
      canvasHeight: 170,
      hasGuaType: 1,
      qingqiuStatus: 0,
      giftData: {}, // 中奖的礼品图片
    }
  },
  created() {
    // 请求后台返回中奖数据
    // this.getPayActivityData();
  },
  mounted() {
    this.hua()
  },
  methods: {
    // 开始刮奖
    startScratch() {
      // 获取客户中奖数据
      this.scratchStart = true;
      this.getGiftData()
    },
    
    // 获取用户中奖信息
    getGiftData() {
      let _this = this
      let url = '/activityGamble/activityGamble/gamble'
      let activityId = this.activityData.id
      let data = {
        id: activityId
      }
      // 触发中奖活动信息动画
      getAction(url, data).then(res => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.giftData = resData;
        }
      })
    },
    hua() {
      let _this = this
      let canvasWidth = _this.canvasWidth
      let canvasHeight = _this.canvasHeight
      let canvas = document.getElementById('canvas') // 获取元素
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      // 创建遮罩层背景
      let img = new Image()
      // 引入遮罩背景图片
      img.src = guaguaBg
      // 创建遮罩画布
      let ctx = canvas.getContext('2d') // 生成画布
      img.onload = function () {
        ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight)
        ctx.globalCompositeOperation = 'destination-out' // 橡皮擦
      }
      let x1 = 0, // 手指开始的位置
        y1 = 0,
        x2 = 0, // 手指移动的位置
        y2 = 0
      // 鼠标在画布中按下操作;  moveStatus控制是否可以擦拭; drawPoint()计算擦拭路径方法
      // 电脑端用onmousedown,移动端用ontouchstart
      canvas.onmousedown = function (e) {
        let scratchStart = _this.scratchStart
        if (scratchStart) {
          // todo鼠标按下请求中奖结果
          if (_this.qingqiuStatus === 0) {
            _this.qingqiuStatus = 1
          }
          _this.moveStatus = 1;
          // 手指按下位置
          /*310 170*/
          x1 = e.offsetX
          y1 = e.offsetY
          _this.drawPoint(ctx, x1, y1)
        }
      }
      // 鼠标滑动进行的操作
      // 电脑端用onmousemove,移动端用ontouchmove
      canvas.onmousemove = function (e) {
        if (_this.moveStatus == 1) {
          x2 = e.offsetX
          y2 = e.offsetY
          _this.drawPoint(ctx, x1, y1, x2, y2)
          x1 = x2
          y1 = y2
        }
      }
      // 鼠标松开停止刮奖
      // 电脑端用onmouseup,移动端用ontouchend
      canvas.onmouseup = function (e) {
        _this.moveStatus = 0;
        _this.hasGuaType = 1;
      }
    },
    // 擦拭路径操作
    drawPoint(ctx, x1, y1, x2, y2) {
      let _this = this;
      let canvasWidth = this.canvasWidth;
      let canvasHeight = this.canvasHeight;
      let bar = 15; // 橡皮擦半径
      ctx.beginPath();
      if (x2) {
        //优化绘制路径
        let asin = bar * Math.sin(Math.atan((y2 - y1) / (x2 - x1)));
        let acos = bar * Math.cos(Math.atan((y2 - y1) / (x2 - x1)))
        let x3 = x1 + asin;
        let y3 = y1 - acos;
        let x4 = x1 - asin;
        let y4 = y1 + acos;
        let x5 = x2 + asin;
        let y5 = y2 - acos;
        let x6 = x2 - asin;
        let y6 = y2 + acos;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x3, y3);
        ctx.lineTo(x5, y5);
        ctx.lineTo(x6, y6);
        ctx.lineTo(x4, y4);
        ctx.closePath();
        ctx.clip();
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.restore();
        ctx.arc(x2, y2, bar, 0, Math.PI * 2, true);
      } else {
        ctx.arc(x1, y1, bar, 0, Math.PI * 2, true);
      }
      ctx.fill();
      var imgData = ctx.getImageData(0, 0, canvasWidth, canvasHeight),
        pixles = imgData.data,
        transPixs = [];
      for (var i = 0, j = pixles.length; i < j; i += 4) {
        var a = pixles[i + 3];
        if (a < 128) {
          transPixs.push(i);
        }
      }
      //计算刮刮卡抹去的面积所占百分比
      let erasingArea = (transPixs.length / (pixles.length / 4) * 100).toFixed(2);
      // 计算刮奖的面积是否超过35%则反馈给父元素弹出中奖动画
      if (erasingArea > 35) {
        console.log("刮开面积超过35%")
        _this.hua()
        _this.scratchStart = false
        let giftData = _this.giftData
        _this.$emit("winningOpen",giftData)
      }
    },
    // 请求客户中奖数据
    getPayActivityData() {
      let _this = this;
      let payOrderId = this.payOrderId;
      let data = {
        payOrderId: payOrderId,
      }
      let url = '/activityFreeOrder/activityFreeOrder/get'
      getAction(url, data).then(res => {
        if (res.code === 200 && res.success && res.result === 1) {
          _this.imgStatus = 1
        } else {
          _this.imgStatus = 0
        }
      })
    },
    // 关闭刮奖活动
    closeMask() {
      let isTtFree = this.imgStatus;
      this.$emit("closeScratchCard", isTtFree)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/activity/seratchCard';
</style>
