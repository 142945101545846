<template>
  <div class="loginContent container">
    <div class="loginHeader container">
      <div class="container_box">
        <div class="logo">
          <router-link to="/index">
            <img v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
              :src="companyConfigData.pcDarkLogoUrl" :alt="$configOptions.webTitle" />
            <img v-else :src="`${$configOptions.resourcePath}/config/logo/company-logo.png`" :alt="$configOptions.webTitle" />
          </router-link>
        </div>
        <div class="telBox">
          <img src="../assets/images/login/tel.png" alt="" />
        </div>
      </div>
    </div>
    <div class="loginMain container">
      <div class="container_box">
        <div class="liuchengBox">
          <div class="liucheng liucheng1">
            <p>验证身份</p>
            <p :class="{ flowTrue: changeFlow >= 1 }" class="circle">1</p>
          </div>
          <div class="liucheng liucheng2">
            <p>重置密码</p>
            <p :class="{ flowTrue: changeFlow >= 2 }" class="circle">2</p>
          </div>
          <div class="liucheng liucheng3">
            <p>重置成功</p>
            <p :class="{ flowTrue: changeFlow >= 3 }" class="circle">3</p>
          </div>
        </div>
        <!-- 验证身份 -->
        <div v-if="changeFlow == 1 || changeFlow == 2" class="changeFlowPwdBox">
          <div class="phoneNumberInput">
            <img class="phoneIcon" src="../assets/images/login/mobliePhone.png" alt="" />
            <input placeholder="请填写绑定的手机号" class="phoneNumber" v-model="customerPhone"
              oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
          </div>

          <div v-if="showVerifyImgCode" class="verifyCodeBox">
            <div class="verifyCode">
              <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
              <input placeholder="请填写图形验证码" ref="verifyCode" @keyup.enter="changeInputFocus" v-model="verifyCode"
                type="text" />
            </div>
            <div @click="taggleVerfyImg" title="看不清？换一张" class="getVerfiyCodeBtn verfy-img">
              <img v-if="verifyImg" :src="verifyImg" alt="" />
              <img v-else src="../assets/images/login/verfiyCodeImg.png" alt="" />
            </div>
          </div>
          <div class="verifyCodeBox">
            <div class="verifyCode">
              <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
              <input v-model="verifyCode2" placeholder="短信验证码" type="text" />
            </div>
            <button :class="!verfiyState ? 'get-code-btn' : 'disabled-code-btn'" :disabled="verfiyState ? true : false"
              @click="phoneVerify" class="getVerfiyCodeBtn">
              {{ verfiyState ? `${timeNumber}S再获取` : "获取验证码" }}
            </button>
          </div>

          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/passwordIcon.png" alt="" />
            <input v-model="newPassword" class="password" ref="password" placeholder="请输入新密码"
              :type="changeShowPwd ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>

          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/confirmPwd.png" alt="" />
            <input v-model="confirmPassword" class="password" ref="password" placeholder="请再次输入新密码"
              :type="changeShowPwd2 ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd2 = true" title="显示密码" v-if="!changeShowPwd2"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd2 = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>
          <div @click="confirmChangePwd" :class="{ changeLoading: changeLoading === true }" class="loginBtn">
            <i v-if="changeLoading" class="el-icon-loading"></i>
            确认
          </div>
        </div>
        <!-- 重置密码 -->
        <div v-if="false" class="changeFlowPwdBox">
          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/passwordIcon.png" alt="" />
            <input class="password" ref="password" placeholder="请输入新密码" :type="changeShowPwd ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>

          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/confirmPwd.png" alt="" />
            <input class="password" ref="password" placeholder="请再次输入新密码" :type="changeShowPwd ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>

          <div @click="changeFlowClick(3)" :class="{ changeLoading: changeLoading === true }" class="loginBtn">
            <i v-if="changeLoading" class="el-icon-loading"></i>
            提交
          </div>
        </div>
        <div v-else-if="changeFlow == 3" class="changeFlowPwdBox success">
          <img class="successImg" src="../assets/images/login/pwdSuccess.png" alt="" />
          <p style="color: #fd2c17;" class="successP">{{ successMessage }}</p>
          <p class="successP">已成功重置密码，{{ timeCount }}S后 将为您跳转到登录页面！</p>
          <p class="successP">若没有跳转，请点击<router-link to="/login">登录</router-link></p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/common/Footer.vue";
import { getAction, postAction } from "../api/manage";
export default {
  components: { Footer },
  name: "ForgetPwd",
  data() {
    return {
      verfiyState: false,
      timeNumber: 120,
      changeLoading: false,
      changeFlow: 1,
      changeShowPwd: false,
      changeShowPwd2: false,
      hasIeBrowser: false,
      verifyImg: '',
      showVerifyImgCode: true,
      customerPhone: '',
      verifyCode: '', // 图形验证码
      verifyKey: '', // 图形验证码的KEY
      verifyCode2: '', // 短信验证码
      newPassword: '', // 新密码
      confirmPassword: '', // 确认密码
      successMessage: '',
      timeCount: 10,
      timer: null,
    };
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  created() {
    clearInterval(this.timer)
    this.taggleVerfyImg()
  },
  methods: {
    changeInputFocus() {
      console.log("图形验证码回车事件")
    },
    // 切换验证码图片
    taggleVerfyImg() {
      let _this = this;
      let url = "customer/getVerifyCode";
      getAction(url).then((res) => {
        let data = res.result;
        if (res.code === 200 && res.success === true) {
          _this.verifyKey = data.key;
          _this.verifyImg = `data:image/jpg;base64,${data.img}`;
        }
      });
    },
    // 手机登录发送验证码操作
    async phoneVerify() {
      let _this = this;
      // 检查图形验证码和手机号码是否填写并验证手机格式是否正确
      let customerPhone = this.customerPhone;
      let verifyCode = this.verifyCode;
      let verifyKey = this.verifyKey;
      // 验证手机号码格式
      if (_this.$util.checkStr(customerPhone, 'mobile') && verifyCode !== '') {
        // 获取短信验证码
        let url = '/customer/customer/updateCustomerPasswordSendPhoneCode1';
        let data = {
          phone: customerPhone,
          verifyCode: verifyCode,
          verifyKey: verifyKey
        }
        let res = await postAction(url, data);
        if (res.code === 200 && res.success) {
          this.verfiyState = true;
          this.changeFlow = 2;
          let timeNumber = this.timeNumber;
          let interVal = setInterval(function () {
            timeNumber--;
            _this.timeNumber = timeNumber;
            if (timeNumber == 0) {
              clearInterval(interVal);
              _this.timeNumber = 120;
              _this.verfiyState = false;
            }
          }, 1000);
        } else {
          this.$message.error(res.message)
        }
      } else if (_this.$util.checkStr(customerPhone, 'mobile')) {
        this.$message.error("手机号码格式不正确！")
        return false;
      } else if (!verifyCode || verifyCode === '') {
        this.$message.error("请填写图形验证码！")
        return false;
      }
    },
    // 确认修改密码操作
    async confirmChangePwd() {
      let _this = this;
      let customerPhone = this.customerPhone;
      let verifyCode2 = this.verifyCode2;
      let newPassword = this.newPassword;
      let confirmPassword = this.confirmPassword;
      if (!customerPhone || customerPhone === '') {
        this.$message.error("请填写绑定的手机号码")
        return false;
      }
      if (!verifyCode2 || verifyCode2 === '') {
        this.$message.error("请填写短信验证码")
        return false;
      }
      if (!newPassword || newPassword === '') {
        this.$message.error("请填写新密码")
        return false;
      }
      if (!confirmPassword || confirmPassword === '') {
        this.$message.error("请填写确认密码")
        return false;
      }
      if (newPassword !== confirmPassword) {
        this.$message.error("两次输入的密码不一致！")
        return false;
      }
      let url = '/customer/customer/updateCustomerPassword1';
      let data = {
        phone: customerPhone,
        verifyCode: verifyCode2,
        newPassword: _this.$md5(newPassword).substring(8, 24),
        confirmPassword: _this.$md5(confirmPassword).substring(8, 24),
      }
      let res = await postAction(url, data);
      if (res.code === 200 && res.success) {
        this.successMessage = res.message;
        this.changeFlowClick(3)
      } else {
        this.$message.error(res.message)
      }

    },
    // 忘记密码下一步操作
    changeFlowClick(index) {
      let _this = this;
      this.changeLoading = true;
      setTimeout(function () {
        _this.changeFlow = index;
        _this.changeLoading = false;
      }, 1000);
      if (index === 3) {
        _this.timer = setInterval(() => {
          _this.timeCount--;
          if (_this.timeCount <= 0) {
            clearInterval(_this.timer)
            _this.$router.push("/login")
          }
        }, 1000)
      }
    },
  },
  destroyed() {
    clearInterval(this.timer)
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/login/forgetPwd";
</style>