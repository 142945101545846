<template>
  <div class="gold-egg-box">
    <!--砸蛋区域-->
    <div class="box">
      <div v-if="hitIndex > -1" :class="{ hit: hitFlag }"
        :style="{ top: shakPosition.top + 'px', left: shakPosition.left + 'px' }" id="hammer" class="shak">
      </div>
      <ul class="egg clearfix">
        <li :ref="`goldeggItem-${item}`" @click="hitGoldenEgg(item, index)" v-for="(item, index) in goldenEgg"
          :key="index">
          <img v-if="openGoldenEgg.indexOf(item) > -1" src="~@/assets/images/activity/breakGoldenEgg/step3.png"
            class="goldegg">
          <img v-else src="~@/assets/images/activity/breakGoldenEgg/egg.png" :class="item === jumpIndex ? 'jump' : ''"
            class="goldegg init">
          <img src="~@/assets/images/activity/breakGoldenEgg/base.png">
          <div class="info" :style="item === jumpIndex ? 'display: block' : ''"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getAction } from '../../api/manage';
import WinningModal from './WinningModal.vue';
export default {
  components: { WinningModal },
  props: ['activityData'],
  name: "BreakGoldenEgg",
  data() {
    return {
      goldenEgg: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      jumpGoldenEgg: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      openGoldenEgg: [], // 以及打开的金蛋下标数组
      jumpIndex: -1,
      jumpTimer: null,
      hitIndex: 3, // 砸蛋位置
      hitFlag: false,
      shakPosition: {
        top: 20,
        left: 520
      }
    }
  },
  methods: {
    // 金蛋跳动动画
    jumpTimerAgain() {
      let _this = this;
      let index = -1;
      let jumpIndex = this.jumpIndex;
      let jumpGoldenEgg = this.jumpGoldenEgg;
      this.jumpTimer = setInterval(function () {
        if (index >= jumpGoldenEgg.length - 1) {
          jumpIndex = jumpGoldenEgg[0]
          index = 0
        } else {
          jumpIndex = jumpGoldenEgg[index + 1]
          index++;
        }
        _this.jumpIndex = jumpIndex
        _this.$forceUpdate()
      }, 1000)
    },
    // 用户选择砸开的金蛋
    hitGoldenEgg(item, index) {
      let _this = this;
      // 判断当前蛋是否被砸过或者是否正在进行砸蛋请求礼物操作
      let openGoldenEgg = this.openGoldenEgg; // 砸开蛋的数组
      let hitFlag = this.hitFlag; // 砸蛋进行中
      if (openGoldenEgg.indexOf(item) > -1) {
        this.$alert("这枚金蛋已经被您砸开了!", { type: 'warning' })
      } else if (hitFlag) {
        this.$message({
          message: '别心急，正在砸蛋中!',
          offset: 400,
          duration: 2000,
          type: 'warning'
        })
      } else {
        // 设置砸蛋位置和锤子定位动画
        this.hitIndex = index;
        let goldeggItem = this.$refs[`goldeggItem-` + item];
        // 获取当前的金蛋位置，锤子位置left = 金蛋位置left + 90； 锤子位置top = 金蛋位置top -33
        this.shakPosition = {
          top: goldeggItem[0].offsetTop - 33,
          left: goldeggItem[0].offsetLeft + 90
        }
        // 开启1秒后执行砸蛋操作
        setTimeout(function () {
          _this.hitFlag = true
          _this.getCustomerGiftData(item)
        }, 1000)
      }
    },
    // 获取当前用户的中奖信息
    getCustomerGiftData(item) {
      let _this = this;
      let jumpGoldenEgg = this.jumpGoldenEgg;
      let url = '/activityGamble/activityGamble/gamble'
      let activityId = this.activityData.id
      let data = {
        id: activityId
      }
      // 触发中奖活动信息动画
      getAction(url, data).then(res => {
        if (res.code === 200 && res.success) {
          let resData = res.result
          // 中奖后 1.重置砸蛋状态
          _this.hitFlag = false
          // 2.将当前砸开的金蛋下标放进打开数组中
          _this.openGoldenEgg.push(item);
          // 3.将当前金蛋位置从跳动动画数组中移除
          _this.clearJumpInterval()
          let removeIndex = jumpGoldenEgg.indexOf(item)
          jumpGoldenEgg.splice(removeIndex, 1)
          _this.jumpGoldenEgg = jumpGoldenEgg;
          console.log("跳动动画数组222", jumpGoldenEgg)
          _this.jumpTimerAgain();
          // 开启中奖动画
          _this.$emit('winningOpen', resData)
        } else {
          _this.hitFlag = false
          this.$alert(res.message, '温馨提示', {
            type: 'error'
          })
        }
      })
    },
    clearJumpInterval() {
      let _this = this
      clearInterval(_this.jumpTimer)
      _this.jumpTimer = null
    }
  },
  mounted() {
    this.jumpTimerAgain()
  },
  unmounted() {
    this.clearJumpInterval()
  },
  destroyed() {
    this.clearJumpInterval()
  }
}
</script>

<style lang="scss" scoped>
// 锤子动画
#hammer {
  position: absolute;
  z-index: 10;
  width: 84px;
  height: 91px;
  background-image: url("~@/assets/images/activity/breakGoldenEgg/hammer.png");
  background-size: 100% 100%;
  left: 510px;
  top: 20px;
  -o-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -o-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.shak {
  -o-animation: hammer-move .5s linear infinite alternate;
  -ms-animation: hammer-move .5s linear infinite alternate;
  -moz-animation: hammer-move .5s linear infinite alternate;
  -webkit-animation: hammer-move .5s linear infinite alternate;
  animation: hammer-move .5s linear infinite alternate;
}

@keyframes hammer-move {
  100% {
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

// 砸蛋动画
.hit {
  -o-animation: hammer-hit .2s 3 alternate;
  -ms-animation: hammer-hit .2s 3 alternate;
  -moz-animation: hammer-hit .2s 3 alternate;
  -webkit-animation: hammer-hit .2s 3 alternate;
  animation: hammer-hit .2s 3 alternate;
}

@keyframes hammer-hit {
  100% {
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

.gold-egg-box {
  overflow: hidden;

  .box {
    width: 570px;
    height: 570px;
    background-color: #fff071;
  }

  .clearfix {
    &::after {
      display: block;
      content: "";
      clear: both;
    }

    li {
      position: relative;
      float: left;
      width: 120px;
      height: 130px;
      margin: 20px 35px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: 120px;

        &.goldegg {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          top: 5px;

          &.jump {
            animation: jump .5s infinite alternate;
          }
        }
      }

      @keyframes jump {
        100% {
          top: -5px;
        }
      }

      .info {
        display: none;
        position: absolute;
        width: 55px;
        height: 34px;
        background-image: url(~@/assets/images/activity/breakGoldenEgg/tips.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        top: 10px;
        left: -15px;
        z-index: 3;
      }
    }
  }
}
</style>