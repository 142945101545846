<template>
    <div class="goods-price">
        <p v-if="!loginState" class="price-tips">
            <span class="price">登录后可见</span>
        </p>
        <p v-else-if="loginState && salesPrice === '请联系开票员购买'" class="price-tips">
            <span class="price">请联系开票员购买</span>
        </p>
        <div v-else class="price-show">
            <span v-if="specailFlag" class="specail-tag">特价</span>
            <!-- 销售价格 -->
            <p class="sales-price price-box">
                <span class="cur-type">{{ currencyType }}</span>
                <span class="price" :style="size ? `font-size: ${size}px` : ''">{{ salesPrice }}</span>
            </p>
            <!-- 商品原价 -->
            <p v-if="originalPrice && originalPrice !== '' && !lineThrough" class="original-price price-box">
                原价:<span>{{ currencyType }}{{ originalPrice }}</span>
            </p>
            <p v-if="originalPrice && originalPrice !== '' && lineThrough" class="original-price price-box">
                <span style="text-decoration: line-through;">{{ currencyType }}{{ originalPrice }}</span>
            </p>
            <!-- 折后价 -->

            <p v-if="discountPrice && discountPrice !== ''" class="discount-price price-box">
                折后约:<span>{{ currencyType }}{{ discountPrice }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { getUserInfo } from '../../assets/js/common/common';

export default {
    props: {
        currencyType: {
            type: String,
            default: '￥'
        },
        size: {
            type: [String, Number],
            default: '24'
        },
        salesPrice: {
            type: [String, Number],
            default: '',
        },
        originalPrice: {
            type: [String, Number],
            default: '',
        },
        discountPrice: {
            type: [String, Number],
            default: '',
        },
        specailFlag: {
            type: Boolean,
            default: false,
        },
        lineThrough: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loginState: false,
        }
    },
    created() {
        let userInfo = getUserInfo();
        if (userInfo) {
            this.loginState = true
        } else {
            this.loginState = false
        }
    }
}
</script>

<style lang="scss" scoped>
.goods-price {

    .price-tips {
        height: 35px;
        line-height: 35px;
        color: $act-color;

        .price {
            font-size: 15px;
        }
    }

    .price-show {
        line-height: 30px;

        p {
            display: inline;
        }

        .specail-tag {
            display: inline-block;
            width: 37px;
            height: 18px;
            line-height: 18px;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            margin-right: 4px;
            background: linear-gradient(-90deg, #ff6e00, #ff1e00);
        }

        .price-box {
            margin-left: 10px;
        }

        .sales-price {
            height: 30px;
            color: $act-color;
            margin-left: 0px !important;

            .price {
                font-size: 24px;
            }
        }

        .original-price,
        .discount-price {
            color: #585858;
            padding-bottom: 5px;
        }

        .discount-price {
            color: #ff6600;
        }
    }
}
</style>