<template>
  <transition name="el-fade-in-linear">
    <div v-show="advertShowStatus" class="advertMaskBox">
      <div class="maskBox"></div>
      <div class="advertContent">
        <span>
          <i @click="closeAdvertAct" class="el-icon-close"></i>
        </span>
        <a :href="advertUrl" class="advertImg" target="_blank">
          <img :src="advertImg" alt="" />
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { getAction } from "../../api/manage";
export default {
  name: "AdvertMask",
  data() {
    return {
      advertShowStatus: false,
      timer: null,
      advertImg: "",
      advertUrl: ""
    };
  },
  created() {
    // 获取当前广告位信息
    this.getAdertList();
  },
  methods: {
    getAdertList() {
      let _this = this;
      let hideAdvertStatus = this.$ls.get("hideAdvertStatus");
      if (!hideAdvertStatus) {
        let data = {
          type: 2,
        };
        let url = "/homeShow/ad/list";
        getAction(url, data).then((res) => {
          if (res.code === 200 && res.success && res.result && res.result.length > 0) {
            let result = res.result;
            _this.advertShowStatus = true;
            _this.advertImg = result[0].picUrl;
            _this.advertUrl = result[0].url;
            // 十秒后自动关闭弹出层
            _this.closeAdvert()
            console.log("返回的广告列表数据+++++", result[0].picUrl);
          }
        });
      } else {
        _this.advertShowStatus = false;
      }
    },
    // 十秒后自动关闭广告层
    closeAdvert() {
      let _this = this;
      this.timer = setTimeout(function () {
        _this.advertShowStatus = false;
      }, 10000);
    },
    // 用户关闭广告后，一天只能不再显示
    closeAdvertAct() {
      this.advertShowStatus = false;
      this.$ls.set("hideAdvertStatus", true, 24 * 60 * 60 * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.advertMaskBox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3333;
  .maskBox {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.4;
  }
  .advertContent {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    span {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 40px;
      background: #000000;
      opacity: 0.6;
      cursor: pointer;
      i {
        font-size: 30px;
        color: #ffffff;
        margin-top: 5px;
      }
      &:hover {
        opacity: 0.4;
      }
    }
    .advertImg {
      display: inline-block;
      img {
        max-height: 70vh;
        max-width: 80vw;
        height: auto;
        width: auto;
      }
    }
  }
}
</style>