<template>
  <div class="cart-page">
    <header-top></header-top>
    <order-process @showExcelImportModal="showExcelImportModal"></order-process>
    <right-nav ref="rightNavContent"></right-nav>
    <div class="cart-content container_box">
      <div class="cart-type-tab">
        <p
          @click="selectCartStatus(1)"
          class="type-all"
          :class="{ active: cartStatus === 1 }"
        >
          全部商品<span>{{ shopCartCheckIds.length }}</span>
        </p>
        <p
          @click="selectCartStatus(2)"
          class="type-error"
          :class="{ active: cartStatus === 2 }"
        >
          失效商品<span>{{ errorShoppingCartList.length }}</span>
        </p>
      </div>
      <div v-if="canUseDiscountTicketList.length > 0" class="ticketListContent">
        <div class="ticketImgList">
          <p
            @click="ticketShowState = !ticketShowState"
            class="ticketIconTitle"
          >
            优惠券
            <i
              v-if="!ticketShowState"
              class="ticketRight el-icon-arrow-right"
            ></i>
            <i v-else class="ticketRight el-icon-arrow-down"></i>
          </p>
          <div v-if="ticketShowState" class="ticketList">
            <h3 class="ticketTitle">可用优惠券</h3>
            <el-scrollbar class="ticketListMain">
              <div
                class="listContent"
                v-for="(item, index) of canUseDiscountTicketList"
                :key="'ticketItem' + index"
              >
                <i class="ticketIcon"></i>
                <div class="ticketDetailD">
                  <!-- <span>平台券</span> -->
                  <span>{{ item.name }}</span>
                  <!-- <p>{{ item.detail }}</p> -->
                </div>
                <span @click="selectTicket(item)" class="useBtn">使用</span>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <p class="ticketDeatil">{{ actTicketData.name }}</p>
      </div>
      <div class="select-address">
        <div v-if="false" class="add-remark-btn customerAdd">
          <a @click="addOrderRemark" href="javascript:;">添加备注信息</a>
        </div>
        <div v-if="customerAddressList.length > 0" class="customerAdd">
          <div class="addLeftBox">
            <i class="el-icon-location"></i>
            <div v-if="actAddressListData.length > 0" class="addressText">
              收货地址:
              <span>{{ actAddressListData[0].contactsName }}</span>
              <span>{{ actAddressListData[0].contactsTel }}</span>
              <span>{{ actAddressListData[0].address }}</span>
            </div>
            <div v-if="addressShowState" class="customerList">
              <el-scrollbar class="addressBoxList">
                <el-radio-group @change="actAddress" v-model="selecedAddId">
                  <el-radio
                    class="addIdItem"
                    v-for="(item, index) of customerAddressList"
                    :key="'addId' + item.id + 'index' + index"
                    :label="item.id"
                  >
                    <div class="addressData">
                      <p>收货人: {{ item.contactsName }}</p>
                      <p>{{ item.contactsTel }}</p>
                    </div>
                    <div :title="item.address" class="add">
                      收货地址: {{ item.address }}
                    </div>
                  </el-radio>
                </el-radio-group>
              </el-scrollbar>
              <div class="goto-address">
                <router-link to="/addressList"
                  ><i class="el-icon-edit"></i>地址管理</router-link
                >
              </div>
            </div>
            <div class="addActBox">
              <a v-if="false" @click="editAddStatus = true" href="javascript:;"
                >修改本地址</a
              >
              <a
                @click="addressShowState = !addressShowState"
                href="javascript:;"
              >
                使用其他地址
                <i v-if="addressShowState" class="el-icon-arrow-down"></i>
                <i v-else class="el-icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="customerAdd">
          <p>
            暂无可用收货地址，点击<router-link
              class="add-address"
              to="addressList"
              >立即添加</router-link
            >
          </p>
        </div>
      </div>
    </div>
    <div class="list-content" v-loading="shopLoading === 0">
      <div v-if="shopLoading === 1" class="container_box">
        <div class="cart-list">
          <div class="cart-main fontSize16" v-loading="listOperatLoaing">
            <el-row class="table-header">
              <el-col :span="5">
                <div class="checked-all">
                  <el-checkbox
                    class="checked-box"
                    :indeterminate="isIndeterminate"
                    v-model="shopCartCheckAll"
                    @change="handleCheckAllChange"
                  >
                  </el-checkbox>
                  全选
                </div>
              </el-col>
              <el-col :span="5">
                <div class="goods-data">商品</div>
              </el-col>
              <el-col :span="4">
                <div class="goods-price">单价</div>
              </el-col>
              <el-col :span="4">
                <div class="amount">采购数量</div>
              </el-col>
              <el-col :span="4">
                <div class="price">金额</div>
              </el-col>
              <el-col :span="2">
                <div class="operation">操作</div>
              </el-col>
            </el-row>
            <el-checkbox-group
              v-show="cartStatus === 1"
              v-model="checkedCart"
              @change="handleCheckedCitiesChange"
            >
              <div
                v-for="(item, index) of companyShoppingCartList"
                :key="index"
              >
                <!-- 购物车列表分仓显示 -->
                <div v-if="item.shoppingCartList.length > 0" class="store">
                  <div class="store-header">
                    <el-row>
                      <el-col :span="10">
                        <div class="store-name">
                          <el-checkbox
                            class="checked-box"
                            :indeterminate="
                              isIndeterminateStock[item.companyId]
                            "
                            v-model="item.companyId"
                            :label="item.companyId"
                            @change="handleStockAllChecked(item, $event)"
                          >
                          </el-checkbox>
                          <span
                            >{{ $configOptions.appName }}-{{
                              item.companyName
                            }}</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div
                          v-if="item.logisticsMoney && item.logisticsMoney > 0"
                          class="freight-tips"
                        >
                          满￥{{ item.notLogisticsMoney.toFixed(2) }}免运费
                        </div>
                        <div v-else v-html="'&nbsp;'"></div>
                      </el-col>
                      <el-col :span="6">
                        <div class="toggle-show">
                          <span @click="toggleStockGoods(item.companyId)">
                            {{
                              toggleStockIds.indexOf(item.companyId) > -1
                                ? "收起商品"
                                : "展开商品"
                            }}
                            <i
                              :class="
                                toggleStockIds.indexOf(item.companyId) > -1
                                  ? 'el-icon-arrow-down'
                                  : 'el-icon-arrow-up'
                              "
                            ></i>
                          </span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="cart-list-main">
                    <div class="shop">
                      <div class="goods-list">
                        <ul
                          class="list-ul"
                          v-show="toggleStockIds.indexOf(item.companyId) > -1"
                        >
                          <li
                            v-for="(goods, goodsIndex) of item.shoppingCartList"
                            :key="goodsIndex"
                            :class="{
                              selected: checkedCart.indexOf(goods.id) > -1,
                            }"
                            class="goods-content"
                          >
                            <el-row>
                              <el-col :span="10">
                                <div class="goods-data">
                                  <el-checkbox :label="goods.id"></el-checkbox>
                                  <div class="goods-main-info">
                                    <div class="goods-img">
                                      <router-link
                                        :to="{
                                          path: '/productDetail',
                                          query: { productId: goods.productId },
                                        }"
                                        :title="goods.productName"
                                        target="_blank"
                                      >
                                        <img
                                          v-lazy="productImg(goods)"
                                          alt=""
                                        />
                                      </router-link>
                                    </div>
                                    <div class="goods-info">
                                      <div class="name">
                                        <router-link
                                          :to="{
                                            path: '/productDetail',
                                            query: {
                                              productId: goods.productId,
                                            },
                                          }"
                                          :title="goods.productName"
                                          target="_blank"
                                        >
                                          {{ goods.productName }}</router-link
                                        >
                                      </div>
                                      <div class="format">
                                        {{ goods.productFormat }}
                                      </div>
                                      <div class="manufacture">
                                        {{ goods.productManufacturer }}
                                      </div>
                                      <div
                                        v-if="
                                          goods.endDate && goods.endDate !== ''
                                        "
                                        class="end-date"
                                      >
                                        <i>有效期</i
                                        >{{ cleanEndDate(goods.endDate) }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="price-content">
                                  <div class="price">
                                    <span>￥{{ goods.price }}</span> /
                                    {{ goods.productUnit }}
                                  </div>
                                  <!-- 储值价格填写 -->
                                  <div
                                    v-if="goods.upPriceStrategy === '1'"
                                    class="sale-man-price"
                                  >
                                    <span>储值价:</span>
                                    ￥
                                    <input
                                      class="sale-price-input"
                                      @change="changeShopCartItemAmount(goods)"
                                      onkeyup="this.value=this.value.replace(/^(\-?\d+)\.(\d{2}).*$/, '$1.$2');"
                                      v-model="goods.updatePrice"
                                      type="text"
                                    />
                                  </div>
                                  <!-- 医保价格展示 -->
                                  <div
                                    v-else-if="
                                      goods.upPriceStrategy === '2' &&
                                      goods.medicalPrice &&
                                      goods.medicalPrice !== ''
                                    "
                                    class="sale-man-price"
                                  >
                                    <span>医保价格:</span>
                                    <span class="yb-price"
                                      >￥{{ goods.medicalPrice }}</span
                                    >
                                  </div>
                                  <!-- end 医保价格展示 -->
                                  <!-- 业务员改价 -->
                                  <div
                                    v-if="updatePriceFlag"
                                    class="sale-man-price"
                                  >
                                    <span>业务员价格：</span>
                                    {{
                                      !goods.giftFlag || goods.giftFlag !== "1"
                                        ? "￥"
                                        : "￥" + goods.originalPrice
                                    }}
                                    <input
                                      type="text"
                                      v-if="
                                        !goods.giftFlag ||
                                        goods.giftFlag !== '1'
                                      "
                                      @change="changeSalePrice(goods, $event)"
                                      oninput="value=value.replace(/[^\d^\.]+/g,'')"
                                      onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                                      v-model="goods.salePrice"
                                      class="sale-price-input"
                                    />
                                  </div>
                                  <div
                                    v-if="
                                      goods.discountPrice &&
                                      goods.discountPrice !== ''
                                    "
                                    class="discount-price"
                                  >
                                    <span
                                      >折后约:￥{{ goods.discountPrice }}</span
                                    >
                                  </div>
                                  <!-- 商品标签说明 -->
                                  <div class="tags">
                                    <span
                                      v-if="
                                        goods.productOfflineSaleFlag &&
                                        goods.productOfflineSaleFlag == '1'
                                      "
                                      class="tips tip1"
                                      >开票员订货</span
                                    >
                                    <span
                                      style="cursor: pointer"
                                      v-else-if="
                                        goods.inventoryTypeId &&
                                        goods.inventoryTypeId == 'YUSHOU'
                                      "
                                      class="tips tip1"
                                      >{{ goods.inventoryTypeName }}</span
                                    >
                                    <span
                                      v-else-if="
                                        goods.productActivityPriceStrategyId &&
                                        goods.productActivityPriceStrategyId !==
                                          ''
                                      "
                                      class="tips tip1"
                                      >秒杀</span
                                    >

                                    <span
                                      v-else-if="
                                        goods.newCustomerPriceFlag &&
                                        goods.newCustomerPriceFlag == 1
                                      "
                                      class="tips tip3"
                                      >特价</span
                                    >
                                    <el-tooltip
                                      v-else-if="goods.policyFlag == '1'"
                                      class="goods"
                                      effect="light"
                                      :content="goods.policyRemark"
                                      placement="top"
                                    >
                                      <span class="tips tip3">促销</span>
                                    </el-tooltip>
                                    <span
                                      v-else-if="
                                        goods.nearDateFlag &&
                                        goods.nearDateFlag == 1
                                      "
                                      class="tips tip4"
                                      >近效期</span
                                    >
                                    <span
                                      v-if="
                                        goods.inventoryTypeId &&
                                        goods.inventoryTypeId == 'YUSHOU'
                                      "
                                      @click.stop="gotoDhh"
                                    >
                                      <span
                                        style="cursor: pointer"
                                        class="tips tip3"
                                        v-for="labelItem in classifyLabelList(
                                          goods
                                        )"
                                        :key="labelItem.id"
                                      >
                                        {{ labelItem.name }}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="amount-content">
                                  <div class="input-box">
                                    <span
                                      @click="lessAmount(goods)"
                                      class="el-icon-minus"
                                    ></span>
                                    <input
                                      @change="changeAmount(goods, $event)"
                                      onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                                      :value="goods.amount ? goods.amount : 1"
                                      type="text"
                                    />
                                    <span
                                      @click="addAmount(goods)"
                                      class="el-icon-plus"
                                    ></span>
                                  </div>
                                  <div class="tips">
                                    <span
                                      >中包装：{{
                                        goods.productMediumPkgSize
                                      }}/{{ goods.productUnit }}
                                    </span>
                                    <span
                                      class="split-tips"
                                      :class="{
                                        disabled: goods.productStep > 1,
                                      }"
                                    >
                                      {{
                                        goods.productStep > 1
                                          ? "不可拆零"
                                          : "可拆零"
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="goods-sum">
                                  ￥
                                  {{
                                    goods.productMoney
                                      ? goods.productMoney
                                      : "--/--"
                                  }}
                                </div>
                              </el-col>
                              <el-col :span="2">
                                <div class="operate-list">
                                  <a
                                    @click="removeProduct(goods)"
                                    class="delete"
                                    href="javascript:;"
                                    >删除</a
                                  >
                                  <a
                                    @click="customerCollect(goods, 1)"
                                    class="collect"
                                    href="javascript:;"
                                    >添加收藏</a
                                  >
                                </div>
                              </el-col>
                            </el-row>
                            <!-- 赠品数据 -->

                            <el-row
                              class="goods-gift"
                              v-if="
                                goods.policySendFlag == '1' &&
                                goods.productPolicy &&
                                goods.productPolicy.giftType == '1'
                              "
                            >
                              <el-col :span="10">
                                <div class="goods-data">
                                  <div class="goods-main-info">
                                    <div class="goods-img">
                                      <a
                                        href="JavaScript:;"
                                        :title="goods.productPolicy.giftName"
                                      >
                                        <img
                                          v-lazy="
                                            productImg(goods.productPolicy)
                                          "
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="goods-info">
                                      <div class="name">
                                        <router-link
                                          :to="{
                                            path: '/productDetail',
                                            query: {
                                              productId:
                                                goods.productPolicy
                                                  .giftProductId,
                                            },
                                          }"
                                          :title="goods.productName"
                                          target="_blank"
                                        >
                                          {{
                                            goods.productPolicy.giftProductName
                                          }}</router-link
                                        >
                                      </div>
                                      <div class="format">
                                        {{
                                          goods.productPolicy.giftProductFormat
                                        }}
                                      </div>
                                      <div class="manufacture">
                                        {{
                                          goods.productPolicy
                                            .giftProductManufacturer
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="price-content">
                                  <div class="price">
                                    <span
                                      >￥{{
                                        goods.productPolicy.sendNeedPrice
                                      }}</span
                                    >
                                  </div>
                                  <!-- 商品标签说明 -->
                                  <div class="tags highlight">
                                    <span>赠品</span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="amount-content">
                                  <span>
                                    {{ goods.productPolicy.sendNumber }}
                                  </span>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="goods-sum">
                                  ￥
                                  {{
                                    goods.productPolicy.sendNeedMoney
                                      ? goods.productPolicy.sendNeedMoney
                                      : "--/--"
                                  }}
                                </div>
                              </el-col>
                              <el-col :span="2">
                                <div class="operate-list"></div>
                              </el-col>
                            </el-row>

                            <el-row
                              class="goods-gift"
                              v-if="
                                goods.policySendFlag == '1' &&
                                goods.productPolicy &&
                                goods.productPolicy.giftType == '2'
                              "
                            >
                              <el-col :span="10">
                                <div class="goods-data">
                                  <div class="goods-main-info">
                                    <div class="goods-img">
                                      <a
                                        href="JavaScript:;"
                                        :title="goods.productPolicy.giftName"
                                      >
                                        <img
                                          v-lazy="
                                            productImg(goods.productPolicy)
                                          "
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="goods-info">
                                      <div class="name">
                                        <a
                                          :title="goods.productName"
                                          href="javascript:;"
                                        >
                                          {{ goods.productPolicy.giftName }}</a
                                        >
                                      </div>
                                      <div class="format">
                                        {{ goods.productPolicy.giftFormat }}
                                      </div>
                                      <div class="manufacture">
                                        {{
                                          goods.productPolicy.giftManufacturer
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="price-content">
                                  <div class="price">
                                    <span
                                      >￥{{
                                        goods.productPolicy.sendNeedPrice
                                      }}</span
                                    >
                                  </div>
                                  <!-- 商品标签说明 -->
                                  <div class="tags highlight">
                                    <span>赠品</span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="amount-content">
                                  <span>
                                    {{ goods.productPolicy.sendNumber }}
                                  </span>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="goods-sum">
                                  ￥
                                  {{
                                    goods.productPolicy.sendNeedMoney
                                      ? goods.productPolicy.sendNeedMoney
                                      : "--/--"
                                  }}
                                </div>
                              </el-col>
                              <el-col :span="2">
                                <div class="operate-list"></div>
                              </el-col>
                            </el-row>
                            <!-- end 赠品数据 -->
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="shop-footer">
                      <div class="middle-inner">
                        <p>
                          已选商品共
                          <i>{{ item.choiceId.length }}</i> 种，商品总金额：￥{{
                            item.money
                          }}，<span v-if="item.notLogisticsMoneyGap > 0"
                            >运费：<i>￥{{ item.logisticsMoney }}</i
                            >，</span
                          ><span class="sum-money"
                            >总计：￥{{ item.payMoney.toFixed(2) }}</span
                          >
                        </p>
                        <!-- 先判断是否满足起配金额，不满足提示起配，满足起配不满足包邮提示包邮 -->
                        <p
                          v-if="
                            item.logisticsOrderMoneyGap &&
                            item.logisticsOrderMoneyGap > 0
                          "
                          class="store-freight-tip"
                        >
                          {{ $configOptions.appName }}-{{
                            item.companyName
                          }}
                          满￥{{ item.logisticsOrderMoney.toFixed(2) }}起配，
                          <i
                            >还差￥{{
                              item.logisticsOrderMoneyGap.toFixed(2)
                            }}</i
                          >
                          <span
                            @click="continueShop(item.companyId)"
                            class="continue-shop"
                            >去凑单 <i class="el-icon-arrow-right"></i
                          ></span>
                        </p>
                        <p
                          v-else-if="
                            item.notLogisticsMoneyGap &&
                            item.notLogisticsMoneyGap > 0
                          "
                          class="store-freight-tip"
                        >
                          {{ $configOptions.appName }}-{{
                            item.companyName
                          }}
                          满￥{{ item.notLogisticsMoney.toFixed(2) }}免运费
                          <i
                            >还差￥{{
                              item.notLogisticsMoneyGap.toFixed(2)
                            }}，当前运费￥{{
                              item.logisticsMoney.toFixed(2)
                            }}</i
                          >
                          <span
                            @click="continueShop(item.companyId)"
                            class="continue-shop"
                            >去凑单 <i class="el-icon-arrow-right"></i
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END 购物车列表分仓显示 -->
              </div>
            </el-checkbox-group>
            <!-- 失效商品列表 -->
            <div
              v-if="errorCompanyShoppingCartList.length > 0"
              class="error-shopping-list"
            >
              <div class="error-header">
                失效商品（{{ errorShoppingCartList.length }}）
              </div>

              <!-- 购物车列表分仓显示 -->
              <div
                v-for="(item, index) of errorCompanyShoppingCartList"
                :key="index"
                class="store"
              >
                <div>
                  <div class="store-header">
                    <el-row>
                      <el-col :span="10">
                        <div class="store-name">
                          <el-checkbox class="checked-box" ::disabled="true">
                          </el-checkbox>
                          <span
                            >{{ $configOptions.appName }}-{{
                              item.companyName
                            }}</span
                          >
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div v-html="'&nbsp;'"></div>
                      </el-col>
                      <el-col :span="6">
                        <div v-html="'&nbsp;'" class="toggle-show"></div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="cart-list-main">
                    <div class="shop">
                      <div class="goods-list">
                        <ul
                          class="list-ul"
                          v-show="toggleStockIds.indexOf(item.companyId) > -1"
                        >
                          <li
                            v-for="(
                              goods, goodsIndex
                            ) of item.errorShoppingCartList"
                            :key="goodsIndex"
                            class="goods-content"
                            style="background-color: #f7f7f7"
                          >
                            <el-row>
                              <el-col :span="10">
                                <div class="goods-data">
                                  <span class="error-tip">失效</span>
                                  <div class="goods-main-info">
                                    <div class="goods-img">
                                      <img v-lazy="productImg(goods)" alt="" />
                                      <div class="error-mask">失效</div>
                                    </div>
                                    <div class="goods-info">
                                      <div class="name">
                                        {{ goods.productName }}
                                      </div>
                                      <div class="format">
                                        {{ goods.productFormat }}
                                      </div>
                                      <div class="manufacture">
                                        {{ goods.productManufacturer }}
                                      </div>
                                      <div
                                        v-if="
                                          goods.endDate && goods.endDate !== ''
                                        "
                                        class="end-date"
                                      >
                                        <i>有效期</i
                                        >{{ cleanEndDate(goods.endDate) }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="price-content">
                                  <div class="price">
                                    <span>￥{{ goods.price }}</span> /
                                    {{ goods.productUnit }}
                                  </div>
                                  <div
                                    v-if="
                                      goods.discountPrice &&
                                      goods.discountPrice !== ''
                                    "
                                    class="discount-price"
                                  >
                                    <span
                                      >折后约:￥{{ goods.discountPrice }}</span
                                    >
                                  </div>
                                  <!-- 商品标签说明 -->
                                  <div v-if="false" class="tags">
                                    <span>禁退</span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="amount-content">
                                  <div class="input-box">
                                    <span
                                      @click="lessAmount(goods)"
                                      class="el-icon-minus"
                                    ></span>
                                    <input
                                      @change="changeAmount(goods, $event)"
                                      onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                                      :value="goods.amount ? goods.amount : 1"
                                      type="text"
                                    />
                                    <span
                                      @click="addAmount(goods)"
                                      class="el-icon-plus"
                                    ></span>
                                  </div>
                                  <div class="tips">
                                    <span
                                      >中包装：{{
                                        goods.productMediumPkgSize
                                      }}/{{ goods.productUnit }}
                                    </span>
                                    <span
                                      class="split-tips"
                                      :class="{
                                        disabled: goods.productStep > 1,
                                      }"
                                    >
                                      {{
                                        goods.productStep > 1
                                          ? "不可拆零"
                                          : "可拆零"
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="goods-sum">
                                  ￥
                                  {{
                                    goods.productMoney
                                      ? goods.productMoney
                                      : "--/--"
                                  }}
                                </div>
                              </el-col>
                              <el-col :span="2">
                                <div class="operate-list">
                                  <a
                                    @click="removeProduct(goods)"
                                    class="delete"
                                    href="javascript:;"
                                    >删除</a
                                  >
                                  <a
                                    @click="customerCollect(goods, 1)"
                                    class="collect"
                                    href="javascript:;"
                                    >添加收藏</a
                                  >
                                </div>
                              </el-col>
                            </el-row>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END 购物车列表分仓显示 -->
            </div>
            <!-- END 失效商品列表 -->
          </div>
        </div>
      </div>
      <div v-else-if="shopLoading === 2" class="dataNone container_box">
        <router-link to="/productList">
          <img src="../assets/images/common/shopNone.png" alt="" />
        </router-link>
      </div>

      <!-- 购物车底部操作栏 -->
      <div v-if="shopLoading === 1" class="cart-bottom-opt">
        <div v-if="logisticsOrderMoneyGapList.length > 0" class="valid-tips">
          <i class="el-icon-question"></i>
          <div class="tips-delivery flex">
            <div class="text-cut" style="max-width: 200px">
              {{ $configOptions.appName }}-{{
                logisticsOrderMoneyGapList.toString()
              }}
            </div>
            <div @click="continueShop('')" style="margin-left: 5px">
              未满足起配金额,无法结算,去凑单
            </div>
          </div>
          <i class="el-icon-d-arrow-right"></i>
        </div>
        <div class="container_box opt-content">
          <div class="summary-left">
            <div class="opt-left">
              <label class="checked-all">
                <el-checkbox
                  class="checked-box"
                  :indeterminate="isIndeterminate"
                  v-model="shopCartCheckAll"
                  @change="handleCheckAllChange"
                >
                </el-checkbox>
                <span>全选</span>
              </label>
              <a
                v-if="presaleFlag"
                @click="checkPresaleCart(1)"
                href="javascript:;"
                >勾选预售产品</a
              >
              <a
                v-if="presaleFlag"
                @click="checkPresaleCart(0)"
                href="javascript:;"
                >勾选非预售产品</a
              >
              <a @click="removeBatchCart(1)" href="javascript:;"
                >删除选中的商品</a
              >
              <a @click="removeBatchCart(2)" href="javascript:;"
                >删除失效产品</a
              >
              <div
                v-if="feeIsOnline === 1 && feeIsOffline === 1"
                class="pay-type-list"
              >
                <el-radio
                  v-if="feeIsOnline === 1"
                  @change="changePayType"
                  v-model="feeType"
                  :label="2"
                  >在线支付
                </el-radio>
                <el-radio
                  v-if="feeIsOffline === 1"
                  @change="changePayType"
                  v-model="feeType"
                  :label="1"
                  >线下支付
                </el-radio>
              </div>
              <div v-if="canNotLogisticsTicketFlag" class="payTypeBox">
                <el-radio v-model="canNotLogisticsTicketFlag" :label="1"
                  >免邮券
                </el-radio>
              </div>
            </div>
            <div class="operated-data">
              <div class="data-left">
                <div class="shopData">
                  <span
                    >已选 <i>{{ orderBreedAmount }}</i> 商品
                  </span>
                </div>
                <div v-if="false" class="checked-freight-tips">
                  <label>
                    <el-checkbox class="checked-box"> </el-checkbox>
                    <span>运费提示</span>
                  </label>
                </div>
              </div>
              <div class="line"></div>
              <div class="data-right">
                <span class="order-money"
                  >总金额（含运费）：<i class="icon">￥</i
                  ><i class="num">{{ shopCartAllMoneyAll }}</i></span
                >
                <div class="other-money">
                  <el-tooltip
                    v-if="discountMoney > 0"
                    popper-class="discount-tooltip"
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                      <div class="discount-content" style="width: 300px">
                        <div class="discount-detail-head">优惠明细</div>
                        <div class="discount-detail-body">
                          <div class="summary-save-freight">
                            <div class="list-item bold">
                              <div style="font-size: 16px">商品总额：</div>
                              <div style="font-size: 16px">
                                ¥{{ originalMoney }}
                              </div>
                            </div>
                            <div class="list-item">
                              <div>优惠金额：</div>
                              <div class="highlight">¥{{ discountMoney }}</div>
                            </div>
                            <div class="list-item">
                              <div>优惠券名称：</div>
                              <div>{{ actTicketData.name }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="discount-money">
                      已优惠：<i>￥{{ discountMoney }}</i>
                      <span class="icon">
                        <i class="el-icon-arrow-up"></i>
                      </span>
                    </span>
                  </el-tooltip>
                  <el-tooltip
                    popper-class="discount-tooltip"
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                      <div class="discount-content" style="width: 300px">
                        <div class="discount-detail-head">运费说明</div>
                        <div class="discount-detail-body">
                          <div class="summary-save-freight">
                            <div class="light">实际运费以结算页为准</div>
                            <div
                              v-for="(item, index) of companyShoppingCartList"
                              :key="index"
                              class="list-item"
                            >
                              <div>
                                {{ $configOptions.appNme }}-{{
                                  item.companyName
                                }}
                              </div>
                              <div>¥{{ item.logisticsMoney.toFixed(2) }}</div>
                            </div>
                            <div class="list-item bold">
                              <div>合计</div>
                              <div>¥{{ logisticsMoney }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="discount-money"
                      >运费说明：<i>￥{{ logisticsMoney }}</i>
                      <span class="icon">
                        <i class="el-icon-arrow-up"></i>
                      </span>
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="summary-btn">
            <div
              v-if="
                (saleOrderBigPkgNumberAstrictOk == '1' &&
                  !createOrderStatus &&
                  logisticsOrderMoneyGap <= 0) ||
                (saleOrderBigPkgNumberAstrictFlag == '1' &&
                  saleOrderBigPkgNumberAstrictOk == '1' &&
                  !createOrderStatus &&
                  logisticsOrderMoneyGap <= 0) ||
                (createOrderMoneyStrategyOk == '1' &&
                  !createOrderStatus &&
                  logisticsOrderMoneyGap <= 0)
              "
              @click="confirmCreateOrder"
              class="create-btn"
            >
              创建订单
            </div>
            <div
              v-else-if="
                (saleOrderBigPkgNumberAstrictFlag == '1' &&
                  saleOrderBigPkgNumberAstrictOk == '0' &&
                  !createOrderStatus) ||
                (createOrderMoneyStrategyOk == '0' && !createOrderStatus) ||
                logisticsOrderMoneyGap > 0
              "
              :disabled="true"
              class="create-btn disabled"
            >
              无法创建
            </div>
            <div
              class="create-btn"
              :disabled="true"
              v-else
              v-loading="createOrderStatus"
            >
              正在创建
            </div>
          </div>
        </div>
      </div>
      <!-- END 购物车底部操作栏 -->
      <el-dialog
        title="提示信息"
        :visible.sync="dialogVisible"
        width="25%"
        height="15vh"
      >
        <span style="font-size: 15px">{{
          deleteType === 1 ? "确定批量删除购物车?" : "确定批量移除失效列表?"
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="hadleOk" @click="deleteShopCart">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="EXCEL导入商品"
      :visible.sync="uploadDialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div class="upload-content">
        <p class="upload-title">
          请先下载<a
            href="javascript:;"
            @click="
              createDownloadFile(
                '/resources/template/导入购物车模板.xls',
                '导入购物车模版'
              )
            "
            >EXCEL模版</a
          >，并按照模版本内要求完成填写。
        </p>
        <div class="upload-btn">
          <el-upload
            ref="upload"
            class="upload-demo"
            :limit="1"
            drag
            :on-success="uploadSuccess"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :headers="{ 'Shopping-Access-Token': accessToken }"
            accept=".csv,.xls,.xlsm,.xlsx"
            action="/web/shopping/cart/importExcel"
            multiple
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将商品列表文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传csv,xls,xlsm,xlsx文件
            </div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deleteAction, getAction, postAction, putAction } from "../api/manage";
import { accAdd, getUserInfo } from "../assets/js/common/common";
import { mapGetters } from "vuex";
import HeaderTop from "../components/common/HeaderTop.vue";
import OrderProcess from "../components/order/OrderProcess.vue";
import RightNav from "../components/common/RightNav.vue";
export default {
  components: { HeaderTop, OrderProcess, RightNav },
  name: "ShoppingCart",
  data() {
    return {
      uploadDialogVisible: false,
      editAddStatus: false,
      selecedAddId: 0,
      addressShowState: false,
      ticketShowState: false,
      dialogVisible: false,
      deleteType: 0,
      shopLoading: 0,
      shopCartCheckAll: true,
      checkedCart: [], // 勾选的购物车复选框数组
      presaleFlag: false,
      presaleCheckedCart: [], // 预售产品购物车id
      nonPresaleCheckedCart: [], // 非预售产品购物车id
      shopCartCheckIds: [],
      containStockShopCartCheckIds: [], // 包含仓库编码的购物车复选框数组
      isIndeterminate: false,
      canShoppingCartList: [],
      noActivityShoppingCart: [],
      activityShoppingCart: [],
      errorShoppingCartList: [],
      canUseDiscountTicketList: [],
      customerAddressList: [],
      actAddressListData: [],
      addressListOpen: false,
      feeType: 0,
      feeIsOnline: 1,
      feeIsOffline: 1,
      showTicketListStatus: false,
      createOrderStatus: false,
      actTicketData: {},
      selectTicketData: {},
      logisticsMoney: 0,
      notLogisticsMoney: 0,
      logisticsMoneyInfo: "", // 运费提示信息
      notLogisticsMoneyGap: 0, // 距离免配金额还剩多少
      logisticsOrderMoneyGap: 0, // 距离配送还剩余多少
      productNotCanUseDiscountTicketMoney: 0,
      productCanUseDiscountTicketMoney: 0,
      originalMoney: 0, // 商品总额
      discountMoney: 0, // 优惠金额
      selectTicketId: 0,
      shopCartAllMoney: 0,
      listOperatLoaing: false, // 列表加载动画 用于删除和清空数据操作
      orderStatus: null, // 订单配货定时器
      shopCardSendGiftIds: [], // 原始购物车所有的赠品id数组
      shopCardSendGiftItems: [], // 原始购物车所有的赠品数组
      sendGiftIds: [], // 赠品复选框id
      createOrderMoneyStrategyOk: "0",
      createOrderMoneyStrategyMoney: 0,
      createOrderAlertMessageFlag: null,
      createOrderAlertMessage: "",
      saleOrderBigPkgNumberAstrictFlag: "0",
      saleOrderBigPkgNumberAstrictOk: "0",
      saleOrderBigPkgNumberAstrict: 0,
      saleOrderBigPkgNumber: 0,
      orderRemark: "", // 订单备注信息
      updatePriceFlag: false, // 业务员改价
      firstGetFlag: true,
      canNotLogisticsTicketFlag: 1,
      orderAmount: 0,
      shopCartsNumber: 0,
      companyShoppingCartList: [],
      orderBreedAmount: 0,
      companyStockIds: [], // 储存购物车所有仓库id数组
      toggleStockIds: [], // 展示折叠的库存状态数组
      isIndeterminateStock: {}, // 仓库的半选显示状态
      companyStockIdObj: {}, // 根据仓库名称将仓库下的购物车id放到Object对象中
      cartStatus: 1,
      accessToken: {},
    };
  },

  created() {
    this.backTop();
    const userInfo = getUserInfo();
    if (!userInfo) {
      this.$alert("请登录后操作!", "提示信息：");
      this.$router.push("/login");
      return false;
    } else {
      this.accessToken = userInfo.token;
      console.log("userInfo+++++++++", userInfo);
      this.renderUserPayWay();
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    // 创建下载地址
    createDownloadFile(url, fileName = "文件下载") {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName + ".xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    },
    // 购物车excel上传成功
    uploadSuccess(res, file, fileList) {
      console.log("购物车列表上传成功的数据为++++++", res);
      if (res.code === 200 && res.success) {
        this.$message.success(res.message);
        this.uploadDialogVisible = false;
        this.renderUserPayWay();
      } else {
        this.$message.error(res.message);
      }
    },
    showExcelImportModal() {
      this.uploadDialogVisible = true;
      console.log("点击当前展示excel上传弹出层");
    },
    handleClose() {
      this.uploadDialogVisible = false;
    },
    selectCartStatus(status) {
      this.cartStatus = status;
    },
    // 切换显示仓库商品列表
    toggleStockGoods(companyId) {
      let toggleStockIds = this.toggleStockIds;
      let stockIdIndex = toggleStockIds.indexOf(companyId);
      if (stockIdIndex > -1) {
        toggleStockIds.splice(stockIdIndex, 1);
      } else {
        toggleStockIds.push(companyId);
      }
    },
    // 仓位的全选操作
    handleStockAllChecked(item, val) {
      console.log("当前仓位的全选状态", item, val);
      // 将仓位下的所有购物车id取出来；
      let stockIds = [];
      for (let key of item.shoppingCartList) {
        stockIds.push(key.id);
      }
      console.log("当前仓位的购物车id数组为：", stockIds);
      // 遍历当前选中的复选框数组
      let checkedCart = this.checkedCart;
      for (let id of stockIds) {
        if (val && checkedCart.indexOf(id) < 0) {
          checkedCart.push(id);
        } else if (!val && checkedCart.indexOf(id) >= 0) {
          let index = checkedCart.indexOf(id);
          checkedCart.splice(index, 1);
        }
      }
      const choiceId = checkedCart;
      this.getShoppingCartList(choiceId);
    },
    gotoDhh() {
      this.$router.push("/activityZone/orderPlacing");
    },
    classifyLabelList(item) {
      let labelList = [];
      if (item.productLabelList && item.productLabelList.length > 0) {
        let productLabelList = item.productLabelList;
        labelList = productLabelList.filter((key) => {
          return key.id === 340 || key.id === 341 || key.id === 342;
        });
      }
      return labelList;
    },
    // 设置业务员自定义价格
    changeSalePrice(item, e) {
      let reg = /^\d+$|^\d*\.\d+$/g;
      let val = item.salePrice;
      if (reg.test(val)) {
        this.changeShopCartItemAmount(item);
      } else {
        this.$message({
          message: "请填写正确的商品定价！",
          offset: 400,
          duration: 1000,
          type: "error",
        });
      }
    },
    // 添加订单备注信息
    addOrderRemark() {
      let _this = this;
      let orderRemark = this.orderRemark;
      this.$prompt("", "订单备注信息：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: orderRemark,
      })
        .then(({ value }) => {
          console.log("您填写的订单备注信息为:+++", value);
          _this.orderRemark = value;
        })
        .catch(() => {});
    },
    removeSendGift(id) {
      let sendGiftIds = this.sendGiftIds;
      let index = sendGiftIds.indexOf(id);
      if (index > -1) {
        sendGiftIds.splice(index, 1);
      } else {
        sendGiftIds.push(id);
      }
      this.sendGiftIds = sendGiftIds;
    },
    changeSendGiftStatus(id) {
      console.log("修改礼品框的选中状态++++", id);
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    accMuls(arg1, arg2) {
      if (typeof arg1 === "undefined") {
        arg1 = 0;
      }
      if (typeof arg2 === "undefined") {
        arg2 = 0;
      }
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      const numberMoney =
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m);
      return numberMoney.toFixed(2);
    },
    productImg(item) {
      if (item.productPic && item.productPic !== "") {
        let picUrlArr = item.productPic.split(",");
        const imgsArr = picUrlArr[0];
        console.log("imgsArr", imgsArr);
        const imgSrc = this.$imgPath + imgsArr;
        return imgSrc;
      } else {
        const imgSrc = "~@/assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    // 处理后台返回的日期格式
    cleanEndDate(endDate) {
      if (endDate && endDate !== "") {
        let dayNumber = endDate.substring(0, 10);
        let dateArr = dayNumber.split("-");
        return `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
      }
    },
    customerCollect(item, flag) {
      const _this = this;
      this.$util.debounce(() => {
        let url = "";
        let data = {};
        if (flag === 1) {
          data = {
            productId: item.productId,
          };
          url = "/customerCollectProduct/customerCollectProduct/add";
          postAction(url, data).then((res) => {
            if (res.code === 200 && res.success) {
              _this.$message({
                message: "已成功收藏该产品",
                offset: 400,
                duration: 1000,
                type: "success",
              });
              item.isCollect = 1;

              _this.$store.commit("COLLECT_UPLOAD_STATUS", true);
            }
          });
        } else {
          data = {
            productId: item.productId,
          };
          url =
            "/customerCollectProduct/customerCollectProduct/cancelProductCollect";
          deleteAction(url, data).then((res) => {
            if (res.code === 200 && res.success) {
              _this.$message({
                message: "已取消收藏该产品",
                offset: 400,
                duration: 1000,
                type: "info",
              });
              item.isCollect = 0;
              _this.$store.commit("COLLECT_UPLOAD_STATUS", false);
            }
          });
        }
      });
    },
    searchProductManufacturer(manufacturer) {
      this.$router.push({
        name: "productList",
        query: { searchVal: manufacturer },
      });
    },
    handleCheckAllChange(val) {
      console.log("购物车全选/全不选操作：", val);
      this.checkedCart = val ? this.shopCartCheckIds : [];
      console.log("购物车全选/全不选操作：", this.checkedCart);
      if (val == false) {
        this.sendGiftIds = [];
      } else {
        let shopCardSendGiftIds = this.shopCardSendGiftIds;
        this.sendGiftIds = shopCardSendGiftIds;
      }
      this.isIndeterminate = false;
      const choiceId = this.checkedCart;
      this.getShoppingCartList(choiceId);
    },
    // 购物车数组勾选的状态
    handleCheckedCitiesChange(checkedIds) {
      let companyStockIdObj = this.companyStockIdObj;
      for (let i in companyStockIdObj) {
        let containArr = this.getInclude(checkedIds, companyStockIdObj[i]);
        console.log("仓位复选框和购物车复选框的交集数组为", containArr);
        let stockNameIndex = checkedIds.indexOf(i);
        this.isIndeterminateStock[i] =
          containArr.length > 0 &&
          containArr.length < companyStockIdObj[i].length;
        if (
          containArr.length > 0 &&
          containArr.length < companyStockIdObj[i].length
        ) {
          if (stockNameIndex > -1) {
            checkedIds.splice(stockNameIndex, 1);
          }
        } else if (containArr.length === companyStockIdObj[i].length) {
          if (stockNameIndex < 0) {
            checkedIds.push(i);
          }
        } else {
          console.log("全不选状态");
          if (stockNameIndex > -1) {
            checkedIds.splice(stockNameIndex, 1);
          }
        }
      }
      console.log("点击子复选框的数组数据为：", checkedIds);
      this.$forceUpdate();
      const checkedCount = checkedIds.length;
      this.shopCartCheckAll =
        checkedCount === this.containStockShopCartCheckIds.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.containStockShopCartCheckIds.length;
      let companyStockIds = this.companyStockIds;
      let choiceIds = checkedIds.filter((item) => {
        // 过滤掉带有仓库编码的购物车id
        return companyStockIds.indexOf(item) < 0;
      });
      this.getShoppingCartList(choiceIds);
    },
    // 判断数组1是否包含数组2
    getInclude(arr1, arr2) {
      let temp = [];
      for (const item of arr2) {
        arr1.find((i) => i === item) ? temp.push(item) : "";
      }
      // if (arr2.length === temp.length) {
      //   return true;
      // } else {
      //   return false;
      // }
      return temp;
    },
    changePayType() {
      const choiceId = this.checkedCart;
      let companyStockIds = this.companyStockIds;
      let choiceIds = choiceId.filter((item) => {
        // 过滤掉带有仓库编码的购物车id
        return companyStockIds.indexOf(item) < 0;
      });
      this.getShoppingCartList(choiceIds);
    },
    // 获取用户基础参数配置（客户支付方式请求）
    renderUserPayWay() {
      const that = this;
      const url = "/customer/customerConfig/get";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          that.feeIsOnline = resData.feeIsOnline;
          that.feeIsOffline = resData.feeIsOffline;
          if (resData.feeIsOnline === 1 && resData.feeIsOffline === 0) {
            that.feeType = 2;
          } else if (resData.feeIsOnline === 0 && resData.feeIsOffline === 1) {
            that.feeType = 1;
          } else {
            that.feeType = resData.defaultPayType === 0 ? 1 : 2;
          }
          that.getShoppingCartList();
        }
      });
    },
    continueShop(companyId) {
      if (companyId && companyId !== "") {
        this.$router.push({
          path: "productList",
          query: { companyId: companyId },
        });
      } else {
        this.$router.push({
          path: "productList",
        });
      }
    },
    // 获取购物车商品列表数据
    // choiceId：客户勾选中的购物车商品编码，用户计算用户的可用优惠券和相关金额
    getShoppingCartList(choiceId) {
      const feeType = this.feeType;
      const data = {
        feeType: feeType,
      };
      if (choiceId) {
        data.choiceId = choiceId;
      }
      if (
        this.selectTicketData &&
        JSON.stringify(this.selectTicketData) !== "{}"
      ) {
        this.actTicketData = this.selectTicketData;
        const useDiscountTicketId = this.actTicketData.id;
        data.useDiscountTicketId = useDiscountTicketId;
      }
      const _this = this;
      const url = "/shopping/cart/getList";
      postAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          const checkedCart = this.checkedCart;
          const shopCartCheckIds = _this.shopCartCheckIds;

          if (
            // eslint-disable-next-line no-prototype-builtins
            resData.hasOwnProperty("customerAddressList") &&
            resData.customerAddressList
          ) {
            _this.renderAddressList(resData.customerAddressList);
            _this.customerAddressList = resData.customerAddressList;
          }
          // 初始化展开数据
          let companyShoppingCartList = resData.companyShoppingCartList;
          if (companyShoppingCartList && companyShoppingCartList.length > 0) {
            let toggleStockIds = [];
            let companyStockIds = [];
            let companyStockIdObj = {};
            for (let key of companyShoppingCartList) {
              toggleStockIds.push(key.companyId);
              companyStockIds.push(key.companyId);
              companyStockIdObj[key.companyId] = [];
              for (let key2 of key.shoppingCartList) {
                companyStockIdObj[key.companyId].push(key2.id);
              }
            }
            this.toggleStockIds = toggleStockIds;
            this.companyStockIds = companyStockIds;
            this.companyStockIdObj = companyStockIdObj;
          }
          if (
            typeof choiceId === "undefined" ||
            checkedCart === shopCartCheckIds
          ) {
            this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData(true);
          } else {
            this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData();
          }
        }
      });
    },
    renderShopCartData(checkStatus) {
      let resData = this.shoppingCartList;
      console.log("当前购物车列表返回的数据为123++++++", resData);
      if (resData.updatePriceFlag && resData.updatePriceFlag == "1") {
        this.updatePriceFlag = true;
      } else {
        this.updatePriceFlag = false;
      }
      this.listOperatLoaing = false;
      const _this = this;
      let shoppingCartCount = 0;
      if (
        (resData.shoppingCartList && resData.shoppingCartList.length > 0) ||
        (resData.errorShoppingCartList &&
          resData.errorShoppingCartList.length > 0)
      ) {
        _this.shopLoading = 1;
      } else {
        _this.shopLoading = 2;
        _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
        return false;
      }
      _this.companyShoppingCartList = resData.companyShoppingCartList;
      if (resData.shoppingCartList && resData.shoppingCartList.length > 0) {
        _this.canShoppingCartList = resData.shoppingCartList;
        const shopCartCheckIds = [];
        const activityShoppingCart = [];
        const noActivityShoppingCart = [];
        const shopCardSendGiftIds = [];
        const presaleCheckedCart = [];
        const nonPresaleCheckedCart = [];
        _this.shopCardSendGiftItems = [];
        for (const key of resData.shoppingCartList) {
          shopCartCheckIds.push(key.id);
          if (key.policySendFlag == "1" && key.productPolicy) {
            shopCardSendGiftIds.push(key.productPolicy.id);
            _this.shopCardSendGiftItems.push(key.productPolicy);
          }
          if (key.canTicketFlag === 1) {
            activityShoppingCart.push(key);
          } else {
            noActivityShoppingCart.push(key);
          }
          // 给预售产品和非预售产品赋值
          if (key.inventoryTypeId == "YUSHOU") {
            presaleCheckedCart.push(key.id);
            _this.presaleFlag = true;
          } else {
            nonPresaleCheckedCart.push(key.id);
          }
        }

        _this.presaleCheckedCart = presaleCheckedCart;
        _this.nonPresaleCheckedCart = nonPresaleCheckedCart;
        _this.noActivityShoppingCart = noActivityShoppingCart;
        _this.activityShoppingCart = activityShoppingCart;
        _this.$set(_this, "noActivityShoppingCart", noActivityShoppingCart);
        _this.$set(_this, "activityShoppingCart", activityShoppingCart);
        _this.shopCartCheckIds = shopCartCheckIds;
        _this.shopCardSendGiftIds = shopCardSendGiftIds;
        console.log("购物车原始的赠品id数组为++++++", shopCardSendGiftIds);
        console.log("checkStatus为++++++", checkStatus);
        if (checkStatus) {
          let presaleFlag = _this.presaleFlag;
          let firstGetFlag = _this.firstGetFlag;
          // 应后端要求2023-07-27 9:00 初次请求判断购物车是否有预售品种，如果有勾选预售产品再次请求购物车选中品种操作
          if (presaleFlag && firstGetFlag) {
            _this.checkedCart = presaleCheckedCart;
            _this.firstGetFlag = false;
            _this.getShoppingCartList(presaleCheckedCart);
          } else {
            // todo 2024-03-22添加上多仓概念后默认全选的复选框还要加上仓位ID数组
            let companyStockIds = _this.companyStockIds;
            _this.checkedCart = shopCartCheckIds.concat(companyStockIds);
            _this.containStockShopCartCheckIds =
              shopCartCheckIds.concat(companyStockIds);
          }
          // _this.checkedCart = shopCartCheckIds;
        }
        _this.sendGiftIds = shopCardSendGiftIds;
        shoppingCartCount = shopCartCheckIds.length;
      }
      _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
      if (
        resData.errorShoppingCartList &&
        resData.errorShoppingCartList.length >= 0
      ) {
        _this.errorShoppingCartList = resData.errorShoppingCartList;
      }
      _this.productCanUseDiscountTicketMoney =
        resData.productCanUseDiscountTicketMoney;
      _this.productNotCanUseDiscountTicketMoney =
        resData.productNotCanUseDiscountTicketMoney;
      if (
        resData.canUseDiscountTicketList &&
        resData.canUseDiscountTicketList.length > 0
      ) {
        _this.canUseDiscountTicketList = resData.canUseDiscountTicketList;
        if (resData.useDiscountTicket) {
          _this.actTicketData = resData.useDiscountTicket;
          _this.selectTicketData = resData.useDiscountTicket;
        } else {
          _this.actTicketData = resData.canUseDiscountTicketList[0];
          _this.selectTicketData = resData.canUseDiscountTicketList[0];
        }
      } else {
        _this.canUseDiscountTicketList = [];
        _this.actTicketData = {};
        _this.selectTicketData = {};
      }
      // 判断用户是否开启了大包装售卖
      if (
        resData.saleOrderBigPkgNumberAstrictFlag &&
        resData.saleOrderBigPkgNumberAstrictFlag == "1"
      ) {
        _this.saleOrderBigPkgNumberAstrictFlag =
          resData.saleOrderBigPkgNumberAstrictFlag;
        _this.saleOrderBigPkgNumberAstrict =
          resData.saleOrderBigPkgNumberAstrict;
        _this.saleOrderBigPkgNumber = resData.saleOrderBigPkgNumber;
        _this.saleOrderBigPkgNumberAstrictOk =
          resData.saleOrderBigPkgNumberAstrictOk;
      }
      // 是否开启了订单金额控制
      if (
        resData.createOrderMoneyStrategyOk &&
        resData.createOrderMoneyStrategyOk !== ""
      ) {
        _this.createOrderMoneyStrategyOk = resData.createOrderMoneyStrategyOk;
      }
      if (
        resData.createOrderMoneyStrategyMoney &&
        resData.createOrderMoneyStrategyMoney > 0
      ) {
        _this.createOrderMoneyStrategyMoney =
          resData.createOrderMoneyStrategyMoney;
      }
      // 判断是否存在订单创建提示设置
      if (
        resData.createOrderAlertMessageFlag &&
        resData.createOrderAlertMessageFlag !== ""
      ) {
        _this.createOrderAlertMessageFlag = resData.createOrderAlertMessageFlag;
      }
      if (
        resData.createOrderAlertMessage &&
        resData.createOrderAlertMessage !== ""
      ) {
        _this.createOrderAlertMessage = resData.createOrderAlertMessage;
      }

      _this.discountMoney = resData.discountMoney;
      _this.originalMoney = resData.originalMoney;
      _this.logisticsMoney = resData.logisticsMoney;
      _this.notLogisticsMoney = resData.notLogisticsMoney;
      _this.logisticsMoneyInfo = resData.logisticsMoneyInfo;
      _this.logisticsOrderMoneyGap = resData.logisticsOrderMoneyGap;
      _this.notLogisticsMoneyGap = resData.notLogisticsMoneyGap;
      _this.shopCartAllMoney = resData.payMoney;
      if (
        resData.canNotLogisticsTicketFlag &&
        resData.canNotLogisticsTicketFlag === "1"
      ) {
        _this.canNotLogisticsTicketFlag = parseInt(
          resData.canNotLogisticsTicketFlag
        );
      } else {
        _this.canNotLogisticsTicketFlag = 0;
      }
      if (resData.choiceId && resData.choiceId.length > 0) {
        _this.orderBreedAmount = resData.choiceId.length;
      } else {
        _this.orderBreedAmount = resData.shoppingCartList.length;
      }
      this.$forceUpdate();
    },
    renderAddressList(accountAddress) {
      const _this = this;
      const addList = accountAddress;
      let hasDefault = false;
      const actAddressListData = [];
      for (const key of addList) {
        if (key.isDefault === 1) {
          actAddressListData.push(key);
          _this.selecedAddId = key.id;
          hasDefault = true;
        }
      }
      if (!hasDefault) {
        for (const key of addList) {
          if (key.isAction && key.isAction === 1) {
            actAddressListData.push(key);
            _this.selecedAddId = key.id;
            hasDefault = true;
          }
        }
      }
      if (!hasDefault && accountAddress.length > 0) {
        actAddressListData.push(accountAddress[0]);
        _this.selecedAddId = accountAddress[0].id;
      }
      this.actAddressListData = actAddressListData;
      this.addressListOpen = false;
    },
    changeAddressListOpen(boolean) {
      if (boolean) {
        this.actAddressListData = this.customerAddressList;
        this.addressListOpen = true;
      } else {
        this.renderAddressList(this.customerAddressList);
        this.addressListOpen = false;
      }
    },
    actAddress(addId) {
      this.actAddressListData = [];
      this.selecedAddId = addId;
      let customerAddressList = this.customerAddressList;
      this.actAddressListData = [];
      for (let key of customerAddressList) {
        if (addId == key.id) {
          this.actAddressListData.push(key);
        }
      }
      this.addressShowState = false;
    },
    setCustomerDefault(item) {
      const _this = this;
      const url = "/customer/customerAddress/edit";
      const data = {
        id: item.id,
        isDefault: 1,
      };
      putAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.getCustomerAddressList();
        }
      });
    },
    getCustomerAddressList() {
      const _this = this;
      const url = "/customer/customerAddress/list";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          _this.customerAddressList = res.result;
          _this.renderAddressList(_this.customerAddressList);
        }
      });
    },
    removeProduct(item) {
      this.listOperatLoaing = true;
      const _this = this;
      const deleteUrl = "/shopping/cart/delete?getList=1";
      const feeType = this.feeType;
      const data = {
        id: item.id,
        getList: 1,
        feeType: feeType,
      };
      postAction(deleteUrl, data).then((res) => {
        this.listOperatLoaing = false;
        if (res.code === 200 && res.success) {
          const resData = res.result;
          _this.$store.commit("SHOPPING_CART_LIST", resData);
          // 删除成功后从全选数据里面移除删除的id
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (shopCartCheckIds.indexOf(item.id) > -1) {
            let index = shopCartCheckIds.indexOf(item.id);
            shopCartCheckIds.splice(index, 1);
            _this.shopCartCheckIds = shopCartCheckIds;
          }
          const choiceId = _this.checkedCart;
          if (choiceId.indexOf(item.id) > -1) {
            let index = choiceId.indexOf(item.id);
            choiceId.splice(index, 1);
            _this.choiceId = choiceId;
          }
          _this.renderShopCartData();
        }
      });
    },
    lessAmount(item) {
      let inputNum = item.amount ? parseInt(item.amount) : 1;
      console.log("inputNum+++++++", inputNum);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      const stockNum = parseInt(item.inventory);
      if (
        (inputNum > 0 && inputNum <= step && inputNum < stockNum) ||
        inputNum <= 0
      ) {
        inputNum = step;
      } else if (inputNum > step && inputNum <= stockNum) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAmount(item);
    },
    addAmount(item) {
      let inputNum = item.amount ? parseInt(item.amount) : 1;
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      const stockNum = parseInt(item.inventory);
      if (
        (inputNum > stockNum - step && inputNum < stockNum) ||
        inputNum >= stockNum
      ) {
        this.$message({
          message: "该产品最大库存为：" + stockNum,
          offset: 400,
          duration: 1000,
          type: "error",
        });
        inputNum = stockNum;
      } else if (inputNum >= 0 && inputNum <= stockNum - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAmount(item);
    },
    changeAmount(item, e) {
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let inputNum = parseInt(e.target.value);
      const inventory = parseInt(item.inventory);
      // 强制改变用户的输入数量为步进数量
      if (inputNum >= inventory) {
        if (inputNum > inventory) {
          this.$message({
            message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加至购物车！`,
            offset: 400,
            duration: 2000,
            type: "success",
          });
        }
        inputNum = inventory;
      } else if (inputNum <= 0 && inputNum >= step) {
        // 输入的小于 0并且库存大于步进数量, 按照默认步进来计算
        inputNum = step;
      } else {
        if (step !== 1) {
          if (inputNum % step > 0) {
            let mulNum = (inputNum - (inputNum % step)) / step + 1;
            inputNum = mulNum * step;
            if (inputNum > inventory && inventory > step) {
              inputNum = step * (mulNum - 1);
            } else {
              inputNum = inventory;
            }
            this.$message({
              message: `该产品不拆零销售, 修改购物车的数量为:${inputNum}`,
              offset: 400,
              duration: 2000,
              type: "success",
            });
          }
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAmount(item);
    },
    changeShopCartItemAmount(item) {
      console.log("修改购物车产品的item数据为++++", item);
      const _this = this;
      const inputNum = item.amount;
      if (inputNum > 0) {
        const addPrice = item.addPrice;
        let updatePrice = "";
        // 判断储值价是取医保中标价还是客户填写价格
        let medicalPrice = item.medicalPrice;
        if (medicalPrice && medicalPrice !== "") {
          updatePrice = item.medicalPrice ? parseFloat(item.medicalPrice) : "";
        } else {
          updatePrice = item.updatePrice ? parseFloat(item.updatePrice) : "";
        }
        const amount = inputNum;
        const id = item.id;
        const feeType = this.feeType;
        const productPriceStrategyId = item.productPriceStrategyId;
        const uid = item.uid;
        const actTicketData = this.actTicketData;
        const addShopData = {
          addPrice: addPrice,
          amount: amount,
          updatePrice: updatePrice,
          feeType: feeType,
          uid: uid,
          id: id,
        };
        if (item.salePrice && item.salePrice !== "") {
          addShopData.salePrice = item.salePrice;
        }
        if (productPriceStrategyId) {
          addShopData.productPriceStrategyId = productPriceStrategyId;
        }
        const productActivityPriceStrategyId =
          item.productActivityPriceStrategyId;
        if (productActivityPriceStrategyId) {
          addShopData.productActivityPriceStrategyId =
            productActivityPriceStrategyId;
        }
        if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
          addShopData.useDiscountTicketId = actTicketData.id;
        }
        // 用户选中的购物车产品数据
        const checkedCart = this.checkedCart;
        // 过滤仓库id
        let companyStockIds = this.companyStockIds;
        let choiceIds = checkedCart.filter((item) => {
          // 过滤掉带有仓库编码的购物车id
          return companyStockIds.indexOf(item) < 0;
        });
        if (choiceIds.length > 0) {
          addShopData.choiceId = choiceIds;
        }
        const addShopUrl = "/shopping/cart/add?getList=1";
        postAction(addShopUrl, addShopData).then((res) => {
          if (res.success && res.code === 200) {
            const resData = res.result;
            _this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData();
          } else {
            // 判断当前产品为液体 报错时修改数量为原先购物车数量
            item.amount = res.result;
            this.$alert(res.message, "提示信息", {
              type: "error",
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.$message({
          message: "添加产品的数量不能小于1",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
      }
    },
    checkPresaleCart(type) {
      if (type == 1) {
        this.checkedCart = this.presaleCheckedCart;
      } else {
        this.checkedCart = this.nonPresaleCheckedCart;
      }
      let checkedIds = this.checkedCart;
      this.getShoppingCartList(checkedIds);
    },
    removeBatchCart(flag) {
      let errorShoppingCartList = this.errorShoppingCartList;
      let checkedCart = this.checkedCart;
      if (flag == 2 && errorShoppingCartList.length === 0) {
        this.$message({
          message: "购物车暂时没有失效产品哦！",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
        return false;
      } else if (flag == 1 && checkedCart.length === 0) {
        this.$message({
          message: "请勾选需要删除的产品！",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
        return false;
      }
      this.deleteType = parseInt(flag);
      this.dialogVisible = true;
    },
    // 删除购物车操作
    deleteShopCart() {
      this.listOperatLoaing = true;
      this.dialogVisible = false;
      const _this = this;
      const deleteType = this.deleteType;
      const checkedCart = this.filterStockName(this.checkedCart);
      console.log("批量删除的购物车数据为：", checkedCart);
      const shopCartCheckIds = this.shopCartCheckIds;
      const actTicketData = this.actTicketData;
      const errorShoppingCartList = this.errorShoppingCartList;
      const feeType = this.feeType;
      const data = [];
      if (deleteType === 1) {
        for (const key of checkedCart) {
          const itemData = {
            id: parseInt(key),
            feeType: feeType,
          };
          if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
            itemData.useDiscountTicketId = actTicketData.id;
          }
          data.push(itemData);
        }
      } else if (deleteType === 2) {
        for (const key of errorShoppingCartList) {
          const itemData = {
            id: parseInt(key.id),
            feeType: feeType,
          };
          if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
            itemData.useDiscountTicketId = actTicketData.id;
          }
          data.push(itemData);
        }
      }
      const url = "/shopping/cart/deleteList?getList=1";
      postAction(url, data, true).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          if (deleteType === 1) {
            _this.$message({
              message: "已批量删除购物车!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
          } else if (deleteType === 2) {
            _this.$message({
              message: "已移除失效产品!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
          }

          for (let key of data) {
            if (checkedCart.indexOf(key.id) > -1) {
              let index = checkedCart.indexOf(key.id);
              checkedCart.splice(index, 1);
            }
            if (shopCartCheckIds.indexOf(key.id) > -1) {
              let index = shopCartCheckIds.indexOf(key.id);
              shopCartCheckIds.splice(index, 1);
            }
          }
          _this.checkedCart = checkedCart;
          _this.shopCartCheckIds = shopCartCheckIds;
          _this.$store.commit("SHOPPING_CART_LIST", resData);
          _this.renderShopCartData();
        }
      });
    },
    // 创建订单前的弹窗提示判断
    confirmCreateOrder() {
      let that = this;
      let createOrderAlertMessageFlag = this.createOrderAlertMessageFlag;
      if (createOrderAlertMessageFlag && createOrderAlertMessageFlag == "1") {
        let createOrderAlertMessage = this.createOrderAlertMessage;
        this.$confirm(createOrderAlertMessage, "温馨提示:", {
          type: "warning",
          confirmButtonText: "继续提交",
          cancelButtonText: "返回购物车",
        })
          .then(() => {
            that.createOrder();
          })
          .catch(() => {});
      } else {
        this.createOrder();
      }
    },
    // 过滤含仓库id的购物车复选框数组
    filterStockName(arr) {
      let companyStockIds = this.companyStockIds;
      let choiceIds = arr.filter((item) => {
        // 过滤掉带有仓库编码的购物车id
        return companyStockIds.indexOf(item) < 0;
      });
      return choiceIds;
    },
    // 创建订单
    createOrder() {
      const _this = this;
      const shoppingCartId = this.filterStockName(this.checkedCart);
      const actAddressListData = this.actAddressListData;
      const feeType = this.feeType;
      const sourceType = "1";
      let orderRemark = this.orderRemark;
      const shopData = {
        shoppingCartId: shoppingCartId,
        feeType: feeType,
        sourceType: parseInt(sourceType),
        remark: orderRemark,
      };
      if (actAddressListData && actAddressListData.length > 0) {
        shopData.customerAddressId = actAddressListData[0].id;
      }
      const customerDiscountTicketId = null;
      if (this.actTicketData && JSON.stringify(this.actTicketData) !== "{}") {
        if (this.actTicketData.orderActivityFlag === 1) {
          shopData.orderActivityId = this.actTicketData.orderActivityId;
        } else {
          shopData.customerDiscountTicketId =
            this.actTicketData.customerDiscountTicketId;
        }
      }
      let sendGiftIds = this.sendGiftIds;
      if (sendGiftIds.length > 0) {
        shopData.policyChoiceId = sendGiftIds;
      }
      if (shoppingCartId.length > 0) {
        this.createOrderStatus = true;
        const url = "/saleOrder/saleOrder/createSaleOrder";
        console.log("创建订单传输的数据为++++++", shopData);
        postAction(url, shopData).then((res) => {
          if (res.code === 200 && res.success) {
            console.log("创建订单返回的数据为++++++", res);
            const resData = res.result;
            _this.$store.commit("SHOPPING_CART_COUNT", 0);
            // 订单的下一节点地址跳转
            _this.orderNextFlowRouter(resData.saleOrder);
          } else {
            console.log("创建订单失败！", res.message);
            _this.createOrderStatus = false;
            _this.$alert(res.message, "提示信息:", {
              type: "error",
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.createOrderStatus = false;
      }
    },
    orderNextFlowRouter(saleOrder) {
      let _this = this;
      // 获取订单id，轮询请求订单配货状态
      // 判断订单下一节点状态
      let nextFlow = saleOrder.nextFlow;
      let saleOrderId = saleOrder.id;
      let payOrderId = saleOrder.payOrderId;
      // 判断订单审核状态
      if (nextFlow && nextFlow !== "") {
        if (nextFlow === "audit") {
          // 去审核
          this.$router.push({
            path: "/orderList",
            query: { state: 2 },
          });
        } else if (nextFlow === "allot") {
          // 获取配货状态
          _this.$router.push({
            path: "/createOrder",
            query: { saleOrderId: saleOrderId },
          });
        } else if (nextFlow === "confirm") {
          // 去确认订单
          // 创建订单成功后跳转确认订单页面
          _this.$router.push({
            name: "confirmOrder",
            query: {
              saleOrderId: saleOrderId,
            },
          });
        } else if (nextFlow === "pay") {
          // 去支付订单
          // 线上支付
          _this.$router.push({
            path: "/orderPay",
            query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
          });
        }
      } else {
        this.$router.push({
          path: "/orderList",
          query: { state: 3 },
        });
      }
    },
    allMoney(list) {
      const trueIndex = this.checkedCart;
      let allMoney = 0;
      for (const key of trueIndex) {
        let productMoney = 0;
        for (const key2 of list) {
          if (key === key2.id) {
            productMoney = key2.productMoney;
          }
        }
        allMoney = accAdd(productMoney, allMoney);
      }
      return allMoney;
    },
    changeTicketListStatus(actId) {
      const _this = this;
      const canUseDiscountTicketList = this.canUseDiscountTicketList;
      for (const key of canUseDiscountTicketList) {
        if (key.id === actId) {
          _this.selectTicketData = key;
          const checkedCart = this.checkedCart;
          _this.getShoppingCartList(checkedCart);
        }
      }
    },
    cleanTime(time) {
      const timeArr = time.split(" ");
      return timeArr[0];
    },
    // 选择使用的优惠券的数据
    selectTicket(item) {
      this.selectTicketData = item;
      this.ticketShowState = false;
      this.showTicketListStatus = false;
      let checkedCart = this.checkedCart;
      this.getShoppingCartList(checkedCart);
    },
  },
  computed: {
    ...mapGetters(["shoppingCartList"]),
    errorCompanyShoppingCartList() {
      let companyShoppingCartList = this.companyShoppingCartList;
      if (companyShoppingCartList && companyShoppingCartList.length > 0) {
        return companyShoppingCartList.filter((item) => {
          return (
            item.errorShoppingCartList && item.errorShoppingCartList.length > 0
          );
        });
      } else {
        return [];
      }
    },
    // 起配金额提示框
    logisticsOrderMoneyGapList() {
      let companyNameArr = [];
      let companyShoppingCartList = this.companyShoppingCartList;
      if (companyShoppingCartList && companyShoppingCartList.length > 0) {
        for (let key of companyShoppingCartList) {
          if (key.logisticsOrderMoneyGap && key.logisticsOrderMoneyGap > 0) {
            companyNameArr.push(key.companyName);
          }
        }
        return companyNameArr;
      } else {
        return [];
      }
    },
    // 计算勾选赠品的金额
    shopCartAllMoneyAll() {
      let shopCartAllMoney = this.shopCartAllMoney;
      let sendGiftIds = this.sendGiftIds;
      let shopCardSendGiftItems = this.shopCardSendGiftItems;
      let notCheckGiftIds = [];
      let notGiftMoney = 0;
      for (let key of shopCardSendGiftItems) {
        if (sendGiftIds.indexOf(key.id) < 0) {
          notCheckGiftIds.push(key.id);
          notGiftMoney = accAdd(notGiftMoney, key.sendNeedMoney);
        }
      }
      console.log("当前取消的礼品id为++++++", notCheckGiftIds);
      console.log("需要扣除的总金额++++++", notGiftMoney);
      console.log("shopCartAllMoney++++++", shopCartAllMoney);
      return accAdd(shopCartAllMoney, -notGiftMoney);
    },
    satisfyMoney() {
      const canShoppingCartList = this.canShoppingCartList;
      const allMoney = this.allMoney(canShoppingCartList);
      const notLogisticsMoney = this.notLogisticsMoney
        ? this.notLogisticsMoney
        : 0;
      return accAdd(notLogisticsMoney, -allMoney);
    },
  },
  unmounted() {
    clearInterval(this.orderStatus);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/shoppingCart/cart";
</style>