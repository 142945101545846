<template>
  <div class="liveBroContainer">
    <header-top></header-top>
    <div class="liveHeader">
      <div class="content container_box">
        <router-link to="/">
          <img :src="`${$configOptions.resourcePath}/config/logo/company-logo.png`" alt="logo" />
        </router-link>
        <div class="headerText">{{$configOptions.appName}}直播</div>
      </div>
    </div>
    <div class="liveMain clear container_box">
      <div class="liveNav left">
        <p @click="changeListData(0)" :class="{ hoverAct: collectFlag === 0 }">
          全部直播
        </p>
        <p @click="changeListData(1)" :class="{ hoverAct: collectFlag === 1 }">
          我的关注
        </p>
      </div>
      <div class="liveListBox right">
        <div class="searchBox clearfix">
          <el-input class="searchInputBox fl" size="mini" @change="getLiveBroList" v-model="searchVal"
            placeholder="请输入查询的直播间名称">
          </el-input>
          <el-button @click="getLiveBroList" class="searchBtn fr">搜索</el-button>
        </div>
        <ul v-if="totalCount > 0" class="listContent clear">
          <li class="liveItemLi" v-for="(item, index) of liveList" :key="'liveId' + item.id + index">
            <div @click="navtoLive(item)" class="liveItem">
              <div class="liveImg">
                <img v-if="item.imgPath && item.imgPath !== ''" class="imgPath" :src="item.imgPath" alt="直播图片" />
                <img v-else class="imgPath" src="../assets/images/liveBro/liveNone.jpg" alt="直播图片" />
                <div class="mark">
                  <img class="bofang" src="../assets/images/liveBro/bofangIcon.png" alt="播放" />
                </div>
              </div>
              <div class="liveData">
                <div class="imgbox">
                  <img class="userImg" src="../assets/images/liveBro/liveImgDemo.jpg" alt="主播头像" />
                  <div class="addCollect">
                    <img @click="guanzhu(item, true)" v-if="!item.collectFlag" title="关注该主播"
                      src="../assets/images/liveBro/addCollectIcon.png" alt="关注该主播" />
                    <img @click="guanzhu(item, false)" v-else title="取消关注" src="../assets/images/liveBro/removeIcon.png"
                      alt="取消关注" />
                  </div>
                </div>
                <p :title="item.detail" class="liveTitle">{{ item.detail }}</p>
                <p class="liveName">
                  <span :title="item.name" class="name">{{ item.name }}</span>
                  <span class="gkrs">{{ renderGkrs(item) }}</span>
                </p>
              </div>
            </div>
          </li>
        </ul>

        <div v-else-if="totalCount === 0" class="dataNone">
          <div class="dataImg datanone"></div>
        </div>
        <div class="pagination-bottom">
          <el-pagination background v-if="totalCount > 0" layout="prev, pager, next, jumper" :current-page="pageNo"
            @current-change="currentChange" :page-size="pageSize" :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteAction, getAction, postAction } from "../api/manage";
import Footer from "../components/common/Footer.vue";
import HeaderTop from "../components/common/HeaderTop.vue";
export default {
  name: "LiveBro",
  components: { HeaderTop, Footer },
  data() {
    return {
      searchVal: "",
      pageSize: 20,
      totalCount: 0,
      pageNo: 1,
      collectFlag: 0,
      liveList: [],
    };
  },
  created() {
    this.getLiveBroList();
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    navtoLive(item) {
      let id = item.id;
      let status = item.status
      if (status == 1) {
        this.$router.push({ path: "/liveBroDetail", query: { id: id } });
      } else {
        this.$alert("该直播间尚未开播，请耐心等候哦！", "提示信息", {
          type: "error",
        });
      }
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getLiveBroList();
    },
    changeListData(falg) {
      this.collectFlag = parseInt(falg);
      this.getLiveBroList();
    },
    renderGkrs(item) {
      if (item.status === 1) {
        let gkrsNum = item.lookCount;
        if (gkrsNum > 9999) {
          let gkrsNumW = gkrsNum / 10000;
          return gkrsNumW + "万";
        } else {
          return gkrsNum;
        }
      } else {
        return "0";
      }
    },

    // 关注直播间操作
    guanzhu(item, flag) {
      let _this = this;
      let liveDetailId = item.id;
      // 关注直播间操作
      if (flag) {
        let url = "/liveActivity/customerCollectLiveActivity/add";
        let data = {
          liveActivityId: liveDetailId,
        };
        postAction(url, data).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "已关注该主播！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            item.collectFlag = true;
          }
        });
      } else {
        // 取消直播间关注
        let url =
          "/liveActivity/customerCollectLiveActivity/delete?id=" + liveDetailId;
        let data = {
          id: liveDetailId,
        };
        deleteAction(url, null).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "已取消关注",
              offset: 400,
              duration: 1000,
              type: "error",
            });
            item.collectFlag = false;
          }
        });
      }
    },
    getLiveBroList() {
      let _this = this;
      let searchVal = this.searchVal;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        pageSize: pageSize,
        pageNo: pageNo,
        name: searchVal,
      };
      let collectFlag = this.collectFlag;
      if (collectFlag === 1) {
        data.collectFlag = 1;
      }
      let url = "/liveActivity/liveActivity/list";
      getAction(url, data).then((res) => {
        console.log("获取的直播间列表的数据为++++++", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.liveList = resData.records;
          _this.pageNo = resData.current;
          _this.totalCount = resData.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/liveBro/liveBro.scss"
</style>