<template>
  <router-view />
</template>
<script>
import { getAction } from "./api/manage";
import Clipboard from "clipboard";
import { mapGetters } from "vuex";
import { getUserInfo } from './assets/js/common/common';
export default {
  name: "App",
  created() {
    let _this = this;
    //在页面加载时读取cookie里的状态信息
    let storedata = _this.$ls.get("storedata");
    if (storedata) {
      // 刷新页面重新加载购物车数据
      // _this.getShoppingCartNum();
      _this.$store.replaceState(
        Object.assign({}, _this.$store.state, JSON.parse(storedata))
      );
      _this.$ls.remove("storedata");
    }
    //在页面刷新时将vuex里的信息保存到cookie里
    window.addEventListener("beforeunload", () => {
      let stateData = this.$store.state;
      this.$ls.set("storedata", JSON.stringify(stateData));
    });
    // 兼容iphone手机
    window.addEventListener("pagehide", () => {
      this.$ls.set("storedata", JSON.stringify(this.$store.state));
    });
    // 处理打开新窗口问题
    window.addEventListener("blur", () => {
      let stateData = this.$store.state;
      this.$ls.set("storedata", JSON.stringify(stateData));
    });

    let windowWidth = document.body.clientWidth;
    if (windowWidth <= 750) {
      location.href = this.$configOptions.h5Url;
    }
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    // 弹窗客服JS
    injectCustomJS() {
      const script = document.createElement("script");
      var kefuId = this.companyConfigData.customerServiceQq
        ? this.companyConfigData.customerServiceQq + ""
        : "kefu";

      let userInfo = getUserInfo();
      var customerName = ""
      if(userInfo && userInfo !== '') {
        console.log("获取当前客户的数据为", userInfo)
        customerName = userInfo.name
      }
      script.type = "text/javascript";
      script.innerHTML = `(function(a, b, c, d) {
        let h = b.getElementsByTagName('head')[0];let s = b.createElement('script');
        s.type = 'text/javascript';s.src = c+"/static/js/kefu-front.js";s.onload = s.onreadystatechange = function () {
            if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") d(c);
        };h.appendChild(s);
    })(window, document,"https://kefu.jiuqu817.com",function(u){
        KEFU.init({
            KEFU_URL:u,
            KEFU_KEFU_ID: '${kefuId}',
            KEFU_CUSTOMER_NAME: '${customerName}'
        })
    });`;
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    // 复制地址
    onCopyText(className) {
      var clipboard = new Clipboard(`.${className}`);
      clipboard.on("success", () => {
        clipboard.destroy();
        this.$message.success("复制成功");
      });
      clipboard.on("error", () => {
        this.$message.error("该浏览器不支持自动复制");
        clipboard.destroy();
      });
    },
    windowResize() {
      window.addEventListener("resize", () => {
        let windowWidth = document.body.clientWidth;
        if (windowWidth <= 750) {
          location.href = this.$configOptions.h5Url;
        }
      });
    },
    // 获取所在的公司配置
    async getCompanyConfigData() {
      let url = "company/companyConfig/queryById";
      let res = await getAction(url);
      let productShowType = "1";
      console.log("获取的公司配置信息为++++++", res);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        // 产品列表默认展示方式
        let localProductShowType = this.$ls.get("productShowType");
        if (!localProductShowType || localProductShowType == "") {
          if (resData.defaultProductListShowType) {
            productShowType = resData.defaultProductListShowType;
          } else {
            productShowType = "2";
          }
          // 设置state参数状态
          this.$store.commit("PRODUCT_SHOW_TYPE", productShowType);
        }

        // 系统维护功能开启
        this.showMaintainAct(resData);
        this.$store.commit("COMPANY_CONFIG_DATA", resData);
      }
    },
    // 系统维护判断信息
    showMaintainAct(companyConfigData) {
      // 系统维护功能开启
      if (
        companyConfigData.maintainFlag &&
        companyConfigData.maintainFlag == "1"
      ) {
        // 判断系统维护功能提示类型
        let maintainType = companyConfigData.maintainType;
        if (maintainType == "1") {
          this.$alert(companyConfigData.maintainTips, "系统维护提示", {
            type: "error",
          });
        } else if (maintainType == "2") {
          this.$router.push("/maintain");
        }
      }
    },
    // 获取购物车列表数量
    getShoppingCartNum() {
      let _this = this;
      let url = "shopping/cart/getListCount";
      getAction(url).then((res) => {
        if (res.code == 200 && res.success) {
          let shopCartNum = res.result.shoppingCartListcount;
          _this.$store.commit("SHOPPING_CART_COUNT", shopCartNum);
        }
      });
    },
  },
  mounted() {
    let _self = this;
    this.injectCustomJS();
    window.addEventListener("resize", () => {
      _self.$util.debounce(() => {
        let windowWidth = document.body.clientWidth;
        if (windowWidth <= 750) {
          location.href = this.$configOptions.h5Url;
        }
      }, 300);
    });
    let routerPath = this.$route.path;
    console.log("当前的路由地址为++++++", routerPath);
    if (routerPath === "/index") {
      // 首页重新请求公司配置
      this.getCompanyConfigData();
    } else {
      // 检查公司配置信息是否出现系统维护页面
      let companyConfigData = this.$ls.get("companyConfigData");
      if (companyConfigData && companyConfigData !== "") {
        this.showMaintainAct(companyConfigData);
      }
    }
  },
};
</script>
<style lang="scss">
body {
  font-family: "Microsoft YaHei", sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::v-deep .el-select-dropdown__item {
  text-align: center !important;
}

::v-deep .bd_weixin_popup {
  width: 250px !important;
}

::v-deep .el-tooltip__popper {
  max-width: 400px !important;
}
</style>
