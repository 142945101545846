var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"goodsImgBox"}},[_c('div',{staticClass:"container_box"},[_c('ul',{staticClass:"goods-tag"},_vm._l((_vm.productList),function(item,index){return _c('li',{key:'imgProduct' + index,class:{
          'offline-sale-item':
            item.offlineSaleFlag && item.offlineSaleFlag == '1',
        }},[_c('div',[_c('div',{staticClass:"productImgBox"},[_c('router-link',{attrs:{"to":{
                path: '/productDetail',
                query: { productId: item.productId },
              },"title":item.name,"target":"_blank"}},[(_vm.specialAreaMaskImg && _vm.specialAreaMaskImg !== '')?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.specialAreaMaskImg,"alt":"产品遮罩图"}}):(
                  item.productLabelList &&
                  item.productLabelList.length > 0 &&
                  _vm.prolabelMaskImg(item.productLabelList) !== ''
                )?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.prolabelMaskImg(item.productLabelList),"alt":"产品遮罩图"}}):_vm._e(),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.productImg(item)),expression:"productImg(item)"}],staticClass:"productImg"})])],1)]),_c('div',{staticClass:"img-mingzi"},[_vm._v(" "+_vm._s(item.name)+" ")]),(!item.inventoryList || item.inventoryList.length === 0)?_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.loginState ? "￥--/--" : "会员可见")+" ")])]):(
            item.offlineSaleFlag &&
            item.offlineSaleFlag == '1' &&
            item.productNotputSaleShowPrice !== '1'
          )?_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl",staticStyle:{"font-size":"17px"}},[_vm._v("请联系开票员")])]):(
            item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
          )?_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl"},[_vm._v(" ￥"+_vm._s(item.productActivityPriceStrategyDetail.price)+" ")]),_c('p',{staticClass:"yjPriceBox fl"},[_vm._v(" ￥"+_vm._s(item.productActivityPriceStrategyDetail.originalPrice)+" ")])]):(
            item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ''
          )?_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl"},[_vm._v(" ￥"+_vm._s(item.inventoryList[0].discountPrice)+" ")]),_c('p',{staticClass:"yjPriceBox fl"},[_vm._v(" ￥"+_vm._s(item.inventoryList[0].price)+" ")])]):(
            item.inventoryList[0].originalPrice &&
            item.inventoryList[0].originalPrice !== ''
          )?_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl"},[_vm._v("￥"+_vm._s(item.inventoryList[0].price))]),_c('p',{staticClass:"yjPriceBox fl"},[_vm._v(" ￥"+_vm._s(item.inventoryList[0].originalPrice)+" ")])]):_c('div',{staticClass:"proPriceContent clearfix"},[_c('p',{staticClass:"priceBox fl"},[_vm._v("￥"+_vm._s(item.inventoryList[0].price))])]),(
            _vm.loginState && item.inventoryList && item.inventoryList.length > 0
          )?_c('div',{staticClass:"tipBox"},[(
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该产品为秒杀产品","placement":"top"}},[_c('span',{staticClass:"tip1"},[_vm._v("秒杀")])]):_vm._e(),(
              item.inventoryList[0].newCustomerPriceFlag &&
              item.inventoryList[0].newCustomerPriceFlag == '1'
            )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"新用户特价产品","placement":"top"}},[_c('span',{staticClass:"tip3"},[_vm._v("特价")])]):_vm._e(),(item.inventoryList[0].deliveryTime)?_c('span',{staticClass:"tip1",style:(item.inventoryList[0].deliveryTime === '24'
                ? 'background-color:#009944'
                : 'background-color:#18a97b')},[_vm._v(_vm._s(item.inventoryList[0].deliveryTime))]):_vm._e(),(
              item.inventoryList[0].priceTitle &&
              item.inventoryList[0].priceTitle !== ''
            )?_c('span',{staticClass:"tip2"},[_vm._v(_vm._s(item.inventoryList[0].priceTitle))]):_vm._e(),(
              item.policyFlag &&
              item.productPolicyList &&
              item.productPolicyList.length > 0
            )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.productPolicyList[0].remark,"placement":"top"}},[_c('span',{staticClass:"tip3"},[_vm._v("促销")])]):_vm._e(),(item.inventoryList[0].nearDateFlag)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该产品为近效期产品","placement":"top"}},[_c('span',{staticClass:"tip4"},[_vm._v("近效期")])]):_vm._e(),(
              (item.productActivityPriceStrategyFlag !== 1 ||
                item.productActivityPriceStrategyDetail.canSaleAmount ===
                  0) &&
              item.minDiscountTicket &&
              item.minDiscountTicket.name !== ''
            )?_c('span',{staticClass:"tip5"},[_vm._v(" "+_vm._s(item.minDiscountTicket.name)+" ")]):_vm._e()],1):_c('div',{staticClass:"tipBox"},[(!_vm.loginState)?_c('span',{staticClass:"tip1",staticStyle:{"background-color":"#009944"}}):_vm._e()]),_c('div',{staticClass:"img-compamy",staticStyle:{"cursor":"pointer"},attrs:{"title":item.manufacturer},on:{"click":function($event){return _vm.searchFatory(item.manufacturer)}}},[_vm._v(" "+_vm._s(item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== "" ? item.manufacturer : "暂无厂家信息！")+" ")]),_c('div',{staticClass:"img-guige"},[_vm._v(" 规格："),_c('span',{attrs:{"title":item.format}},[_vm._v(_vm._s(item.format))])]),(_vm.loginState)?_c('div',{staticClass:"img-guige"},[_vm._v(" 有效期: "+_vm._s(item.inventoryList && item.inventoryList.length > 0 && item.inventoryList[0].endDate && item.inventoryList[0].endDate !== "" ? item.inventoryList[0].endDate : "暂无效期")+" ")]):_c('div',{staticClass:"img-guige"},[_vm._v(" 有效期: "),_c('span',{staticStyle:{"color":"#fd2c17"}},[_vm._v("会员可见")])]),_c('div',{staticClass:"img-xiaoqi"},[(item.inventoryList && item.inventoryList.length > 0)?_c('span',{staticClass:"kucun"},[_vm._v(" 库存："+_vm._s(_vm.showInventoryText(item.inventoryNum, item))+" ")]):(_vm.loginState)?_c('span',{staticClass:"kucun"},[_vm._v("库存：暂无库存")]):_c('span',{staticClass:"kucun"},[_vm._v("库存："),_c('i',{staticStyle:{"font-style":"normal","color":"#fd2c17"}},[_vm._v("会员可见")])]),_c('span',{staticClass:"baozhuang",attrs:{"title":((item.mediumPkgSize) + "/" + (item.bigPkgSize))}},[_vm._v("中/大包装："+_vm._s(item.mediumPkgSize)+"/"+_vm._s(item.bigPkgSize))])]),(!_vm.loginState)?_c('div',{staticClass:"cz_content"},[_vm._m(0,true),_c('div',{staticClass:"jionBtnBox",on:{"click":_vm.gotoLogin}},[_vm._v("立即抢购")])]):(_vm.defaultInventory(item.inventoryList).length > 0)?_c('div',{staticClass:"cz_content"},[(
              item.inventoryList &&
              item.inventoryList.length > 0 &&
              item.productNotputSaleCreateOrder !== '0'
            )?_c('div',{staticClass:"amoutBox"},[_c('span',{on:{"click":function($event){return _vm.lessNum(item.inventoryList[0], item)}}},[_vm._v("-")]),_c('input',{class:{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              },attrs:{"oninput":"value=value.replace(/[^0-9.]/g,'')","type":"text"},domProps:{"value":item.inventoryList[0].addCartQuantity},on:{"focus":function($event){return _vm.selectShopAmount($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.jionShopCart(item.inventoryList[0], item, $event)},"change":function($event){return _vm.changeNum(item.inventoryList[0], item, $event)}}}),_c('span',{on:{"click":function($event){return _vm.addNum(item.inventoryList[0], item)}}},[_vm._v("+")])]):_vm._e(),_c('div',{staticClass:"jionBtnBox",style:(item.offlineSaleFlag &&
              item.offlineSaleFlag == '1' &&
              item.productNotputSaleCreateOrder == '0'
                ? 'width: 100%'
                : ''),on:{"click":function($event){return _vm.jionShopCart(item.inventoryList[0], item, $event)}}},[_c('img',{attrs:{"src":require("@/assets/images/product/img/jionShop.png")}}),_vm._v(" "+_vm._s(item.offlineSaleFlag && item.offlineSaleFlag == "1" ? item.productNotputSaleCreateOrder == "1" ? "开票员订货" : "请联系开票员订货" : "加入购物车")+" ")])]):_vm._e()])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caozuoBox"},[_c('p',{staticClass:"zwkcText",staticStyle:{"color":"#fd2c17"}},[_vm._v("会员可见")])])}]

export { render, staticRenderFns }