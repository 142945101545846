import { getAction,postAction } from '@/api/manage'
import Vue from 'vue'
import router from '@/router/index'

const common = {
  state: {
    productCategoryList: [],
    navMenuList: [],
    advertTopData: {},
    unreadMsgNumAll: {
      sumCount: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
    companyCredentialsList: [],
    companyNoticeList: [],
    companyConfigData: {},
    productShowType: '1',
  },
  mutations: {
    COMPANY_CREDENTIALS_LIST: (state, companyCredentialsList) => {
      state.companyCredentialsList = companyCredentialsList
    },
    COMPANY_NOTICE_LIST: (state, companyNoticeList) => {
      state.companyNoticeList = companyNoticeList
    },
    // 产品分类数据
    PRODUCT_CATEGORY_LIST: (state, list) => {
      state.productCategoryList = list
    },
    // 导航栏菜单数据
    NAV_MENU_LIST: (state, list) => {
      state.navMenuList = list
    },
    ADVERT_TOP_DATA: (state, res) => {
      state.advertTopData = res
    },
    UNREAD_MSG_NUM_ALL: (state, res) => {
      state.unreadMsgNumAll = res
    },
    PRODUCT_SHOW_TYPE: (state, type) => {
      state.productShowType = type;
      Vue.ls.set('productShowType',type)
    },
    COMPANY_CONFIG_DATA_LOGIN: (state, data) => {
      state.companyConfigData = data;
    },
    COMPANY_CONFIG_DATA: (state, data) => {
      state.companyConfigData = data;
      Vue.ls.set('companyConfigData', data)
    },
  },

  actions: {
    ClearCompanyConfigData({commit}, state) {
      commit('COMPANY_CONFIG_DATA', {})
    }
  }
}

export default common
