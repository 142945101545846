<template>
  <el-dialog title="上传头像" :visible.sync="dialogTableVisible">
    <el-row :gutter="24" class="cropper-content">
      <el-col :xs="24" :sm="24" :md="24" :lg="15">
        <div class="cropper-box">
          <div class="cropper">
            <vue-cropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.outputSize"
              :outputType="option.outputType"
              :info="option.info"
              :canScale="option.canScale"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :full="option.full"
              :fixedBox="option.fixedBox"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :centerBox="option.centerBox"
              :height="option.height"
              :infoTrue="option.infoTrue"
              :maxImgSize="option.maxImgSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              @realTime="realTime"
              @imgLoad="imgLoad"
            >
            </vue-cropper>
          </div>
          <!--底部操作工具按钮-->
          <div class="footer-btn">
            <div class="scope-btn">
              <label class="btn" for="uploads">
                <i class="el-icon-plus"></i>
                选择头像
              </label>
              <input
                type="file"
                id="uploads"
                style="position: absolute; clip: rect(0 0 0 0)"
                accept="image/png, image/jpeg, image/gif, image/jpg"
                @change="selectImg($event)"
              />
              <el-button
                icon="el-icon-refresh-left"
                size="mini"
                type="danger"
                plain
                @click="rotateLeft"
              >
                左旋转</el-button
              >
              <el-button
                icon="el-icon-refresh-right"
                size="mini"
                type="danger"
                plain
                @click="rotateRight"
              >
                右旋转</el-button
              >
            </div>
          </div>
        </div>
      </el-col>
      <!--预览效果图-->
      <el-col :xs="24" :sm="24" :md="24" :lg="9">
        <div class="show-preview">
          <div :style="previews.div"  class="preview">
            <img id="previewImg" :src="previews.url" :style="previews.img" />
          </div>

          <div v-if="previews.url" class="upload-btn">
            <el-button
              size="mini"
              icon="el-icon-upload"
              type="success"
              @click="uploadImg('blob')"
              >上传头像</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { httpAction } from '../../api/manage';
export default {
  name: "EditUserImg",
  components: {
    VueCropper,
  },
  props: ["Name"],
  data() {
    return {
      name: this.Name,
      previews: {},
      dialogTableVisible: false,
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 200, //默认生成截图框宽度
        autoCropHeight: 200, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "200px 200px", //图片默认渲染方式
      },
    };
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      //转化为base64
      reader.readAsDataURL(file);
    },
    //上传图片
    async uploadImg(type) {
      let _this = this;
      if (type === "blob") {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          console.log("data++++++++++",data)
          formData.append("file", data, "DX.jpg");
          console.log("formData+++++++++",formData)
          //调用axios上传
          let url = "/customer/customer/updateCustomerImg"
          var res = await httpAction(url,formData,"POST")
          if (res.code === 200) {
            _this.dialogTableVisible = false
            _this.$message({
                message: "图像上传成功！",
                offset: 400,
                duration: 1000,
                type: "success",
              });
            let imgInfo = res.result
            _this.$emit("uploadImgSuccess", imgInfo);
          } else {
            _this.$message({
              message: "文件服务异常，请联系管理员！",
              type: "error",
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/myAccount/editUserImg";
</style>