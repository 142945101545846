<template>
  <!--中奖提示-->
  <div v-if="maskShowType" id="mask">
    <div class="blin"></div>
    <div class="caidai"></div>
    <div class="winning reback">
      <div class="red-head"></div>
      <div class="red-body"></div>
      <div id="card" class="pull">
        <a href="javascript:;" target="_self" class="win">
          <p class="gift-title">{{ winningData.name }}</p>
          <img :src="winningData.iconUrl" alt="">
        </a>
      </div>
      <a @click="maskShowType = false" href="javascript:;" target="_self" class="btn"></a>
      <span @click="maskShowType = false" id="close"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinningModal",
  data() {
    return {
      maskShowType: false,
      winningData: {}
    }
  },
  methods: {
    open() {
      this.maskShowType = true
    }
  }
}
</script>

<style lang="scss" scoped>
/*中奖提示*/
#mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);

  .blin {
    width: 540px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -270px;
    margin-top: -270px;
    background-image: url("~@/assets/images/activity/redPack/gold.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    -o-animation: circle 10s linear infinite;
    -ms-animation: circle 10s linear infinite;
    -moz-animation: circle 10s linear infinite;
    -webkit-animation: circle 10s linear infinite;
    animation: circle 10s linear infinite;
  }

  @keyframes circle {
    0% {
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .caidai {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 540px;
    height: 760px;
    margin-left: -270px;
    margin-top: -380px;
    background-image: url("~@/assets/images/activity/redPack/dianzhui.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .winning {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 430px;
    height: 365px;
    margin: -142px 0 0 -215px;
    -o-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }

  .reback {
    -o-animation: reback .5s linear forwards;
    -ms-animation: reback .5s linear forwards;
    -moz-animation: reback .5s linear forwards;
    -webkit-animation: reback .5s linear forwards;
    animation: reback .5s linear forwards;
  }

  @keyframes reback {
    100% {
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.winning {
  .red-head {
    position: relative;
    top: -75px;
    width: 100%;
    height: 242px;
    background-image: url("~@/assets/images/activity/redPack/top.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .red-body {
    position: relative;
    top: -160px;
    z-index: 2;
    width: 100%;
    height: 261px;
    background-image: url("~@/assets/images/activity/redPack/bottom.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  #card {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: -170px;
    margin-top: -80px;
    width: 80%;
    height: 230px;
    background-image: url("~@/assets/images/activity/redPack/middle.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-transition: top .5s;
    -ms-transition: top .5s;
    -moz-transition: top .5s;
    -webkit-transition: top .5s;
    transition: top .5s;

    .win {
      display: block;
      margin: 10px auto;
      width: 92%;
      height: 290px;
      text-align: center;
      p {
        color: #fd5255;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 1px;
        text-indent: 1px;
        @include ellipsis;
        margin: 10px auto;
      }
      img {
        max-width: 200px;
        max-height: 200px;
        width: auto;
        height: auto;
      }
    }
  }

}

.winning {
  .pull {
    -o-animation: card .5s linear forwards;
    -ms-animation: card .5s linear forwards;
    -moz-animation: card .5s linear forwards;
    -webkit-animation: card .5s linear forwards;
    animation: card .5s linear forwards;
  }

  @keyframes card {
    100% {
      margin-top: -200px;
    }
  }

  .btn {
    position: absolute;
    left: 50%;
    bottom: 18%;
    z-index: 9;
    width: 260px;
    height: 50px;
    margin-left: -130px;
    background-image: url("~@/assets/images/activity/redPack/button.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-animation: shake .5s 2 linear alternate;
    -ms-animation: shake .5s 2 linear alternate;
    -moz-animation: shake .5s 2 linear alternate;
    -webkit-animation: shake .5s 2 linear alternate;
    animation: shake .5s 2 linear alternate;
  }

  @keyframes shake {
    50% {
      -o-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      -moz-transform: rotate(-5deg);
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }

    100% {
      -o-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
  }

  #close {
    opacity: 0;
    position: absolute;
    right: 0px;
    top: -80px;
    z-index: 10;
    width: 45px;
    height: 45px;
    background-image: url("~@/assets/images/activity/redPack/close.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-animation: fadein .5s .5s linear forwards;
    -ms-animation: fadein .5s .5s linear forwards;
    -moz-animation: fadein .5s .5s linear forwards;
    -webkit-animation: fadein .5s .5s linear forwards;
    animation: fadein .5s .5s linear forwards;
  }

  @keyframes fadein {
    100% {
      opacity: 1;
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>