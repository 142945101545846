<template>
  <div class="page-center">
    <div class="center-content container_box">
      <order-left></order-left>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import OrderLeft from '../components/order/OrderLeft.vue'
export default {
  name: 'Center',
  components: { OrderLeft },
  data() {
    return {
      selectIndex: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.page-center {
  padding-top: 10px;
  padding-bottom: 50px;
  background-color: #f8f8f8;
}
.center-content {
  @include clear;
}

@import "../assets/styles/order/left";
</style>