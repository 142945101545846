<template>
  <div class="giftDetailBox">
    <header-top></header-top>
    <div class="nav content">
      <div class="content_box">
        <div class="logo_box" style="width: 250px; text-align: left">
          <router-link to="/">
            <img style="width: 226px; height: 60px; vertical-align: middle" v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
            :src="companyConfigData.pcDarkLogoUrl" />
            <img v-else style="width: 226px; height: 60px; vertical-align: middle" :src="`${$configOptions.resourcePath}/config/logo/company-logo.png`" />
          </router-link>
        </div>
        <ul class="nav_list">
          <li class="navLi">
            <router-link :to="{ name: 'integralCenter', query: { navType: 0 } }" class="navLia">积分首页</router-link>
          </li>
          <li class="navLi">
            <router-link :to="{ name: 'integralCenter', query: { navType: 1 } }" class="navLia">礼品列表</router-link>
          </li>
          <li class="navLi">
            <router-link :to="{ name: 'integralCenter', query: { navType: 3 } }" class="navLia">个人中心</router-link>
          </li>
          <li class="navLi">
            <router-link class="navLia" to="/">返回首页</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="false" style="width: 100%; text-align: center">
      <img src="@/assets/images/product/loading.gif" alt="" />
    </div>
    <div v-else id="xiangqing" class="container">
      <div class="container_box clearfix">
        <div id="good-route-nav">
          <div class="dataLeft">
            <router-link to="/" class="dataWeizhi">首页</router-link>
            <b class="icon-arrow-right"> &gt; </b>
            <router-link to="/integralCenter" class="dataWeizhi">积分商城</router-link>
            <b class="icon-arrow-right"> &gt; </b>
            <a href="javascript:;" class="dataWeizhi">{{
              giftDetail.name
            }}</a>
          </div>
        </div>
        <div class="xq_main">
          <div class="xq_main_top clearfix">
            <div class="DetilLeft">
              <div class="xq_datu">
                <div @mousemove="spanMove($event)" @mouseleave="spanOut()" class="jqzoom">
                  <img ref="jqzoomImg" v-if="activeImg && activeImg !== ''" :src="activeImg" alt="商品图片" />
                  <img ref="jqzoomImg" v-else src="../assets/images/common/zwtp.png" alt="商品图片" />
                  <span :style="`top: ${spanMoveStyle.top}px;left: ${spanMoveStyle.left}px;`" v-show="spanMoveShow"
                    ref="moveSpan" class="moveSpan"></span>
                </div>
              </div>
              <div class="product_img_list">
                <el-carousel :arrow="sliceArrs(
                  giftImgList(giftDetail),
                  5
                ).length > 1 ? 'hover' : 'never'" class="xiaotuCar" :autoplay="false" trigger="click">
                  <el-carousel-item v-for="(item, index) of sliceArrs(
                    giftImgList(giftDetail),
                    5
                  )" :key="'xsqgItem' + index">
                    <ul class="xiaotu_list clearfix">
                      <li @mousemove="proImgclick(itemc, indexc)" :class="activeImgIndex == indexc ? 'active' : ''"
                        v-for="(itemc, indexc) of item" :key="indexc">
                        <img :src="`${$imgPath}${itemc}`" alt="" />
                      </li>
                    </ul>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="bigPic" ref="bigPic" v-show="bigDivShow">
              <img :style="`top: ${picMoveStyle.top}px;left: ${picMoveStyle.left}px;`" ref="bigPicImg"
                v-if="bigImg && bigImg !== ''" :src="bigImg" alt="商品图片" />
              <img :style="`top: ${picMoveStyle.top}px;left: ${picMoveStyle.left}px;`" ref="bigPicImg" v-else
                src="../assets/images/common/zwtp.png" alt="商品图片" />
            </div>
            <div class="mid">
              <div class="money">
                <p class="name">
                  {{ giftDetail.name }}
                  <span class="spgg">{{ giftDetail.format }}</span>
                </p>
                <!-- 价格区域判断-->
                <div class="productList" style="margin-bottom: 30px">
                  <p>
                    <span class="sj">所需积分：<i>{{ giftDetail.integral }}</i></span>
                    <span class="yuanjia">市场参考价：<i>￥{{ giftDetail.referencePrice }}</i></span>
                  </p>
                </div>
              </div>
              <div class="text textBox" style="border-top: 1px dashed #dfdfdf;border-bottom: none;">
                <p>
                  <span class="justy">限兑数量：{{ giftDetail.limitAmount }}</span>
                </p>
                <p>
                  <span class="justy">已兑数量：{{ giftDetail.exchangeAmount }}</span>
                </p>
                <p>
                  <span class="justy">库存数量：{{ giftDetail.remainAmount }}</span>
                </p>
              </div>
              <div class="inventoryNumBox">
                <div class="inputBox">
                  数量:
                  <div class="inputNumber fr">
                    <span @click="less(giftDetail)" class="mins"> &minus; </span>
                    <input :value="giftDetail.jionAmount" onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                      @change="changeAmount(giftDetail, $event)" @keyup.enter="exchangeGift(giftDetail)" type="text"
                      class="shopNumber" />
                    <span @click="add(giftDetail)" class="plus"> &plus; </span>
                  </div>
                </div>
              </div>
              <div class="caozuoBox">
                <a v-if="giftDetail.remainAmount <= 0" href="javascript:;" class="jionBtn jionBtn1">
                  已售罄
                </a>
                <a v-else-if="giftDetail.limitAmount && (giftDetail.limitAmount <= giftDetail.exchangeAmount)" href="javascript:;"
                  class="jionBtn jionBtn2">
                  兑换告罄
                </a>
                <a v-else @click="exchangeGift(giftDetail)" href="javascript:;" class="jionBtn">
                  <img src="../assets/images/product/detail/cartIcon.png" alt="" />
                  立即兑换
                </a>
              </div>
              <div class="imgTip">
                温馨提示：产品图片仅供参考，请以实物为准。
              </div>
            </div>
          </div>
        </div>
        <div class="detailTagBox">
          <div class="detailContent">
            <el-tabs v-if="giftDetail.detail" type="border-card">
              <el-tab-pane label="礼品详情">
                <div v-if="giftDetail.detail
                " class="productBookBox clearfix">
                  <div v-if="giftDetail.detail" class="productBookContent fl" v-html="giftDetail.detail"></div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction } from "@/api/manage";
import { accMuls, getUserInfo } from "../assets/js/common/common";
import HeaderTop from "../components/common/HeaderTop.vue";
export default {
  name: "giftDetail",
  components: {
    HeaderTop
  },
  data() {
    return {
      giftId: "",
      giftDetail: {},
      bigImg: "",
      activeImg: "",
      activeImgIndex: 0,
      bigDivShow: false, // 大图展示状态
      spanMoveShow: false, // 移动模块展示状态
      picMoveStyle: {
        top: 0,
        left: 0
      },
      spanMoveStyle: {
        top: 0,
        left: 0
      },
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
      this.companyId = userInfo.companyId;
    } else {
      this.loginState = false;
    }
    this.backTop();
    const giftId = this.$route.query.id;
    this.giftId = giftId;
    this.getGiftDetail();
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    exchangeGift(item) {
      let _this = this;
      let integralGiftId = item.id; // 礼品的id
      let jionAmount = item.jionAmount; // 用户兑换的数量
      let integral = item.integral; // 当前礼品所对应的积分
      let limitAmount = item.limitAmount; // 当前限兑数量
      let amount = item.amount; // 当前总数量
      let userIntegral = this.userIntegral; // 用户总积分
      // 进行数量判断
      if (jionAmount > 0) {
        this.$confirm(
          `当前兑换的数量为${jionAmount},是否继续？`,
          "提示信息：",
          { type: "info" }
        )
          .then((res) => {
            // 前端进行用户积分兑换的验证
            let allJF = accMuls(jionAmount, integral);
            if (parseInt(userIntegral) < parseInt(allJF)) {
              _this.$alert("兑换礼品所用积分不足!", "提示信息:", {
                type: "error",
              });
            } else {
              if (limitAmount && limitAmount !== "") {
                if (limitAmount < jionAmount) {
                  jionAmount = limitAmount;
                }
              } else {
                if (jionAmount > amount) {
                  jionAmount = amount;
                }
              }
              let url = "/integralMall/integralGift/exchangeIntegralGift";
              let addData = {
                integralGiftId: integralGiftId,
                amount: jionAmount,
              };
              console.log("兑换传给后台的数据为++++++", addData);
              getAction(url, addData).then((res) => {
                console.log("兑换数量的数据为++++++", res);
                if (res.code === 200 && res.success) {
                  _this.$alert(res.message, "提示信息:", { type: "success" });
                  item.jionAmount = 0;
                  _this.getIntegralGiftList();
                  _this.$emit("getUserIntegral");
                } else {
                  _this.$alert(res.message, "提示信息:", { type: "error" });
                  item.jionAmount = 0;
                }
              });
            }
          })
          .catch((error) => { });
      } else {
        this.$alert("请输入正确的兑换数量！", "提示信息:", { type: "warning" });
      }
    },
    // 数据添加
    add(item) {
      let beforeAmount = 0;
      let amount = item.limitAmount; // 活动礼品的数量
      let jionAmount = item.jionAmount; // 用户的添加数量
      let remainAmount = item.remainAmount;
      if (remainAmount && remainAmount < amount) {
        amount = remainAmount
      }
      if (jionAmount) {
        beforeAmount = jionAmount;
      }
      if (beforeAmount < amount) {
        jionAmount = beforeAmount + 1;
      }
      this.$set(item, "jionAmount", jionAmount);
      item.jionAmount = jionAmount;
    },
    less(item) {
      let beforeAmount = 0;
      let amount = parseInt(item.limitAmount); // 活动礼品的数量
      let jionAmount = item.jionAmount; // 用户的添加数量
      let remainAmount = item.remainAmount;
      if (remainAmount && remainAmount < amount) {
        amount = remainAmount
      }
      if (jionAmount) {
        beforeAmount = jionAmount;
      }
      if (beforeAmount <= amount && beforeAmount > 0) {
        jionAmount = beforeAmount - 1;
      } else if (beforeAmount <= 0) {
        jionAmount = 0;
      }
      this.$set(item, "jionAmount", jionAmount);
      item.jionAmount = jionAmount;
    },
    changeAmount(item, e) {
      let value = parseInt(e.target.value);
      let amount = parseInt(item.limitAmount); // 活动礼品的数量
      let remainAmount = item.remainAmount;

      if (value > 0 && value <= amount) {
        value = value;
      } else if (value > amount) {
        if (remainAmount && remainAmount < amount) {
          amount = remainAmount
        }
        value = amount;
      } else if (value < 0) {
        value = 0;
      }
      this.$set(item, "jionAmount", value);
      item.jionAmount = value;
    },
    // 均分数组
    sliceArrs(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    // 光标选中全选文字
    selectShopAmount(e) {
      e.currentTarget.select();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 显示产品图片
    giftImgList(item) {
      if (item.picUrl && item.picUrl !== "") {
        let imgsArr = item.picUrl.split(",");
        let imgSrc = imgsArr;
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 修改效期数据
    renderEndDate(endDate) {
      return endDate.replace(" 00:00:00", "");
    },
    changeTabIndex(item, index) {
      this.tabIndex = index;
      this.selectedTabList = item.floorDetailList;
    },
    async getGiftDetail() {
      const _this = this;
      const giftId = this.giftId;
      const url = "integralMall/integralGift/list";
      const data = {
        id: giftId,
      };
      this.loading = true;
      let res = await getAction(url, data)
      console.log("获取当前的礼品详情为++++++", res)
      this.loading = false;
      if (res.code === 200 && res.success) {
        let resData = res.result;
        this.giftDetail = resData.giftList[0]

        if (this.giftDetail.picUrl && this.giftDetail.picUrl !== "") {
          let productPicUrl = this.giftDetail.picUrl.split(",");
          _this.activeImg = `${this.$imgPath}${productPicUrl[0]}`;
          _this.bigImg = `${this.$imgPath}${productPicUrl[0]}`;
        }
      }
    },
    spanMove(e) {
      let normalPic = this.$refs.jqzoomImg;
      let spanMove = this.$refs.moveSpan;
      let bigDiv = this.$refs.bigPic;
      let picMove = this.$refs.bigPicImg;
      this.bigDivShow = true;
      this.spanMoveShow = true;
      let bigSize = picMove.offsetHeight;
      let littleSize = normalPic.offsetHeight;
      let n = bigSize / littleSize;
      let picX = normalPic.getBoundingClientRect().left;
      let picY = normalPic.getBoundingClientRect().top;
      let mouseX = e.pageX - picX;
      let mouseY = e.pageY - picY;
      let spanW = spanMove.offsetWidth;
      let spanH = spanMove.offsetHeight;
      let normalPicW = normalPic.offsetWidth;
      let normalPicH = normalPic.offsetHeight;
      let bigW = bigDiv.offsetHeight;
      let picMoveLeft = 0;
      let picMoveTop = 0;
      let spanMoveLeft = 0;
      let spanMoveTop = 0;
      if (mouseX <= spanW / 2) {
        picMoveLeft = 0;
        spanMoveLeft = 0;
      } else if (mouseX > spanW / 2 && mouseX < normalPicW - spanW / 2) {
        let tempX = mouseX - spanW / 2;
        picMoveLeft = -n * tempX;
        spanMoveLeft = tempX;
      } else {
        picMoveLeft = -n * (normalPicW - spanW);
        spanMoveLeft = normalPicW - spanW;
      }
      if (mouseY <= spanH / 2) {
        picMoveTop = 0;
        spanMoveTop = 0;
      } else if (mouseY > spanH / 2 && mouseY < normalPicH - spanH / 2) {
        let tempY = mouseY - spanH / 2;
        picMoveTop = -n * tempY;
        spanMoveTop = tempY;
      } else {
        picMoveTop = -(n * normalPicH - bigW);
        spanMoveTop = normalPicH - spanH;
      }
      this.picMoveStyle = {
        top: picMoveTop,
        left: picMoveLeft
      };
      this.spanMoveStyle = {
        top: spanMoveTop,
        left: spanMoveLeft
      }
    },
    spanOut() {
      this.bigDivShow = false;
      this.spanMoveShow = false;
    },
    prevLunBo() {
      const hasClick = this.hasClick;
      const tabIndex = this.tabIndex;
      if (hasClick || typeof hasClick === "undefined") {
        this.hasClick = false;
        const nowLeft = parseInt($("#productListUl" + tabIndex).css("left"));
        let index = -nowLeft / 1200;
        index--;
        this.moveUl(index);
      }
    },
    nextLunBo() {
      const hasClick = this.hasClick;
      const tabIndex = this.tabIndex;
      if (hasClick || typeof hasClick === "undefined") {
        this.hasClick = false;
        const nowLeft = parseInt($("#productListUl" + tabIndex).css("left"));
        let index = -nowLeft / 1200;
        index++;
        this.moveUl(index);
      }
    },
    moveUl(ind) {
      const that = this;
      const tabIndex = this.tabIndex;
      let index = 0;
      const thisUl = $("#productListUl" + tabIndex);
      const liLength = $("#productListUl" + tabIndex + " li").length;
      const oldliLength = liLength - 2;
      thisUl.animate(
        {
          left: -(ind * 1200) + "px",
        },
        1000,
        function (e) {
          if (ind === 0) {
            index = oldliLength;
            thisUl.css("left", -(index * 1200) + "px");
          } else if (ind === oldliLength + 1) {
            index = 1;
            thisUl.css("left", -(index * 1200) + "px");
          }
          that.hasClick = true;
        }
      );
    },
    // 选择礼品图片
    proImgclick(item, index) {
      this.bigImg = `${this.$imgPath}${item}`;
      this.activeImg = `${this.$imgPath}${item}`;
      this.activeImgIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/intergralCenter/intergralShop";
@import "../assets/styles/product/productDetail";

.textBox {
  padding: 0 !important;

  p {
    margin-top: 20px !important;
  }
}

.detailContent {
  width: 100% !important;
}

.jionBtn1 {
  border: 1px solid #909399 !important;
  background: #ccc !important;
}
.jionBtn2 {
  border: 1px solid #FF1919 !important;
  background: #FFFFFF !important;
  color: #666 !important;
}
::v-deep .nav {
  border-bottom: 2px solid $base-color;
  box-shadow: none;
}</style>
