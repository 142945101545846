<template>
  <p>
    <span>{{ lastTime | format }}</span>
    <span>{{ lastTime | format2 }}</span>
    <span>{{ lastTime | format3 }}</span>
  </p>
</template>

<script>

function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export default {
  name: "CountDown",
  props: {
    format: {
      type: Function,
      default: undefined
    },
    format2: {
      type: Function,
      default: undefined
    },
    format3: {
      type: Function,
      default: undefined
    },
    target: {
      type: [Date, Number],
      required: true,
    },
    nowTarget: {
      type: [Date, Number],
      required: false,
    },
    onEnd: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      dateTime: '0',
      originTargetTime: 0,
      lastTime: 0,
      timer: 0,
      interval: 1000
    }
  },
  filters: {
    format(time) {
      const hours = 60 * 60 * 1000;
      const h = Math.floor(time / hours);
      return `${fixedZero(h)}`
    },
    format2(time) {
      const hours = 60 * 60 * 1000;
      const minutes = 60 * 1000;

      const h = Math.floor(time / hours);
      const m = Math.floor((time - h * hours) / minutes);
      return `${fixedZero(m)}`
    },
    format3(time) {
      const hours = 60 * 60 * 1000;
      const minutes = 60 * 1000;
      const h = Math.floor(time / hours);
      const m = Math.floor((time - h * hours) / minutes);
      const s = Math.floor((time - h * hours - m * minutes) / 1000);
      return `${fixedZero(s)}`
    }
  },
  created() {
    this.initTime()
    this.tick()
  },
  methods: {
    initTime() {
      let lastTime = 0;
      let targetTime = 0;
      this.originTargetTime = this.target
      try {
        if (Object.prototype.toString.call(this.target) === '[object Date]') {
          targetTime = this.target
        } else {
          targetTime = new Date(this.target).getTime()
        }
      } catch (e) {
        throw new Error('invalid target prop')
      }

      lastTime = targetTime - new Date().getTime();

      this.lastTime = lastTime < 0 ? 0 : lastTime
    },
    tick() {
      const { onEnd } = this

      this.timer = setTimeout(() => {
        if (this.lastTime < this.interval) {
          clearTimeout(this.timer)
          this.lastTime = 0
          if (typeof onEnd === 'function') {
            onEnd();
          }
        } else {
          this.lastTime -= this.interval
          this.tick()
        }
      }, this.interval)
    }
  },
  beforeUpdate() {
    if (this.originTargetTime !== this.target) {
      this.initTime()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
p {
  display: inline-block;
  height: 27px;
  line-height: 27px;

  span {
    display: inline-block;
    align-items: center;
    width: 40px;
    height: 27px;
    background-color: #ffffff;
    border-radius: 5px;
    color: #fb3803;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    margin: 0 20px 0 0;
    letter-spacing: 0px;
    text-indent: 10px;
    position: relative;
    &:last-child {
      &::after {
        display: none;
      }
    }
    &::after {
      position: absolute;
      right: -13px;
      color: #ffffff;
      display: inline-block;
      content: ":";
      clear: both;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }
  }
}
</style>