<template>
  <div
    class="areaContainer"
    :style="
      brandDetail &&
      brandDetail.backgroundColor &&
      brandDetail.backgroundColor !== ''
        ? `background-color:${brandDetail.backgroundColor}`
        : ''
    "
  >
    <!-- banner区域 -->
    <div
      v-if="brandDetail && brandDetail.picUrl && brandDetail.picUrl !== ''"
      class="areaBanner container"
    >
      <img :src="brandDetail.picUrl" alt="brandDetail.name" />
    </div>
    <!-- end banner区域 -->
    <!-- 促销活动专区 -->
    <div class="activityBox container_box">
      <!-- 厂家促销 -->
      <div
        v-loading="promotion.loading"
        id="cjmzNav"
        v-if="promotion.list.length > 0"
        class="cjmzContent activityItem"
      >
        <ul class="goods-list-content">
          <li
            v-for="(item, index) of promotion.list"
            v-bind:key="'imgProduct' + index"
            :class="{
              'offline-sale-item':
                item.offlineSaleFlag && item.offlineSaleFlag == '1',
            }"
          >
            <div class="good-data-content">
              <div class="good-img-content">
                <router-link
                  :to="{
                    path: '/productDetail',
                    query: { productId: item.productId },
                  }"
                  :title="item.name"
                  target="_blank"
                >
                  <img class="productImg" v-lazy="productImg(item)" />
                </router-link>
              </div>
              
            <img
              v-if="
                item.policyFlag &&
                item.productPolicyList &&
                item.productPolicyList.length > 0
              "
              class="act-img-icon"
              src="~@/assets/images/product/act-cx.png"
              alt=""
            />
            <img
              v-else-if="specailFlagStatus(item)"
              class="act-img-icon"
              src="~@/assets/images/product/act-tj.png"
              alt=""
            />
              <price-show-text
                :salesPrice="showSalePrice(item)"
                :originalPrice="showOriginalPrice(item)"
                :specailFlag="specailFlagStatus(item)"
              ></price-show-text>
              <div class="goods-name-content">
                <span
                  class="goods-tag-list"
                  v-if="
                    loginState &&
                    item.inventoryList &&
                    item.inventoryList.length > 0
                  "
                >
                  <span
                    class="stock-tag"
                    v-if="item.companyName && item.companyName !== ''"
                    :class="item.companyName === '河北仓' ? 'tip3' : 'tip2'"
                    >{{ item.companyName }}</span
                  >
                  <span
                    v-if="item.medicalType && item.medicalType === '1'"
                    class="yb-tag"
                    >医保</span
                  >
                  <span
                    v-if="item.inventoryList[0].deliveryTime"
                    class="tip2"
                    >{{ item.inventoryList[0].deliveryTime }}</span
                  >
                </span>
                <span class="goods-name"
                  >{{ item.name }} {{ item.format }}</span
                >
              </div>
              <div
                style="cursor: pointer"
                @click="searchFatory(item.manufacturer)"
                class="factory"
                :title="item.manufacturer"
              >
                {{
                  item.manufacturer &&
                  item.manufacturer.replace(/\s*/g, "") !== ""
                    ? item.manufacturer
                    : "暂无厂家信息！"
                }}
              </div>
              <div v-if="loginState" class="validity">
                有效期:
                {{
                  item.inventoryList &&
                  item.inventoryList.length > 0 &&
                  item.inventoryList[0].endDate &&
                  item.inventoryList[0].endDate !== ""
                    ? item.inventoryList[0].endDate
                    : "暂无效期"
                }}
              </div>
              <div v-else class="validity">
                有效期: <span style="color: #fd2c17">会员可见</span>
              </div>
              <!-- 优惠券信息 -->
              <div class="good-discount">
                <p
                  class="goods-tag-list"
                  v-if="
                    loginState &&
                    item.inventoryList &&
                    item.inventoryList.length > 0
                  "
                >
                  <span
                    class="tip1"
                    v-if="
                      (item.productActivityPriceStrategyFlag !== 1 ||
                        item.productActivityPriceStrategyDetail
                          .canSaleAmount === 0) &&
                      item.minDiscountTicket &&
                      item.minDiscountTicket.name !== ''
                    "
                  >
                    {{ item.minDiscountTicket.name }}
                  </span>
                </p>
              </div>
              <!-- 其他标签 -->
              <div class="good-tags">
                <div
                  class="goods-tag-list"
                  v-if="
                    loginState &&
                    item.inventoryList &&
                    item.inventoryList.length > 0
                  "
                >
                  <span
                    class="tip2"
                    v-if="
                      item.inventoryList[0].priceTitle &&
                      item.inventoryList[0].priceTitle !== ''
                    "
                    >{{ item.inventoryList[0].priceTitle }}</span
                  >

                  <el-tooltip
                    v-if="
                      item.policyFlag &&
                      item.productPolicyList &&
                      item.productPolicyList.length > 0
                    "
                    class="item"
                    effect="dark"
                    :content="item.productPolicyList[0].remark"
                    placement="top"
                  >
                    <span class="tip1">促销</span>
                  </el-tooltip>
                  <el-tooltip
                    v-if="item.inventoryList[0].nearDateFlag"
                    class="item"
                    effect="dark"
                    content="该产品为近效期产品"
                    placement="top"
                  >
                    <span class="tip4">近效期</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <!-- <div v-if="false" class="inventory"><span :title="`${item.mediumPkgSize}/${item.bigPkgSize}`" class="baozhuang">中/大包装：{{
            item.mediumPkgSize
          }}/{{ item.bigPkgSize }}</span>
          </div> -->
            <transition name="el-zoom-in-bottom">
              <div
                class="goods-bottom-act"
                v-show="showCartActionIndex !== item.id"
              >
                <p class="inventory">
                  <span
                    v-if="item.inventoryList && item.inventoryList.length > 0"
                    class="kucun"
                  >
                    库存：{{ showInventoryText(item.inventoryNum, item) }}
                  </span>
                  <span v-else-if="loginState" class="kucun"
                    >库存：暂无库存</span
                  >
                  <span v-else class="kucun"
                    >库存：<i style="font-style: normal; color: #fd2c17"
                      >会员可见</i
                    ></span
                  >
                </p>
                <span @click="showCartActionIndex = item.id" class="cart-icon">
                  <img src="~@/assets/images/common/cart-white.png" alt="" />
                </span>
              </div>
            </transition>
            <!-- 添加购物车操作栏 -->
            <transition name="el-zoom-in-bottom">
              <div
                class="cart-input-box transition-box"
                v-show="showCartActionIndex === item.id"
              >
                <div class="input-content">
                  <i
                    @click="lessNum(item.inventoryList[0], item)"
                    class="el-icon-minus"
                  ></i>
                  <input
                    :class="{
                      shoppingHas:
                        item.inventoryList[0].shoppingCartAmount &&
                        item.inventoryList[0].shoppingCartAmount > 0,
                    }"
                    :value="item.inventoryList[0].addCartQuantity"
                    @focus="selectShopAmount($event)"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    @keyup.enter="
                      jionShopCart(item.inventoryList[0], item, $event)
                    "
                    @change="changeNum(item.inventoryList[0], item, $event)"
                    type="text"
                  />
                  <i
                    @click="addNum(item.inventoryList[0], item)"
                    class="el-icon-plus"
                  ></i>
                </div>
                <div
                  @click="jionShopCart(item.inventoryList[0], item, $event)"
                  :style="
                    item.offlineSaleFlag &&
                    item.offlineSaleFlag == '1' &&
                    item.productNotputSaleCreateOrder == '0'
                      ? 'width: 100%'
                      : ''
                  "
                  class="cart-btn"
                >
                  {{
                    item.offlineSaleFlag && item.offlineSaleFlag == "1"
                      ? item.productNotputSaleCreateOrder == "1"
                        ? "开票员订货"
                        : "联系开票员"
                      : "加入购物车"
                  }}
                </div>
              </div>
            </transition>
            <!-- <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'"
              class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox"
              :style="(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0') ? 'width: 100%' : ''">
              <img src="~@/assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? item.productNotputSaleCreateOrder == '1' ?
                '开票员订货' : '请联系开票员订货' : '加入购物车' }}
            </div>
          </div> -->
          </li>
        </ul>
        <div class="pagination-bottom">
          <el-pagination
            background
            v-if="promotion.totalCount > 0"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :current-page="promotion.pageNo"
            @current-change="currentChange2"
            :page-size="promotion.pageSize"
            :total="promotion.totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { getAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";
import PriceShowText from "../components/product/PriceShowText.vue";
export default {
  components: { PriceShowText },
  name: "PromotionZone",
  mixins: [ProductMixin],
  data() {
    return {
      loginState: false,
      brandDetail: {},
      promotion: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      },
      areaId: "",
    };
  },
  created() {
    let areaId = this.$route.query.id;
    if (areaId && areaId !== "") {
      this.areaId = areaId;
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
    }
    this.getPromotionList();
    this.getZoneDetailData();
  },
  methods: {
    // 是否显示特价标识
    specailFlagStatus(goods) {
      let loginState = this.loginState;
      if (loginState && goods.inventoryList && goods.inventoryList.length > 0) {
        if (
          goods.productActivityPriceStrategyFlag === 1 &&
          goods.productActivityPriceStrategyDetail &&
          goods.productActivityPriceStrategyDetail.canSaleAmount
        ) {
          return true;
        } else if (
          goods.inventoryList[0].newCustomerPriceFlag &&
          goods.inventoryList[0].newCustomerPriceFlag == "1"
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    // 展示商品销售价格
    showSalePrice(goods, selectedInventory) {
      if (!goods.inventoryList || goods.inventoryList.length === 0) {
        return "--/--";
      } else if (
        goods.offlineSaleFlag &&
        goods.offlineSaleFlag == "1" &&
        goods.productNotputSaleShowPrice !== "1"
      ) {
        return "请联系开票员购买";
      } else if (
        goods.productActivityPriceStrategyFlag === 1 &&
        goods.productActivityPriceStrategyDetail &&
        goods.productActivityPriceStrategyDetail.canSaleAmount
      ) {
        return goods.productActivityPriceStrategyDetail.price;
      } else {
        if (selectedInventory) {
          if (
            selectedInventory.discountPrice &&
            selectedInventory.discountPrice !== ""
          ) {
            return selectedInventory.discountPrice;
          } else if (
            selectedInventory.originalPrice &&
            selectedInventory.originalPrice !== ""
          ) {
            return selectedInventory.price;
          } else {
            return selectedInventory.price;
          }
        } else {
          if (
            goods.inventoryList[0].discountPrice &&
            goods.inventoryList[0].discountPrice !== ""
          ) {
            return goods.inventoryList[0].discountPrice;
          } else if (
            goods.inventoryList[0].originalPrice &&
            goods.inventoryList[0].originalPrice !== ""
          ) {
            return goods.inventoryList[0].price;
          } else {
            return goods.inventoryList[0].price;
          }
        }
      }
    },
    // 展示商品原价格
    showOriginalPrice(goods, selectedInventory) {
      if (
        goods.productActivityPriceStrategyFlag === 1 &&
        goods.productActivityPriceStrategyDetail &&
        goods.productActivityPriceStrategyDetail.canSaleAmount
      ) {
        return goods.productActivityPriceStrategyDetail.originalPrice;
      } else {
        if (selectedInventory) {
          if (
            selectedInventory.discountPrice &&
            selectedInventory.discountPrice !== ""
          ) {
            return selectedInventory.discountPrice;
          } else if (
            selectedInventory.originalPrice &&
            selectedInventory.originalPrice !== ""
          ) {
            return selectedInventory.price;
          } else {
            return selectedInventory.price;
          }
        } else {
          if (
            goods.inventoryList[0].discountPrice &&
            goods.inventoryList[0].discountPrice !== ""
          ) {
            return goods.inventoryList[0].price;
          } else if (
            goods.inventoryList[0].originalPrice &&
            goods.inventoryList[0].originalPrice !== ""
          ) {
            return goods.inventoryList[0].originalPrice;
          } else {
            return "";
          }
        }
      }
    },
    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== "") {
        let imgsArr = item.picUrl.split(",");
        // let imgSrc = imgsArr[0]
        let imgSrc = this.$util.resizeImg(imgsArr[0], 200, false);
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 获取专区中心的背景色和banner图
    async getZoneDetailData() {
      let areaId = this.areaId;
      let url = "/area/brandAreavo/get";
      let data = {
        id: areaId,
      };
      let res = await getAction(url, data);
      console.log("获取当前的促销专区的配置信息++++++", res);
      if (res.code == 200 && res.success) {
        this.brandDetail = res.result;
      }
      // this.brandDetail = {
      //   appPicUrl: "/group1/M00/00/05/rBGFfGKEkR2AWAlqAALZS8iwk5Y752.jpg",
      //   backgroundColor: "#FFFFFF",
      //   id: "614110e587f7a9a5592bd88842032b6c",
      //   name: "保健品专区",
      //   picUrl: "/group1/M00/00/04/rBGFfGJxzOmAbe7XAAWb1wqEo0M223.jpg",
      //   productBackgroundPic: "",
      // }
    },
    // 获取促销列表数据
    getPromotionList() {
      let _this = this;
      let pageNo = this.promotion.pageNo;
      let pageSize = this.promotion.pageSize;
      this.promotion.loading = true;
      let data = {
        policyFlag: 1,
        pageNo: parseInt(pageNo),
        pageSize: pageSize,
      };
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        _this.promotion.loading = false;
        if (res.code === 200 && res.success) {
          console.log("获取促销列表的数据为++++", res);
          let resData = res.result;
          _this.promotion.list = resData.pageData.records;
          _this.promotion.pageNo = resData.pageData.current;
          _this.promotion.totalCount = resData.pageData.total;
        }
      });
    },
    currentChange2(val) {
      this.promotion.pageNo = val;
      this.getPromotionList();
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/activity/activity";
@import "../assets/styles/product/img/img";

.pagination-bottom {
  margin-bottom: 50px;
}
</style>