import { getAction,postAction } from '@/api/manage'
import router from '@/router/index'

const order = {
  state: {
    pendingOrderCount: 0,
    waitPaymentNum: 0,
    confirmOrderId: '',
  },
  mutations: {
    // 待确认的订单数量
    PENDING_ORDER_COUNT: (state, count) => {
      state.pendingOrderCount = count
    },
  },

  actions: {
    ClearPendingOrderCount({commit, state}){
      commit('PENDING_ORDER_COUNT', 0)
    }
  }
}

export default order
