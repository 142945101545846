<template>
  <div class="applyOrderReturn container_box">
    <div class="applyHeader">
      <div class="apply-route">
        <div @click="$router.back(-1)" class="backLeft">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <el-breadcrumb class="linkList" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/orderList' }"
            >我的订单</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="showType !== 2"
            >退货退款申请</el-breadcrumb-item
          >
          <el-breadcrumb-item v-else>售后订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-if="refundOrder.saleReturnOrder.statusId" class="operate-box">
        <el-button
        @click="lookReturnAddress(refundOrder.saleReturnOrder)"
          v-if="parseInt(refundOrder.saleReturnOrder.statusId) === 5" size="mini" icon="el-icon-map-location" type="primary"
          >查看退货地址</el-button
        >
        <el-button
        @click="addLogisticsCodeShow"
          v-if="parseInt(refundOrder.saleReturnOrder.statusId) === 5" size="mini" icon="el-icon-edit" type="warning"
          >填写物流单号</el-button
        >
        <el-button
          @click="cancelRefundOrder"
          v-if="parseInt(refundOrder.saleReturnOrder.statusId) < 6"
          size="mini"
          icon="el-icon-refresh-left"
          type="danger"
          >撤销售后操作</el-button
        >
      </div>
    </div>
    <div class="orderList">
      <table class="orderListTitle">
        <thead>
          <tr class="tableHeader">
            <th class="spmc">商品名称</th>
            <th class="gg">规格</th>
            <th class="sccj">厂家</th>
            <th class="dj">单价</th>
            <th class="gmsl">购买数量</th>
            <th class="ytsl">已退数量</th>
            <th style="padding-left: 22px" class="sqsl">
              可申请数量
              <span
                class="allReturn"
                @click="allOrderReturn"
                v-if="showType !== 2"
                >全部退货</span
              >
            </th>
          </tr>
        </thead>
        <tbody v-if="showType !== 2 && orderItemList.length > 0">
          <tr
            class="tableList"
            v-for="(item, index) of orderItemList"
            :key="'orderItem' + index"
          >
            <td class="spmc">{{ item.productName }}</td>
            <td>
              <p class="gg">{{ item.productFormat }}</p>
            </td>
            <td>
              <p class="sccj">{{ item.productManufacturer }}</p>
            </td>
            <td>
              <p class="dj">{{ item.salePrice }}</p>
            </td>
            <td>
              <p class="gmsl">{{ item.confirmAmount }}</p>
            </td>
            <td>
              <p class="ytsl">
                {{ item.returnAmount ? item.returnAmount : 0 }}
              </p>
            </td>
            <td>
              <div class="sqsl">
                <div class="amount">
                  <div class="amoutBox">
                    <span @click="lessReturnAmount($event, item)">-</span>
                    <input
                      @change="changeReturnAmount($event, item)"
                      onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                      :value="item.applyAmount"
                      type="text"
                    />
                    <span @click="addReturnAmount($event, item)">+</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            class="tableList"
            v-for="(item, index) of refundOrder.saleReturnOrderItemList"
            :key="'orderItem' + index"
          >
            <td class="spmc">{{ item.productName }}</td>
            <td>
              <p class="gg">{{ item.productFormat }}</p>
            </td>
            <td>
              <p class="sccj">{{ item.productManufacturer }}</p>
            </td>
            <td>
              <p class="dj">{{ item.salePrice }}</p>
            </td>
            <td>
              <p class="gmsl">{{ item.confirmAmount }}</p>
            </td>
            <td>
              <p class="ytsl">
                {{ item.returnAmount ? item.returnAmount : 0 }}
              </p>
            </td>
            <td>
              <p style="padding-left: 50px" class="sqsl">
                {{ item.amount ? item.amount : 0 }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="applyContent">
      <el-form ref="ruleForm" label-width="100px" class="ruleForm">
        <el-form-item label="原订单号：" prop="allMoney">
          <router-link
            style="font-size: 14px"
            :to="{
              path: 'orderDetail',
              query: { saleOrderId: refundOrder.saleReturnOrder.saleOrderId },
            }"
          >
            {{ refundOrder.saleReturnOrder.saleOrderId }}
          </router-link>
        </el-form-item>
        <el-form-item label="售后单号：" prop="allMoney">
          {{ refundOrder.saleReturnOrder.id }}
        </el-form-item>
        <el-form-item label="退款总金额：" prop="allMoney">
          {{ refundOrder.saleReturnOrder.money }}
        </el-form-item>
        <el-form-item label="货物状态：" prop="logisticsStatus">
          {{
            refundLogisticsStatus(refundOrder.saleReturnOrder.logisticsStatus)
          }}
        </el-form-item>
        <el-form-item class="applyType" label="退货原因：" prop="info">
          {{ refundInfo(refundOrder.saleReturnOrder.info) }}
        </el-form-item>
        <el-form-item class="valBox" label="退货说明：" prop="remark">
          {{ refundOrder.saleReturnOrder.remark }}
        </el-form-item>
        <el-form-item label="审核状态：" prop="allMoney">
          {{ auditStatus(refundOrder.saleReturnOrder.auditStatus) }}
        </el-form-item>
        <el-form-item v-if="refundOrder.saleReturnOrder.logisticsTypeName && refundOrder.saleReturnOrder.logisticsTypeName !== ''" label="退货物流：" prop="allMoney">
          {{ refundOrder.saleReturnOrder.logisticsTypeName }}
        </el-form-item>
        <el-form-item v-if="refundOrder.saleReturnOrder.logisticsCode && refundOrder.saleReturnOrder.logisticsCode !== ''" label="退货物流单：" prop="allMoney">
          {{ refundOrder.saleReturnOrder.logisticsCode }}
        </el-form-item>
        <el-form-item
          v-if="
            refundOrder.saleReturnOrder.auditRemark &&
            refundOrder.saleReturnOrder.auditRemark !== ''
          "
          label="审核备注："
          prop="allMoney"
        >
          {{ refundOrder.saleReturnOrder.auditRemark }}
        </el-form-item>
      </el-form>
    </div>
    <add-logistics-code :refundId="refundOrder.saleReturnOrder.id" @ok="getRefundOrderData" ref="addModal"></add-logistics-code>
    <return-address ref="returnAddModal"></return-address>
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
import AddLogisticsCode from '../components/order/AddLogisticsCode.vue';
import ReturnAddress from '../components/order/ReturnAddress.vue';
export default {
  components: { AddLogisticsCode,ReturnAddress },
  data() {
    return {
      orderItemList: [],
      ruleForm: {
        logisticsStatus: "",
        info: "",
        remark: "",
      },
      rules: {
        logisticsStatus: [
          { required: true, message: "请选择订单货物状态", trigger: "change" },
        ],
        info: [
          { required: true, message: "请选择退货原因", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请填写退货说明", trigger: "blur" },
        ],
      },
      logisticsStatusOptions: [
        {
          value: 2,
          label: "已收到货",
        },
        {
          value: 1,
          label: "未收到货",
        },
      ],
      infoOptions: [
        {
          value: 0,
          label: "商品压损/质量问题",
        },
        {
          value: 1,
          label: "批号不符",
        },
        {
          value: 2,
          label: "商家发错货",
        },
        {
          value: 3,
          label: "发货少货",
        },
        {
          value: 4,
          label: "药品近效期",
        },
        {
          value: 5,
          label: "发票相关-无票",
        },
        {
          value: 6,
          label: "发票相关-错票",
        },
        {
          value: 7,
          label: "发票相关-申请专票",
        },
        {
          value: 8,
          label: "发票相关-发票其他",
        },
        {
          value: 9,
          label: "资质相关",
        },
        {
          value: 10,
          label: "其他",
        },
      ],
      refundOrder: {},
      showType: 1,
    };
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    let refundId = this.$route.query.refundId;
    let type = this.$route.query.type;
    if (type == 2) {
      this.showType = 2;
      this.refundId = refundId;
      this.getRefundOrderData();
    } else {
      this.saleOrderId = saleOrderId;
      if (saleOrderId) {
        this.getOrderDeatil();
      }
    }
  },
  methods: {
    addLogisticsCodeShow() {
      let saleReturnOrderData = this.refundOrder.saleReturnOrder;
      this.$refs.addModal.open(saleReturnOrderData);
    },
    lookReturnAddress(saleReturnOrderData) {
      this.$refs.returnAddModal.open(saleReturnOrderData)
    },
    // 撤销售后操作
    cancelRefundOrder() {
      const that = this;
      this.$confirm("是否确认取消该笔售后单操作?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        let url = "saleOrder/saleReturnOrder/deleteById";
        let refundId = that.refundId;
        let data = {
          id: refundId,
        };
        postAction(url, data).then((res) => {
          console.log("关闭退货单返回的数据为：", res);
          if (res.code === 200 && res.success) {
            that.$message({
              message: "已撤销该笔售后操作!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
          } else {
            that.$message({
              message: res.message,
              offset: 400,
              duration: 1000,
              type: "error",
            });
          }
        });
      });
    },
    refundLogisticsStatus(logisticsStatus) {
      if (logisticsStatus === 1) {
        return "未收到货";
      } else if (logisticsStatus === 2) {
        return "已收到货";
      }
    },
    refundInfo(info) {
      if (info === 0) {
        return "商品压损/质量问题";
      } else if (info === 1) {
        return "批号不符";
      } else if (info === 2) {
        return "商家发错货";
      } else if (info === 3) {
        return "发货少货";
      } else if (info === 4) {
        return "药品近效期";
      } else if (info === 5) {
        return "发票相关-无票";
      } else if (info === 6) {
        return "发票相关-错票";
      } else if (info === 7) {
        return "发票相关-申请专票";
      } else if (info === 8) {
        return "发票相关-发票其他";
      } else if (info === 9) {
        return "资质相关";
      } else if (info === 10) {
        return "其他";
      }
    },
    auditStatus(auditStatus) {
      if (auditStatus === 1) {
        return "待审批";
      } else if (auditStatus === 2) {
        return "已通过";
      } else if (auditStatus === 3) {
        return "已驳回";
      }
    },

    // 查询售后订单信息
    async getRefundOrderData() {
      let refundId = this.refundId;
      let url = "/saleOrder/saleReturnOrder/queryById";
      let data = {
        id: refundId,
      };
      this.loadingImgStatus = true;
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        this.loadingImgStatus = false;
        console.log("当前售后订单的数据为++++++", res);
        this.refundOrder = resData;
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = this.ruleForm;
          let _this = this;
          let saleOrderId = this.saleOrderId; // 订单id
          let orderItemList = this.orderItemList; // 退货列表
          let returnGoods = []; // 退货列表
          for (let key of orderItemList) {
            if (key.applyAmount && key.applyAmount > 0) {
              let returnOrder = {
                amount: key.applyAmount,
                saleOrderItemId: key.id,
                inventoryUid: key.inventoryUid,
                productId: key.productId,
              };
              returnGoods.push(returnOrder);
            }
          }
          let info = formData.info; // 退货类型
          let logisticsStatus = formData.logisticsStatus; // 收货状态
          let remark = formData.remark; // 退货备注
          let data = {
            saleReturnOrder: {
              saleOrderId: saleOrderId,
              logisticsStatus: logisticsStatus,
              type: 2,
              info: info,
              remark: remark,
            },
          };
          if (returnGoods.length > 0) {
            data.saleReturnOrderItemList = returnGoods;
          } else {
            this.$alert("请选择退货产品！", "提示信息：", { type: "warning" });
            return false;
          }
          let url = "/saleOrder/saleReturnOrder/add";
          postAction(url, data, true).then((res) => {
            if (res.code === 200 && res.success) {
              _this.$alert("添加退货申请成功！", "提示信息：", {
                type: "success",
              });
              _this.resetForm();
              _this.$router.push("/orderList");
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      let returnGoods = this.orderItemList; // 退货列表
      for (let key of returnGoods) {
        key.applyAmount = "";
      }
      this.$forceUpdate();
    },
    // 全部退货申请
    allOrderReturn() {
      let orderItemList = this.orderItemList;
      let orderItemArr = [];
      for (let key of orderItemList) {
        if (key.canReturnAmount && key.canReturnAmount > 0) {
          key.applyAmount = key.canReturnAmount;
        }
        orderItemArr.push(key);
      }
      this.orderItemList = orderItemArr;
    },
    // 数量减少操作
    lessReturnAmount(e, item) {
      let applyAmount = 0;
      if (item.applyAmount && item.applyAmount > 0) {
        applyAmount = item.applyAmount;
      }
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      console.log("该订单商品可申请退货数量less为", canReturnAmount);
      if (applyAmount > 1 && applyAmount <= canReturnAmount) {
        item.applyAmount = applyAmount - 1;
      }
      this.$forceUpdate();
    },
    // 修改输入框数量的操作
    changeReturnAmount(e, item) {
      let applyAmount = parseInt(e.target.value);
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      console.log(
        "该订单商品可申请退货数量change为",
        canReturnAmount,
        applyAmount
      );
      if (applyAmount > canReturnAmount && canReturnAmount > 0) {
        item.applyAmount = canReturnAmount;
      } else if (applyAmount <= canReturnAmount && applyAmount > 0) {
        item.applyAmount = applyAmount;
      } else {
        this.$message({
          message: "该商品可申请退货数量为0！",
          offset: 400,
          duration: 1000,
          type: "error",
        });
        item.applyAmount = "";
      }
      this.$forceUpdate();
    },
    // 数量添加操作
    addReturnAmount(e, item) {
      let applyAmount = 0;
      if (item.applyAmount && item.applyAmount > 0) {
        applyAmount = item.applyAmount;
      }
      let canReturnAmount = parseInt(item.canReturnAmount) | 0;
      console.log("该订单商品可申请退货数量add为", canReturnAmount);
      if (applyAmount >= 0 && applyAmount < canReturnAmount) {
        item.applyAmount = applyAmount + 1;
      }
      this.$forceUpdate();
    },
    // 获取订单详情数据
    async getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      let res = await getAction(url, data);
      if (res.code === 200 && res.success) {
        let resData = res.result;
        that.orderItemList = resData.saleOrderItemList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/applyForReturn.scss";
</style>