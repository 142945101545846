<template>
  <div>
    <!-- 申请退货流程 -->
    <div class="return-refund">
      <div class="header-title">
        <p>选择售后类型 <span></span></p>
      </div>
      <div class="refund-list">
        <!-- 第一步选择退货/退款类型 -->
        <div class="selected-refund-type">
          <div class="type-content">
            <div
              @click="changeRefundType(2)"
              class="type-box"
              :class="{ act: type === 2 }"
            >
              <img src="~@/assets/images/order/refund/return-icon.png" alt="" />
              <div class="type-tips">
                <p class="title">退货退款</p>
                <p class="tips">已收到货，需要退还收到的货物</p>
              </div>
            </div>
            <div
              @click="changeRefundType(1)"
              class="type-box"
              :class="{ act: type === 1 }"
            >
              <img src="~@/assets/images/order/refund/return-icon.png" alt="" />
              <div class="type-tips">
                <p class="title">仅退款/无需退货</p>
                <p class="tips">包裹为空，丢失，或与商家协商同意不退货只退款</p>
              </div>
            </div>
            <div
              @click="changeRefundType(3)"
              class="type-box"
              :class="{ act: type === 3 }"
            >
              <img src="~@/assets/images/order/refund/return-icon.png" alt="" />
              <div class="type-tips">
                <p class="title">退运费</p>
                <p class="tips">多收运费，或与商家协商同意退运费</p>
              </div>
            </div>
          </div>
          <!-- 退货商品列表 -->
          <refund-goods
            v-if="type > 0 && type !== 3"
            @callbackApplyList="callbackApplyList"
          ></refund-goods>
          <!-- END 退货商品列表 -->
          <!-- 选择退货原因 -->
          <div v-if="type > 0" class="refund-content">
            <el-form
              :model="refundForm"
              :rules="rules"
              ref="refundForm"
              label-width="100px"
              class="demo-refundForm"
            >
              <el-form-item label="退款总金额" prop="refundMoney">
                <p class="refund-money">
                  ￥{{ parseFloat(refundForm.refundMoney.toPrecision(12)) }}
                </p>
              </el-form-item>
              <el-form-item
                v-if="type == 2"
                label="货物状态"
                prop="logisticsStatus"
              >
                <el-select
                  v-model="refundForm.logisticsStatus"
                  placeholder="请选择售后原因"
                >
                  <el-option
                    style="padding-left: 20px"
                    v-for="item in logisticsStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="售后原因" prop="info">
                <el-select
                  v-model="refundForm.info"
                  placeholder="请选择售后原因"
                >
                  <el-option
                    style="padding-left: 20px"
                    v-for="item in infoOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <p class="tips">温馨提示：若存在多个退货原因请分批提交</p>
              </el-form-item>
              <el-form-item label="售后说明" prop="remark">
                <el-input
                  class="remark-input"
                  rows="3"
                  style="min-height: 33px"
                  placeholder="您可以详细描述退货具体原因"
                  type="textarea"
                  v-model="refundForm.remark"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传凭证" prop="refundPic">
                <el-upload
                  action="/web/file/img/uploadImg"
                  :data="otherData"
                  accept="image/jpg,image/png,image/jpeg"
                  list-type="picture-card"
                  :limit="5"
                  :file-list="refundForm.refundPic"
                  :on-success="uploadSuccessRefundPic"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p class="upload-tips" style="color: #999">
                  最多可上传5张图片，图片大小不超过8M，支持jpeg\jpg\png\bmp格式
                </p>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="submit"
                  size="big"
                  type="primary"
                  @click="onSubmit"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- END 选择退货原因 -->
        </div>
        <!-- END 第一步选择退货/退款类型 -->
      </div>
    </div>
    <!-- END 申请退货流程 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { accAdd, accMuls } from "../assets/js/common/common";
import RefundGoods from "../components/order/RefundGoods.vue";
import { getAction, postAction } from "../api/manage";
export default {
  components: { RefundGoods },
  data() {
    return {
      flowType: 1, // 1.创建退货单 2.待审核 3.审核通过回填物流单号  4.
      saleOrderId: "",
      refundForm: {
        type: 2,
        refundMoney: 0,
      },
      dialogVisible: false,
      dialogImageUrl: "",
      saleReturnOrderItemList: [], // 申请退货商品列表
      type: 0,
      rules: {
        refundMoney: [{ required: true }],
        logisticsStatus: [{ required: true, message: "请选择货物状态" }],
        info: [{ required: true, message: "请选择售后原因" }],
        remark: [{ required: true, message: "请填写售后说明" }],
        refundPic: [{ required: false, message: "请上传售后凭证" }],
      },

      logisticsStatusOptions: [
        {
          value: 1,
          label: "未收到货",
        },
        {
          value: 2,
          label: "已收到货",
        },
      ],
      infoOptions: [
        {
          value: 0,
          label: "商品压损/质量问题",
        },
        {
          value: 1,
          label: "批号不符",
        },
        {
          value: 2,
          label: "商家发错货",
        },
        {
          value: 3,
          label: "发货少货",
        },
        {
          value: 4,
          label: "药品近效期",
        },
        {
          value: 5,
          label: "发票相关-无票",
        },
        {
          value: 6,
          label: "发票相关-错票",
        },
        {
          value: 7,
          label: "发票相关-申请专票",
        },
        {
          value: 8,
          label: "发票相关-发票其他",
        },
        {
          value: 9,
          label: "资质相关",
        },
        {
          value: 10,
          label: "其他",
        },
      ],
      otherData: {},
      logisticsMoney: 0,
    };
  },
  created() {
    let routeQuery = this.$route.query;
    if (routeQuery.saleOrderId && routeQuery.saleOrderId !== "") {
      this.saleOrderId = routeQuery.saleOrderId;
    }
    let token = this.$base64.decode(this.token);
    this.otherData = {
      type: 21,
      "Shopping-Access-Token": token,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    // 获取订单详情数据
    async getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      let res = await getAction(url, data);
      if (res.code === 200 && res.success) {
        let resData = res.result;
        that.refundForm.refundMoney = resData.saleOrder.logisticsMoney;
      }
    },
    // 提交退货退款申请
    onSubmit() {
      let _this = this;
      this.$refs.refundForm.validate((valid) => {
        if (valid) {
          let refundForm = _this.refundForm;
          refundForm.saleOrderId = _this.saleOrderId;
          refundForm.type = _this.type;
          console.log("当前提交的退货申请数据为", refundForm);
          // 整理上传的售后图片格式
          let refundPic = refundForm.refundPic;
          if (refundPic && refundPic.length > 0) {
            let fileList = [];
            for (let key of refundPic) {
              fileList.push(key.response.message);
            }
            refundForm.picUrl = fileList.toString();
          } else {
            refundForm.picUrl = "";
          }
          // 判断售后金额时候为0
          let refundMoney = _this.refundForm.refundMoney;
          delete refundForm.refundPic;
          if (refundMoney > 0) {
            let url = "saleOrder/saleReturnOrder/add";
            let saleReturnOrderItemList = _this.saleReturnOrderItemList;
            let refundData = {
              saleReturnOrder: refundForm,
              saleReturnOrderItemList: saleReturnOrderItemList,
            };
            postAction(url, refundData).then((res) => {
              console.log("当前处理的订单售后提交返回的数据为", res);
              if (res.code === 200 && res.success) {
                _this.$message.success("提交售后申请成功，请耐心等待审核！");
                _this.$router.push({
                  path: "/refundList",
                  query: {
                    state: 13,
                  },
                });
              } else {
                _this.$message.error(res.message);
              }
            });
          } else {
            _this.$message.error("请勾选需要售后的商品信息！");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeRefundType(type) {
      this.refundForm.refundMoney = 0;
      this.type = type;
      if (type === 3) {
        this.getOrderDeatil();
      }
    },
    // 预览凭证资质
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 移除凭证照片操作
    handleRemove(file, fileList) {
      console.log("当前移除的售后凭证file", file);
      console.log("当前移除的售后凭证fileList", fileList);
      this.refundForm.refundPic = fileList;
    },
    // 上传售后图片成功
    uploadSuccessRefundPic(response, file, fileList) {
      console.log("上传图片成功的response", response);
      if (response.code === 200 && response.success) {
        this.refundForm.refundPic = fileList;
      } else {
        this.$message.error("售后图片上传失败！");
      }
    },
    callbackApplyList(refundGoodList) {
      let orderItemList = refundGoodList;
      let refundMoney = 0;
      let saleReturnOrderItemList = [];
      for (let key of orderItemList) {
        if (key.applyAmount && key.applyAmount > 0) {
          let confirmPrice = key.confirmPrice;
          let applyAmount = key.applyAmount;
          let data = {
            amount: key.applyAmount,
            saleOrderItemId: key.id,
            inventoryUid: key.inventoryUid,
            productId: key.productId,
          };
          saleReturnOrderItemList.push(data);
          refundMoney += parseFloat(accMuls(confirmPrice, applyAmount));
        }
      }
      this.refundForm.refundMoney = refundMoney;
      console.log("前端计算的退货总金额", refundMoney);
      this.saleReturnOrderItemList = saleReturnOrderItemList;
      console.log("用户勾选的退货明细列表数据", saleReturnOrderItemList);
    },
  },
};
</script>

<style lang="scss" scoped>
// 使用url会引用全局base.scss样式格式化
@import "~@/assets/styles/order/return-refund.scss";
</style>