<template>
  <div class="noticeListBox">
    <div v-if="!detailShowState" class="noticeHeader">消息通知</div>
    <div
      @click="detailShowState = false"
      style="cursor: pointer"
      class="noticeHeader"
      v-else
    >
      <i class="el-icon-arrow-left"></i>
      返回上一级
    </div>
    <div v-if="detailShowState" class="noticeDetail">
      <div v-html="noticeDetailData.detail"></div>
    </div>
    <div
      v-else-if="!detailShowState && noticeDetailList.length > 0"
      class="notcieList"
    >
      <div
        class="noticeItem"
        v-for="(item, index) of noticeDetailList"
        :style="`z-index: ${10 - index}`"
        :key="item.id + '_' + index"
        @click="linkNoticeDetail(item)"
      >
        <div class="noticeDate">
          <p class="nianyue">{{ cleanData(item, 1) }}</p>
          <p class="ri">{{ cleanData(item, 2) }}</p>
        </div>
        <h3 class="noticeTitle">{{ item.title }}</h3>
        <p class="introduction">
          {{ item.briefIntroduction }}
        </p>
      </div>
    </div>
    <div
      v-else-if="!detailShowState && noticeDetailList.length == 0"
      class="dataNone"
    >
      <a to="javascript:;">
        <img src="../assets/images/common/dataNone.png" alt="" />
      </a>
    </div>
    <div v-if="!detailShowState" class="pagination-bottom">
      <el-pagination
        background
        :hide-on-single-page="true"
        v-if="totalCount > 0"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
export default {
  name: "NoticeList",
  data() {
    return {
      noticeId: 0,
      pageNo: 1,
      pageSize: 20,
      totalCount: 0,
      detailShowState: false,
      noticeDetailList: [],
      noticeDetailData: {},
    };
  },
  created() {
    let noticeId = this.$route.query.id;
    let noticeDetailId = this.$route.query.detailId;
    if (noticeId && noticeDetailId && noticeDetailId !== "") {
      this.noticeId = noticeId;
      console.log("应该显示详情数据");
      this.getNoticeIdData(noticeDetailId);
    } else if (noticeId && noticeId !== "") {
      this.noticeId = noticeId;
      this.getNoticeList();
    }
  },
  methods: {
    getNoticeList() {
      let _this = this;
      this.detailShowState = false;
      let noticeId = this.noticeId;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        noticeId: noticeId,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      let url = "/notice/noticeDetail/list";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.noticeDetailList = resData.records;
          _this.pageNo = resData.current;
          _this.totalCount = resData.total;
        }
      });
    },
    // 获取指定通知公告的内容

    getNoticeIdData(noticeId) {
      let _this = this;
      let data = {
        id: noticeId,
      };
      let url = "/notice/noticeDetail/queryById";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.detailShowState = true;
          _this.noticeDetailData = res.result;
        }
      });
    },
    // 处理公告时间
    cleanData(item, flag) {
      let date = item.createTime;
      console.log("cleanData++++++++", date);
      if (date) {
        let dateArr = date.split(" ");
        let nianyueri = dateArr[0].split("-");
        if (flag == 1) {
          return `${nianyueri[0].substring(2, 4)}-${nianyueri[1]}`;
        } else if (flag == 2) {
          return nianyueri[2];
        }
      } else {
        return ""
      }
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getNoticeList();
    },
    // 查看公告详情
    linkNoticeDetail(item) {
      console.log("当前选择的公告的数据为++++++", item);
      this.detailShowState = true;
      this.noticeDetailData = item;
    },
  },
  watch: {
    $route(to, form) {
      if (to.query && to.query.id) {
        let noticeId = to.query.id;
        this.noticeId = noticeId;
        this.getNoticeList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.noticeListBox {
  width: 1060px;
  float: right;
  border-radius: 8px;
  .noticeHeader {
    @include centerCardHeader;
  }
  .notcieList {
    .noticeItem {
      width: 100%;
      height: 80px;
      padding-top: 5px;
      background: #ffffff;
      position: relative;
      padding-left: 85px;
      box-shadow: 0 9px 5px 0px #bad3e83b;
      cursor: pointer;
      &:hover .noticeTitle,
      &:hover .introduction {
        color: $base-color;
      }
      .noticeDate {
        position: absolute;
        width: 65px;
        height: 63px;
        top: 0;
        left: 0;
        background: url("~@/assets/images/center/notice/dateBg.png") no-repeat
          center;
        p {
          color: #ffffff;
          width: 100%;
          text-align: center;
        }
        .nianyue {
          font-size: 15px;
          margin-top: 5px;
        }
        .ri {
          font-size: 24px;
          margin-top: -5px;
        }
      }
      .noticeTitle {
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        color: $font-color;
        font-weight: normal;
      }
      .introduction {
        font-size: 13px;
        color: $font-color;
      }
    }
  }
}

.pagination-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>