<template>
  <div class="prize-content">
    <div class="prize-box">
      <div class="prize-title-box">
        <div class="left-img"></div>
        <span>本期奖品</span>
        <div class="right-img"></div>
      </div>
      <div class="gift-list">
        <div class="gift-box">
          <div :title="item.name" v-for="(item, index) of activityGiftList" :key="index" class="gift-item">
            <div class="gift-bg">
              <img :src="item.iconUrl" alt="">
            </div>
            <div class="gift-name">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrentPrize",
  props: {
    activityGiftList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.prize-content {
  width: 1190px;
  margin: 20px auto;
  margin-bottom: 0;
  text-align: center;

  .prize-box {
    max-width: 1190px;
    min-width: 600px;
    margin: 0 auto;
    display: inline-block;
    min-height: 480px;
    padding: 40px;
    background: #FAE3DD;
    border-radius: 30px;

    // 标题样式
    .prize-title-box {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        font-size: 32px;
        letter-spacing: 3px;
        text-indent: 3px;
         font-family:  'SourceHanSansSC', 'Microsoft YaHei',sans-serif;
        font-weight: 500;
        color: #CA321A;
        line-height: 36px;
        margin: 0 15px;
        padding-bottom: 4px;
      }

      .left-img,
      .right-img {
        width: 55px;
        height: 44px;
        background: url("~@/assets/images/activity/title-left-icon.png") no-repeat center;

        &.right-img {
          transform: rotateY(180deg);
        }
      }
    }

    // 奖品样式
    .gift-list {
      width: 100%;
      margin-top: 25px;

      .gift-box {
        min-width: 960px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .gift-item {
          flex: 0 0 20%;
          text-align: center;
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .gift-bg {
          overflow: hidden;
          width: 160px;
          height: 160px;
          background: #FFFFFF;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            max-width: 160px;
            max-height: 160px;
            width: auto;
            height: auto;
          }
        }

        .gift-name {
          cursor: pointer;
          max-height: 105px;
          overflow: hidden;

          span {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 35px;
          }
        }
      }
    }
  }
}
</style>