<template>
  <div id="goodsImgBox">
    <div>
      <ul class="goods-tag">
        <li
          v-for="(item, index) of productList"
          v-bind:key="'imgProduct' + index"
          :class="{
            'offline-sale-item':
              item.offlineSaleFlag && item.offlineSaleFlag == '1',
          }"
        >
          <div>
            <div class="productImgBox">
              <router-link
                :to="{
                  path: '/orderMeetingProductDetail',
                  query: { productId: item.productId },
                }"
                style="display: inline-block"
                :title="item.name"
                target="_blank"
              >
                <!-- 查看专区产品遮罩图 -->
                <img
                  v-if="specialAreaMaskImg && specialAreaMaskImg !== ''"
                  :src="specialAreaMaskImg"
                  class="maskImg"
                  alt="产品遮罩图"
                />
                <!-- 查看产品标签遮罩图 -->
                <img
                  v-else-if="
                    item.productLabelList &&
                    item.productLabelList.length > 0 &&
                    prolabelMaskImg(item.productLabelList) !== ''
                  "
                  :src="prolabelMaskImg(item.productLabelList)"
                  class="maskImg"
                  alt="产品遮罩图"
                />
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.name }}
          </div>
          <!--判断产品是否有库存-->
          <div
            v-if="!item.inventoryList || item.inventoryList.length === 0"
            class="proPriceContent clearfix"
          >
            <p class="priceBox fl" style="font-size: 17px">
              {{ loginState ? "￥--/--" : "会员可见" }}
            </p>
          </div>
          <div
            v-else-if="
              item.offlineSaleFlag &&
              item.offlineSaleFlag == '1' &&
              item.productNotputSaleShowPrice !== '1'
            "
            class="proPriceContent clearfix"
          >
            <p class="priceBox fl" style="font-size: 17px">请联系开票员</p>
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div
            v-else-if="
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            "
            class="proPriceContent clearfix"
          >
            <p class="priceBox fl">
              ￥{{ item.productActivityPriceStrategyDetail.price }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
            </p>
          </div>
          <!-- 2.再判断产品是否参与折扣活动-->
          <div
            v-else-if="
              item.inventoryList[0].discountPrice &&
              item.inventoryList[0].discountPrice !== ''
            "
            class="proPriceContent clearfix"
          >
            <p class="priceBox fl">
              ￥{{ item.inventoryList[0].discountPrice }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].price }}
            </p>
          </div>
          <!-- 3.再判断产品策略里面是否定义了原价-->
          <div
            v-else-if="
              item.inventoryList[0].originalPrice &&
              item.inventoryList[0].originalPrice !== ''
            "
            class="proPriceContent clearfix"
          >
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].originalPrice }}
            </p>
          </div>
          <!-- 4.显示原价 -->
          <div v-else class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
          </div>
          <div
            v-if="
              loginState && item.inventoryList && item.inventoryList.length > 0
            "
            class="tipBox"
          >
            <el-tooltip
              v-if="
                item.productActivityPriceStrategyFlag === 1 &&
                item.productActivityPriceStrategyDetail &&
                item.productActivityPriceStrategyDetail.canSaleAmount
              "
              class="item"
              effect="dark"
              content="该产品为秒杀产品"
              placement="top"
            >
              <span class="tip1">秒杀</span>
            </el-tooltip>

            <el-tooltip
              v-if="
                item.inventoryList[0].newCustomerPriceFlag &&
                item.inventoryList[0].newCustomerPriceFlag == '1'
              "
              class="item"
              effect="dark"
              content="新用户特价产品"
              placement="top"
            >
              <span class="tip3">特价</span>
            </el-tooltip>
            <span
              class="tip1"
              v-if="item.inventoryList[0].deliveryTime"
              :style="
                item.inventoryList[0].deliveryTime === '24'
                  ? 'background-color:#009944'
                  : 'background-color:#18a97b'
              "
              >{{ item.inventoryList[0].deliveryTime }}</span
            >
            <span
              v-for="labelItem in classifyLabelList(item)"
              :key="labelItem.id"
              class="tip3"
              >{{ labelItem.name }}</span
            >
            <span
              class="tip2"
              v-if="
                item.inventoryList[0].priceTitle &&
                item.inventoryList[0].priceTitle !== ''
              "
              >{{ item.inventoryList[0].priceTitle }}</span
            >

            <el-tooltip
              v-if="
                item.policyFlag &&
                item.productPolicyList &&
                item.productPolicyList.length > 0
              "
              class="item"
              effect="dark"
              :content="item.productPolicyList[0].remark"
              placement="top"
            >
              <span class="tip3">促销</span>
            </el-tooltip>
            <el-tooltip
              v-if="item.inventoryList[0].nearDateFlag"
              class="item"
              effect="dark"
              content="该产品为近效期产品"
              placement="top"
            >
              <span class="tip4">近效期</span>
            </el-tooltip>
            <span
              class="tip5"
              v-if="
                (item.productActivityPriceStrategyFlag !== 1 ||
                  item.productActivityPriceStrategyDetail.canSaleAmount ===
                    0) &&
                item.minDiscountTicket &&
                item.minDiscountTicket.name !== ''
              "
            >
              {{ item.minDiscountTicket.name }}
            </span>
          </div>
          <div class="tipBox" v-else>
            <span
              v-if="!loginState"
              class="tip1"
              style="background-color: #009944"
            ></span>
          </div>
          <div
            style="cursor: pointer"
            @click="searchFatory(item.manufacturer)"
            class="img-compamy"
            :title="item.manufacturer"
          >
            {{
              item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                ? item.manufacturer
                : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.format">{{ item.format }}</span>
          </div>
          <div v-if="loginState" class="img-guige">
            有效期:
            {{
              item.inventoryList &&
              item.inventoryList.length > 0 &&
              item.inventoryList[0].endDate &&
              item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : "暂无效期"
            }}
          </div>
          <div v-else class="img-guige">
            有效期: <span style="color: #fd2c17">会员可见</span>
          </div>
          <div class="img-xiaoqi">
            <span
              v-if="item.inventoryList && item.inventoryList.length > 0"
              class="kucun"
            >
              库存：{{ showInventoryText(item.inventoryNum, item) }}
            </span>
            <span v-else-if="loginState" class="kucun">库存：暂无库存</span>
            <span v-else class="kucun"
              >库存：<i style="font-style: normal; color: #fd2c17"
                >会员可见</i
              ></span
            >
            <span
              :title="`${item.mediumPkgSize}/${item.bigPkgSize}`"
              class="baozhuang"
              >中/大包装：{{ item.mediumPkgSize }}/{{ item.bigPkgSize }}</span
            >
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText" style="color: #fd2c17">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              <img src="~@/assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
          <div
            v-else-if="defaultInventory(item.inventoryList).length > 0"
            class="cz_content"
          >
            <div
              v-if="
                item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.productNotputSaleCreateOrder !== '0'
              "
              class="amoutBox"
            >
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input
                :class="{
                  shoppingHas:
                    item.inventoryList[0].shoppingCartAmount &&
                    item.inventoryList[0].shoppingCartAmount > 0,
                }"
                :value="item.inventoryList[0].addCartQuantity"
                @focus="selectShopAmount($event)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)"
                type="text"
              />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div
              @click="jionShopCart(item.inventoryList[0], item, $event)"
              class="jionBtnBox"
              :style="
                item.offlineSaleFlag &&
                item.offlineSaleFlag == '1' &&
                item.productNotputSaleCreateOrder == '0'
                  ? 'width: 100%'
                  : ''
              "
            >
              <img src="~@/assets/images/product/img/jionShop.png" />
              {{
                item.offlineSaleFlag && item.offlineSaleFlag == "1"
                  ? item.productNotputSaleCreateOrder == "1"
                    ? "开票员订货"
                    : "请联系开票员订货"
                  : "加入购物车"
              }}
            </div>
          </div>
          <div v-else class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">暂无库存</p>
            </div>
            <div class="jionBtnBox spqgBtnBox">
              <router-link
                :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }"
              >
                <img src="~@/assets/images/product/img/search.png" />
                商品求购
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  name: "GoodsImg",
  props: [
    "productList",
    "isClinicAccount",
    "hyProductStatus",
    "specialAreaMaskImg",
  ],
  mixins: [ProductMixin],
  data() {
    return {
      showMoveDot: [],
      elLeft: 0,
      elTop: 0,
      dropImage: false,
    };
  },
  methods: {
    classifyLabelList(item) {
      let labelList = [];
      if (item.productLabelList && item.productLabelList.length > 0) {
        let productLabelList = item.productLabelList;
        labelList = productLabelList.filter((key) => {
          return key.id === 340 || key.id === 341 || key.id === 342;
        });
      }
      return labelList;
    },
    listTypeChange(num) {
      this.$emit("listTypeChange", num);
    },
    beforeEnter(el) {
      el.style.transform = `translate3d(${this.elLeft + 50}px,${
        this.elTop - 300
      }px , 0)`;
      el.style.opacity = 0;
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@include label;

.goods-tag {
  width: 100%;
  overflow: hidden;

  .offline-sale-item {
    background-color: #e2e9ee;
  }
}

.fl {
  float: left;
}

.tableHeader {
  width: 1200px;
  height: 40px;
  margin-top: 30px;
  text-align: right;
  border-bottom: none !important;
  background: #eff1fe !important;
  color: #606060;

  span {
    display: inline-block;
    height: 41px;
    line-height: 40px;
    color: #999999;
    font-size: 13px;

    i {
      font-style: normal !important;
    }
  }

  .dqy {
    text-align: right;
    width: 125px;
    letter-spacing: 1px;

    i {
      font-style: normal;
      font-size: 15px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: bold;

      &:hover {
        color: $base-color;
      }
    }

    .nowPage {
      color: $base-color;
    }
  }

  .listtype,
  .listtype img {
    vertical-align: middle;
  }

  .listtype {
    width: 80px;
    line-height: 40px;
    text-align: center;
    margin: 0;
    font-size: 13px;

    img {
      cursor: pointer;
      margin-right: 5px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .imgIcon {
      margin-top: -2px;
      margin-left: 5px;
    }
  }
}

.content {
  margin-bottom: 50px;
}

.goods-tag li {
  position: relative;
  width: 230px;
  height: 400px;
  padding: 11px;
  padding-bottom: 0px;
  border: 1px solid transparent;
  float: left;
  margin-bottom: 20px;
  margin-right: 20px;
  background-color: #ffffff;

  &:nth-child(4n) {
    margin-right: 0px;
  }

  .tipBox {
    width: 100%;
    height: 22px;
    line-height: 22px;
    overflow: hidden;

    span {
      padding: 0px 5px;
      float: left;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #ffffff;
      font-size: 12px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0 !important;
      }
    }

    .tip1 {
      background: #ea5506;
    }

    .tip2 {
      background: #4d5aaf;
    }

    .tip3 {
      background: #d61518;
    }

    .tip4 {
      background: #9f10f9;
    }

    .tip5,
    .tip6 {
      background: #5064eb;
    }
    .tip7 {
      background: #fd2c17;
    }
  }
}

.goods-tag li img:hover {
  transition: 0.5s;
  /*transform: translateY(-5px);*/
}

.productImgBox {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  // margin-bottom: 10px;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;

  &:hover .youxiaoqi {
    bottom: 0px !important;
  }

  .youxiaoqi {
    position: absolute;
    width: 100%;
    height: 30px;
    background: #eeeeee;
    line-height: 30px;
    font-weight: 600;
    color: #8c8a88;
    font-size: 13px;
    bottom: -30px;
    transition: 0.3s;
  }

  .youxiaoqi {
  }
}

.productImgBox a {
  display: inline-block;
  width: 200px;
  height: 200px;
  position: relative;

  .maskImg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
}

.img-shoucang {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.2s linear;
  filter: Alpha(Opacity=0);
  cursor: pointer;
}

.img-jiage {
  color: #ef2c2f;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  margin-left: 10px;
}

.proPriceContent {
  width: 100%;
  color: $act-color;
  height: 22px;
  line-height: 22px;
  float: left;
  @include ellipsis;

  p {
    font-size: 20px;

    i {
      font-style: normal;
      font-size: 16px;
    }
  }
}

.img-min-jiage-none {
  height: 26px;
  line-height: 26px;
  margin-left: 10px;
  color: #828282;
  font-size: 12px;
  padding-left: 5px;
  float: left;
}

.img-mingzi,
.img-compamy {
  width: 100%;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-mingzi {
  font-size: 15px;
  height: 22px;
  line-height: 22px;
  color: #000000;
}

.img-compamy {
  height: 20px;
  line-height: 20px !important;
  color: $font-color;
}

.img-guige,
.img-xiaoqi,
.img-jianzhuang {
  color: $font-color;
  height: 20px;
  line-height: 20px;
  width: 100%;
  font-size: 12px;
  float: left;
}

.img-jianzhuang {
  height: 25px;
  line-height: 25px;
}

.actContentTag {
  width: 100%;
  display: inline-block;
  max-width: 215px;
  height: 25px;
  line-height: 25px;
  float: left;
  margin: 5px 0;
  overflow: hidden;

  span {
    white-space: nowrap;
    line-height: 21px;
    height: 25px;
    text-align: center;
    margin: 0 2px !important;
    display: inline-block;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  .tag1 {
    color: #009944;
    border: 2px solid;
    border-radius: 5px;
    padding: 1px 5px;
  }

  .tag2 {
    color: #009944;
    border: 2px solid;
    border-radius: 5px;
    padding: 1px 5px;
  }

  .tag3 {
    background: #e60012;
    color: #ffffff;
    border: 2px solid #e60012;
    border-radius: 5px;
    padding: 1px 5px;
    margin-right: 10px;
  }

  .tag4 {
    background: #009944;
    color: #ffffff;
    border: 2px solid #009944;
    border-radius: 5px;
    padding: 1px 5px;
    margin-right: 10px;
  }

  .tag5 {
    background: #eb6100;
    color: #ffffff;
    border: 2px solid #eb6100;
    border-radius: 5px;
    padding: 1px 5px;
    margin-right: 10px;
  }

  .tag6 {
    background: #86227a;
    color: #ffffff;
    border: 2px solid #86227a;
    border-radius: 5px;
    padding: 1px 5px;
    margin-right: 10px;
  }
}

.img-guige span,
.img-xiaoqi span:first-child,
.img-jianzhuang span:first-child {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.img-xiaoqi span:first-child,
.img-jianzhuang span:first-child {
  width: 35px;
}

.img-xiaoqi {
  span {
    display: inline-block;
    width: 50% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kucun {
  }
}

.img-zhekou1 {
  padding: 3px 5px;
  border-radius: 2px;
  text-align: center;
  background: #ff882e;
  color: #ffffff;
}

.img-zhekou2 {
  padding: 2px;
  border-radius: 2px;
  text-align: center;
  margin-left: 15px;
  border: 1px solid #ef3338;
  color: #ef3338;
}

.img-guige span {
  color: $font-color;
  width: 160px;
}

.goods-tag li .caozuoBox {
  height: 30px;
  margin-right: 5px;
  float: left;
}

.jionBtnBox {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 90px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: $base-color;
  color: $base-color;
  background-color: #ffffff;
  border: 1px solid $base-color;
  float: right;
  cursor: pointer;
}

.spqgBtnBox {
  background: #ffffff;
  color: $base-color;
  line-height: 23px;
  height: 25px;
  border: 1px solid $base-color;

  img {
    margin-top: 5px !important;
  }
}

.jionBtnBox img {
  margin-top: 3px;
}

.goods-tag li:hover {
  border: 1px solid #ececec;
  box-shadow: 1px 1px 8px #18a97b25;
}

.goods-tag li:hover .img-shoucang {
  filter: Alpha(Opacity=100);
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s linear;
}

.caozuoBox {
  max-width: 100px;
  height: 25px;
  line-height: 25px;

  .zwkcText {
    width: 100%;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    color: $font-color;
    @include ellipsis;
  }
}

.caozuoBox input {
  width: 40px;
  height: 28px;
  line-height: 22px;
  text-align: center;
  border: 1px solid #8e98dc;
  border-left: none;
  border-right: none;
  float: left;
  color: #666666;
}

#goodsImgBox .addBtn,
#goodsImgBox .lessBtn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 27px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #8e98dc;
  float: left;
  cursor: pointer;
  color: #666666;
  text-align: center;
  font-size: 16px;
}

.cz_content {
  float: left;
  width: 100%;
  margin-top: 5px;
  height: 25px;
  line-height: 25px;
}

.datu_bs {
  position: absolute;
  top: 15px;
  left: 15px;
}

.datu_bs .tj,
.datu_bs .yh,
.datu_bs .mz,
.datu_bs .hg {
  width: 40px;
  line-height: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  margin-right: 5px;
}

#goodsImgBox .xiaoqi,
#goodsImgBox .tj {
  background: #ff5d60;
}

#goodsImgBox .mz {
  background: #3634cf;
}

#goodsImgBox .hg {
  background: #bb8d2b;
}

#goodsImgBox .yh {
  background: #418ed2;
}

.datu_tishi {
  width: 130px;
  padding: 5px 10px;
  background: #fff;
  position: absolute;
  top: 30px;
  left: 0;
  border-radius: 3px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: none;
}

#goodsImgBox .puyao_sanjiao {
  top: -7px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
}

/*大图产品样式结束*/
.min,
.max {
  color: #666666 !important;
  background: #f6f6f6;

  &:hover {
    background: #dddddd;
    opacity: 0.9;
  }
}

/*分页*/
#znq-daohang {
  right: 45px !important;
  bottom: 20px !important;
}

#layer_tips {
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2) !important;
  color: #333 !important;
  max-width: 140px;
  text-align: justify;
}

th.cxbs {
  text-indent: 10px;
}

td.cxbs span:first-child + span {
  margin-left: 0;
}

.puyao_title_list img {
  max-width: none;
  max-height: none;
}

/*为空*/
.none {
  text-align: center;
  padding: 60px 0;
}

.none .img_box,
.none .text {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.none .text {
  margin-left: 50px;
  text-align: left;
  position: relative;
  top: 55px;
}

.none .text p:first-child {
  font-size: 18px;
}

.none .text p:first-child span {
  color: #316aef;
  font-size: 18px;
}

.none .text p:first-child + p {
  color: #ff1919;
  font-size: 16px;
  margin-top: 10px;
}

.none .text a.link {
  display: inline-block;
  width: 86px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: $base-color;
  border-radius: 5px;
  margin-top: 10px;
}

/*为您推荐*/
.ban {
  position: relative;
  overflow: hidden;
  width: 1200px;
}

.banner .img {
  width: 99999px;
  position: absolute;
  left: 0;
  top: 0;
}

#ban2 .banner .img {
  top: 10px;
}

.ban .num {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.num li {
  width: 28px;
  height: 4px;
  background: #93808c;
  margin: 0 3px;
  cursor: pointer;
  float: left;
}

.ban .num li.on {
  width: 28px;
  height: 4px;
  background: $base-color;
}

/*/ 按钮 /*/

.banner .btn {
  height: 26px;
  border-radius: 50%;
  position: absolute;
  top: -29px;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
}

.banner .btn_l {
  left: 0px;
}

.banner .btn_r {
  right: 0px;
}

.ban .banner .wntj-cp .text {
  width: 120px;
  float: left;
  text-align: left;
}

.ban .banner .wntj-cp .text p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  text-indent: 10px;
}

.ban .banner .wntj-cp .text p.name {
  font-size: 18px;
  font-weight: bold;
}

.ban .banner .wntj-cp .text p.gg {
  margin-top: 10px;
  font-size: 14px;
}

.ban .banner .wntj-cp .text p.money {
  margin-top: 30px;
  color: #ff1919;
  height: 24px;
  line-height: 24px;
}

.ban .banner .wntj-cp .text p.money span {
  font-weight: bold;
  color: #ff1919;
  font-size: 18px;
}

#wntj .banner {
  width: 1200px;
  height: 314px;
  border: 1px solid #e5e5e5;
  overflow: visible;
  position: relative;
  margin: 0 auto;
}

#wntj .banner .img li {
  width: 1200px;
  height: 314px;
  padding: 20px 0;
  float: left;
  text-align: center;
}

#wntj .ban .banner a .wntj-cp {
  width: 240px;
  height: 274px;
  border-left: 1px solid #e5e5e5;
  float: left;
}

#wntj .ban .banner a:first-child .wntj-cp {
  border-left: none;
}

#wntj .img_box {
  width: 100%;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.thisLi {
  padding-bottom: 10px;
}

#wntj .img_box img {
  width: 200px;
  height: 200px;
}

#wntj .wntj-cp p {
  overflow: hidden;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 8px auto 0 auto;
  text-align: center;
  color: #000;
  font-size: 16px;
  height: 18px;
  line-height: 18px;
}

#wntj p.gg {
  color: #666;
  height: 14px;
  line-height: 14px;
  font-family: "SourceHanSansSC", "Microsoft YaHei", sans-serif;
}

#wntj p.money {
  color: #ff1919;
  font-size: 16px;
}

#wntj p.login {
  font-size: 14px !important;
}

#xpsj .fr a:hover {
  color: $base-color;
}

#xpsj .fr a:hover i.myicon {
  background-position-x: -154px;
}

#wntj .banner .btn_l {
  right: 34px;
  left: auto;
}

#wntj .banner .btn:hover i.myicon {
  background-position-y: -175px;
}

.section_title {
  height: 40px;
  line-height: 40px;
}

.section_title .biaoti {
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 5px;
}

.cuxiao {
  position: absolute;
  left: 15px;
  top: 190px;
}

.img-lookPc {
  left: 10px;
}

img.productImg {
  max-width: 100%;
  max-height: 100%;
}

#liebiao table td span.addBtn,
#goodsImgBox .addBtn {
  border-left: none !important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

#liebiao table td span.lessBtn,
#goodsImgBox .lessBtn {
  border-right: none !important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

#goodsImgBox {
  // margin-bottom: 50px;
}

.yjPriceBox {
  margin-left: 15px;
  color: #636161;
  font-size: 14px !important;
  font-weight: 500;
  text-decoration: line-through;

  .priceName {
    font-weight: normal;
    font-size: 15px;
  }
}

.inputBtnSpan {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lessBtn {
  position: relative;
  left: 0px;
}

.addBtn {
  position: relative;
  right: 0px;
}

.priceBox {
  font-weight: bold;
  color: $act-color;
  font-size: 14px !important;
}

.amoutBox {
  float: left;
  height: 25px;
  @include flexCenter;

  input {
    width: 50px;
    height: 25px;
    text-align: center;
    border: 1px solid #bfd1df;
  }

  .shoppingHas {
    background: #fad4c6;
    // background: #bfd1df70;
  }

  span {
    display: inline-block;
    width: 20px;
    height: 25px;
    text-align: center;
    line-height: 23px;
    font-size: 15px;
    border: 1px solid #bfd1df;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:first-child {
      margin-right: -1px;
    }

    &:last-child {
      margin-left: -1px;
    }
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(0.8);
  }

  75% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(0.6);
  }
}

@keyframes mymove {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(0.8);
  }

  75% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(0.6);
  }
}

.move_dot {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.move_dot img {
  -webkit-animation: 1.2s mymove ease-in-out;
  animation: 1.2s mymove ease-in-out;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.productQiugou {
  a {
    color: #ffffff;
  }
}

.cartHasThis {
  background: #fad4c6;
}
</style>
