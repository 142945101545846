var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"home-floors container_box"},[_vm._l((_vm.floorListBeforeData),function(item,index){return _c('div',{key:'floorIndexBefore' + index,staticClass:"floor-content",class:{ floorNavClass: (item.floor.title && item.floor.title !== '') }},[(item.floor.typeId === 2 && item.sort == 0)?_c('div',{staticClass:"goods-content"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floorDetailList &&
      _vm.luoboProductData(item.floorDetailList).length > 1
      )?_c('div',{staticClass:"tagIcon fr"},[_c('i',{staticClass:"prevIcon el-icon-caret-left",on:{"click":function($event){return _vm.prevLunBo(item)}}}),_c('i',{staticClass:"nextIcon el-icon-caret-right",on:{"click":function($event){return _vm.nextLunBo(item)}}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"goods-floor-content"},[(item.floorDetailList)?_c('ul',{ref:'productFloor' + item.floor.id,refInFor:true,style:(_vm.floorStyle(item)),attrs:{"id":'productFloor' + item.floor.id}},_vm._l((_vm.luoboProductData(item.floorDetailList)),function(itemC,indexC){return _c('li',{key:'floorItem1' + indexC,staticClass:"floor-good-data"},_vm._l((itemC),function(itemAC,indexAC){return _c('router-link',{key:'floorItemC1' + indexAC,attrs:{"target":"_blank","to":{ path: '/productDetail', query: { productId: itemAC.productId } }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemAC)),expression:"floorDetailImg(itemAC)"}],attrs:{"alt":"产品图片"}}),(itemAC.title && itemAC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemAC.title}},[_vm._v(" "+_vm._s(itemAC.title)+" ")]):(itemAC.product &&
      itemAC.product.name &&
      itemAC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemAC.product.name}},[_vm._v(" "+_vm._s(itemAC.product.name)+" "+_vm._s(itemAC.product.format)+" ")]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemAC.product))+" ")])])}),1)}),0):_vm._e()])]):(item.floor.typeId === 3 && item.sort == 0)?_c('div',{staticClass:"imgFloor"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floor.url && item.floor.url !== '')?_c('a',{staticClass:"activeMore",attrs:{"href":item.floor.url,"target":"_blank"}},[_vm._v(" 查看更多"),_c('i',{staticClass:"el-icon el-icon-arrow-right"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"imgListMain"},_vm._l((item.floorDetailList),function(itemC,indexC){return _c('a',{key:'imageFloorItem' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":""}})])}),0)]):(item.floor.typeId === 4 && item.sort == 0)?_c('div',{staticClass:"floor-box"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floor.url && item.floor.url !== '')?_c('a',{staticClass:"activeMore",attrs:{"href":item.floor.url,"target":"_blank"}},[_vm._v(" 查看更多"),_c('i',{staticClass:"el-icon el-icon-arrow-right"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"floor-child"},[_c('div',{staticClass:"img-box"},[_c('el-carousel',{attrs:{"trigger":"click","width":"250px","height":"400px"}},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      1
    )),function(itemC,indexC){return _c('el-carousel-item',{key:'floorCarousel' + indexC},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$imgPath + itemC.picUrl),expression:"$imgPath + itemC.picUrl"}],staticStyle:{"width":"100%"}})])])}),1)],1),_c('div',{staticClass:"goods-more"},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      2
    )),function(itemC,indexC){return _c('a',{key:'floorBottom' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":"产品图片"}}),(itemC.title && itemC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemC.title}},[_vm._v(" "+_vm._s(itemC.title)+" ")]):(itemC.product &&
      itemC.product.name &&
      itemC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemC.product.name}},[_vm._v(_vm._s(itemC.product.name))]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemC.product))+" ")])])}),0)]),_c('div',{staticClass:"floor-child"},[_c('div',{staticClass:"img-box"},[_c('el-carousel',{attrs:{"trigger":"click","width":"250px","height":"400px"}},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      3
    )),function(itemC,indexC){return _c('el-carousel-item',{key:'floorCarousel' + indexC},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$imgPath + itemC.picUrl),expression:"$imgPath + itemC.picUrl"}],staticStyle:{"width":"100%"}})])])}),1)],1),_c('div',{staticClass:"goods-more"},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      4
    )),function(itemC,indexC){return _c('a',{key:'floorBottom' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":"产品图片"}}),(itemC.title && itemC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemC.title}},[_vm._v(" "+_vm._s(itemC.title)+" ")]):(itemC.product &&
      itemC.product.name &&
      itemC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemC.product.name}},[_vm._v(_vm._s(itemC.product.name))]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemC.product))+" ")])])}),0)])])]):_vm._e()])}),_c('flash-sale-floor',{attrs:{"flashSaleDetail":_vm.flashSaleDetail,"flashSaleList":_vm.flashSaleList,"oldFlashSaleList":_vm.oldFlashSaleList}}),_vm._l((_vm.floorListData),function(item,index){return _c('div',{key:'floorIndex' + index,staticClass:"floor-content",class:{ floorNavClass: (item.floor.title && item.floor.title !== '') }},[(item.floor.typeId === 2 && item.sort > 0)?_c('div',{staticClass:"goods-content"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floorDetailList &&
      _vm.luoboProductData(item.floorDetailList).length > 1
      )?_c('div',{staticClass:"tagIcon fr"},[_c('i',{staticClass:"prevIcon el-icon-caret-left",on:{"click":function($event){return _vm.prevLunBo(item)}}}),_c('i',{staticClass:"nextIcon el-icon-caret-right",on:{"click":function($event){return _vm.nextLunBo(item)}}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"goods-floor-content"},[(item.floorDetailList)?_c('ul',{ref:'productFloor' + item.floor.id,refInFor:true,style:(_vm.floorStyle(item)),attrs:{"id":'productFloor' + item.floor.id}},_vm._l((_vm.luoboProductData(item.floorDetailList)),function(itemC,indexC){return _c('li',{key:'floorItem1' + indexC,staticClass:"floor-good-data"},_vm._l((itemC),function(itemAC,indexAC){return _c('router-link',{key:'floorItemC1' + indexAC,attrs:{"target":"_blank","to":{ name: 'productDetail', query: { productId: itemAC.productId } }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemAC)),expression:"floorDetailImg(itemAC)"}],attrs:{"alt":"产品图片"}}),(itemAC.title && itemAC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemAC.title}},[_vm._v(" "+_vm._s(itemAC.title)+" ")]):(itemAC.product &&
      itemAC.product.name &&
      itemAC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemAC.product.name}},[_vm._v(" "+_vm._s(itemAC.product.name)+" "+_vm._s(itemAC.product.format)+" ")]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemAC.product))+" ")])])}),1)}),0):_vm._e()])]):(item.floor.typeId === 3 && item.sort > 0)?_c('div',{staticClass:"imgFloor"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floor.url && item.floor.url !== '')?_c('a',{staticClass:"activeMore",attrs:{"href":item.floor.url,"target":"_blank"}},[_vm._v(" 查看更多"),_c('i',{staticClass:"el-icon el-icon-arrow-right"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"imgListMain"},_vm._l((item.floorDetailList),function(itemC,indexC){return _c('a',{key:'imageFloorItem' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":""}})])}),0)]):(item.floor.typeId === 4 && item.sort > 0)?_c('div',{staticClass:"floor-box"},[(item.floor.title && item.floor.title !== '')?_c('div',{staticClass:"header-title",style:(item.floor.titleThemeColor
      ? ("color:" + (item.floor.titleThemeColor))
      : '')},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"leftbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, #ffffff, " + (item.floor.titleThemeColor) + ");")
      : '')}),_c('span',[_vm._v(_vm._s(item.floor.title))]),_c('span',{staticClass:"rightbg",style:(item.floor.titleThemeColor
      ? ("background-image: linear-gradient(to right, " + (item.floor.titleThemeColor) + ", #ffffff);")
      : '')})]),(item.floor.timeWarnFlag &&
      item.floor.timeWarnFlag === 1 &&
      item.floor.djsShow
      )?_c('div',{staticClass:"xsqg-content fl",style:(("color: " + (item.floor.titleThemeColor)))},[_vm._v(" "+_vm._s(item.floor.djsText)+" "),(item.floor.suT !== '00')?_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suT ? item.floor.suT : "00"))]):_vm._e(),(item.floor.suT !== '00')?_c('span',[_vm._v("天")]):_vm._e(),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suH ? item.floor.suH : "00"))]),_c('span',[_vm._v("时")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suF ? item.floor.suF : "00"))]),_c('span',[_vm._v("分")]),_c('i',{style:(("background-color: " + (item.floor.titleThemeColor)))},[_vm._v(_vm._s(item.floor.suM ? item.floor.suM : "00"))]),_c('span',[_vm._v("秒")])]):_vm._e(),(item.floor.url && item.floor.url !== '')?_c('a',{staticClass:"activeMore",attrs:{"href":item.floor.url,"target":"_blank"}},[_vm._v(" 查看更多"),_c('i',{staticClass:"el-icon el-icon-arrow-right"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"floor-content-key"},[_c('div',{staticClass:"floor-child"},[_c('div',{staticClass:"img-box"},[_c('el-carousel',{attrs:{"trigger":"click","width":"250px","height":"400px"}},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      1
    )),function(itemC,indexC){return _c('el-carousel-item',{key:'floorCarousel' + indexC},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$imgPath + itemC.picUrl),expression:"$imgPath + itemC.picUrl"}],staticStyle:{"width":"100%"}})])])}),1)],1),_c('div',{staticClass:"goods-more"},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      2
    )),function(itemC,indexC){return _c('a',{key:'floorBottom' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":"产品图片"}}),(itemC.title && itemC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemC.title}},[_vm._v(" "+_vm._s(itemC.title)+" ")]):(itemC.product &&
      itemC.product.name &&
      itemC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemC.product.name}},[_vm._v(_vm._s(itemC.product.name))]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemC.product))+" ")])])}),0)]),_c('div',{staticClass:"floor-child"},[_c('div',{staticClass:"img-box"},[_c('el-carousel',{attrs:{"trigger":"click","width":"250px","height":"400px"}},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      3
    )),function(itemC,indexC){return _c('el-carousel-item',{key:'floorCarousel' + indexC},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$imgPath + itemC.picUrl),expression:"$imgPath + itemC.picUrl"}],staticStyle:{"width":"100%"}})])])}),1)],1),_c('div',{staticClass:"goods-more"},_vm._l((_vm.detailAreaData(
      item.floorDetailList,
      4
    )),function(itemC,indexC){return _c('a',{key:'floorBottom' + indexC,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.gotoUrl(itemC)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.floorDetailImg(itemC)),expression:"floorDetailImg(itemC)"}],attrs:{"alt":"产品图片"}}),(itemC.title && itemC.title !== '')?_c('p',{staticClass:"productData",attrs:{"title":itemC.title}},[_vm._v(" "+_vm._s(itemC.title)+" ")]):(itemC.product &&
      itemC.product.name &&
      itemC.product.name !== ''
      )?_c('p',{staticClass:"productData",attrs:{"title":itemC.product.name}},[_vm._v(_vm._s(itemC.product.name))]):_vm._e(),_c('p',{staticClass:"productPrice"},[_vm._v(" "+_vm._s(_vm.paroductPriceShow(itemC.product))+" ")])])}),0)])])]):_vm._e()])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.floorLeftNavShow),expression:"floorLeftNavShow"}],attrs:{"id":"fixedNavBar"}},[_c('div',{staticClass:"fixedLeftNavBar"},[_c('div',{staticClass:"fixedNavBar_title",on:{"click":function($event){_vm.changeShowLeftNavStatus = !_vm.changeShowLeftNavStatus}}},[_vm._v(" "+_vm._s(_vm.changeShowLeftNavStatus ? '收起导航' : '展开导航')+" ")]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.changeShowLeftNavStatus),expression:"changeShowLeftNavStatus"}]},_vm._l((_vm.floorNavList),function(item,index){return _c('li',{key:'leftNav' + index,staticClass:"leftLiNav",class:{ active: _vm.leftLiActive === index }},[_c('a',{on:{"click":function($event){return _vm.setFloorNavMountClick(index)}}},[_vm._v(_vm._s(item))])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }