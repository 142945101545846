<template>
  <div class="horseRaceLampBox">
    <div class="activeContainer">
      <div class="rightActBox">
        <div class="shanDeng" :class="shanDengTaggle ? 'shanDeng1' : 'shanDeng2'" id="deng">
          <div id="luck" v-if="activityInfo.length > 0">
            <table>
              <!--第一行-->
              <tr>
                <td :class="{ active: index == 0 }" class="luck-unit luck-unit-0">
                  <img :src="giftImgIcon(activityInfo[0])" :alt="giftImgAlt(activityInfo[0])">
                </td>
                <td :class="{ active: index == 1 }" class="luck-unit luck-unit-1">
                  <img :src="giftImgIcon(activityInfo[1])" :alt="giftImgAlt(activityInfo[1])">
                </td>
                <td :class="{ active: index == 2 }" class="luck-unit luck-unit-2">
                  <img :src="giftImgIcon(activityInfo[2])" :alt="giftImgAlt(activityInfo[2])">
                </td>
              </tr>
              <!--第二行-->
              <tr>
                <td :class="{ active: index == 7 }" class="luck-unit luck-unit-7">
                  <img :src="giftImgIcon(activityInfo[7])" :alt="giftImgAlt(activityInfo[7])">
                </td>
                <td v-if="click == 0" rowspan="1" colspan="1" :class="{ cjBtnDom: btnActive }" @click="buttonClick()"
                  class="cjBtn" id="btn"></td>
                <td v-else rowspan="1" colspan="1" class="cjBtn cjBtnDomDis" id="btn"></td>
                <td :class="{ active: index == 3 }" class="luck-unit luck-unit-3">
                  <img :src="giftImgIcon(activityInfo[3])" :alt="giftImgAlt(activityInfo[3])">
                </td>
              </tr>
              <!--第三行-->
              <tr>
                <td :class="{ active: index == 6 }" class="luck-unit luck-unit-6">
                  <img :src="giftImgIcon(activityInfo[6])" :alt="giftImgAlt(activityInfo[6])">
                </td>
                <td :class="{ active: index == 5 }" class="luck-unit luck-unit-5">
                  <img :src="giftImgIcon(activityInfo[5])" :alt="giftImgAlt(activityInfo[5])">
                </td>
                <td :class="{ active: index == 4 }" class="luck-unit luck-unit-4">
                  <img :src="giftImgIcon(activityInfo[4])" :alt="giftImgAlt(activityInfo[4])">
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from '../../api/manage'
export default {
  name: 'HorseRaceLamp',
  props: ['activityData'],
  data() {
    return {
      useTicket: 0,
      isGamble: 1,
      loading: 0,
      ticketData: [], // 活动奖券数据
      accountId: '', // 用户账户id
      // 抽奖动画
      index: -1, // 当前转动到哪个位置，起点位置
      count: 8, // 总共有多少个位置
      timer: 0, // setTimeout的ID，用clearTimeout清除
      speed: 20, // 初始转动速度
      times: 0, // 转动次数
      cycle: 50, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      prize: -1, // 中奖位置

      shandengJsq: '', // 闪灯计时器
      click: false, // 是否已经点击了按钮
      btnActive: false, // 按钮按下去动画操作
      shanDengTaggle: true, // 闪灯切换
      taggletMain: false, // 弹出层显示隐藏效果
      userGiftData: '' // 最终抽到的礼品数据
    }
  },
  computed: {
    activityInfo() {
      return this.activityData.activityGambleDetailList
    },
    residualTicket() {
      return this.activityData.ticketNum
    }
  },
  methods: {
    // 抽奖按钮点击
    buttonClick: function () {
      let _this = this
      let isGamble = this.isGamble
      if (isGamble) {
        // 判断当前是否可点击
        let click = this.click
        // 按钮按下弹起效果
        this.btnActive = true
        setTimeout(function () {
          _this.btnActive = false
        }, 200)
        let ticketNum = this.residualTicket
        // 如果click为true  这正在转动  不可点击
        if (click || ticketNum === 0) {
          if (ticketNum === 0) {
            _this.$alert('抱歉,您当前抽奖次数为0，快去获取抽奖次数吧!', '提示信息:', { type: 'waring' })
          }
          return false
        } else {
          _this.click = true
          // 初始转速为每秒动10次
          _this.speed = 100
          // 抽奖动画操作开始
          _this.zhuangdongAgain()
        }
      } else {
        this.$alert('很遗憾，活动已结束！', '提示信息', { type: 'error' })
      }

    },
    // 转动跑马灯
    zhuangdongAgain() {
      let _this = this
      // 移动位置+1操作
      this.times += 1
      // 转到最后回到初始位置
      this.roll()
      // 如果转动的位置大于转动次数并且中奖位置等于当前位置停止按钮可重新按
      if (_this.times > _this.cycle + 10 && _this.prize === _this.index) {
        // 结束转动
        clearTimeout(_this.timer)
        // 显示中奖弹出层
        setTimeout(function (e) {
          _this.taggletMain = true
        }, 1000)
        // 中奖数值变为-1
        _this.prize = -1
        // 移动位置初始化
        _this.times = 0
        // 按钮变成可点击
        _this.click = false
      } else {
        if (_this.times < _this.cycle) { // 转动的位置小于指定转动的次数,转动速度逐渐加快
          _this.speed -= 10
        } else if (_this.times === _this.cycle) { // 达到转动位置时  请求后台客户中奖结果
          // 请求后台客户中奖信息
          _this.getGiftData()
        } else {
          // 当转动的位置大于最少转动次数 并且中奖的位置时第一个  或者是当前转动的下一个速度放慢
          if (_this.times > _this.cycle + 10 && ((_this.prize === 0 && _this.index === 7) || _this.prize === _this.index + 1)) {
            _this.speed += 110
          } else {
            _this.speed += 20
          }
        }
        // 当转动次数小于40微秒时,变为匀速转动
        if (_this.speed < 40) {
          _this.speed = 40
        }

        let speed = _this.speed
        _this.timer = setTimeout(function () {
          _this.zhuangdongAgain()
        }, speed)
      }
    },
    // 转动到最后位置回到初始位置
    roll() {
      let index = this.index
      let count = this.count
      index += 1
      // 判断转到最后回到初始位置
      if (index > count - 1) {
        index = 0
      }

      this.index = index
    },
    // 获取用户中奖信息
    getGiftData() {
      let _this = this
      let url = '/activityGamble/activityGamble/gamble'
      let activityId = this.activityData.id
      let data = {
        id: activityId
      }
      // 触发中奖活动信息动画
      getAction(url, data).then(res => {
        if (res.code === 200 && res.success) {
          let resData = res.result
          _this.residualTicket = parseInt(_this.residualTicket) - 1
          _this.prize = parseInt(resData.giftDeg) - 1;
          // 触发中奖活动信息动画
          _this.$emit('winningOpen', resData)
        }
      })
    },
    // 关闭礼品展示层
    closeMask() {
      this.taggletMain = false
    },
    // 返回礼品图片
    giftImg(item) {
      if (item.iconUrl === '' || typeof (item.iconUrl) === 'undefined') {
        return ''
      } else {
        return item.iconUrl
      }
    },
    // 返回礼品图标
    giftImgIcon(item) {
      if (item.iconUrl === '' || typeof (item.iconUrl) === 'undefined') {
        return ''
      } else {
        return item.iconUrl
      }
    },
    // 礼品提示
    giftImgAlt(item) {
      if (item.giftName === '' || typeof (item.giftName) === 'undefined') {
        return ''
      } else {
        return item.giftName
      }
    },
    // 抽奖旁边闪灯效果
    shangdengAgain() {
      let _this = this
      let num = 0
      this.shandengJsq = setInterval(function () {
        num++
        if (num % 2 === 0) {
          _this.shanDengTaggle = true
        } else {
          _this.shanDengTaggle = false
        }
      }, 500)
    }
  },
  destroyed() {
    let _this = this
    clearInterval(_this.shanDengTaggle)
    _this.zhuanPanRun = null
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/activity/horseRaceLamp.scss";
</style>
