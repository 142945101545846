<template>
  <div class="header-top-content container">
    <transition v-if="advertTopData && advertTopData.picUrl" name="el-zoom-in-top">
      <div v-show="sliderStatus" class="advert-box">
        <a :href="advertTopData.url" :style="`background-image: url(${advertTopData.picUrl})`" target="_blank"></a>
      </div>
    </transition>
    <div class="page-header-top container">
      <div class="container_box">
        <div class="user-info-data">
          <div class="left-container">
            <a class="web-home-route" href="/">
              <img class="icon" src="~@/assets/images/headerTop/home-icon.png" alt="">
              <span>{{ $configOptions.appName}}首页</span>
            </a>
            <div v-if="loginState">

              <div @mouseenter="showUserCardStatus = true" @mouseleave="showUserCardStatus = false"
                class="main-left-content">
                <p class="username">{{ userInfo.name }}</p>
                <span v-if="!showUserCardStatus" style="margin-left: 5px;" class="el-icon-arrow-down"></span>
                <span v-else style="margin-left: 5px;" class="el-icon-arrow-up"></span>
              </div>
              <div @mouseenter="showUserCardStatus = true" @mouseleave="showUserCardStatus = false"
                v-show="showUserCardStatus" :class="showUserCardStatus ? 'user-on' : 'user-off'"
                class="user-data-content">
                <div class="customer-act">
                  <div class="cus-data">
                    <img class="user-img" :src="`${$configOptions.resourcePath}/config/logo/logo.png`" alt="用户头像">
                    <div class="cus-content">
                      <p class="name">{{ userInfo.name }}</p>
                      <a v-if="loginState" href="javascript:;" @click="Logout" class="logout">退出</a>
                    </div>
                  </div>
                  <div class="cus-shortcut-act">

                    <router-link to="/myAccount">会员中心</router-link>

                    <router-link to="/ticketList">优惠券<span class="act"></span></router-link>
                    <router-link to="/orderList?state=0">我的订单</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!loginState" class="loginTipMain main-left-content">
              <p class="loginLink">
                <router-link to="/login"> 登录 </router-link>
              </p>
              <p class="register">
                <router-link to="/register">注册</router-link>
              </p>
            </div>
          </div>
          <div class="header-right-act">
            <div class="user-act-content">
              <div class="act-item">
                <router-link to="/orderList?state=0">我的订单</router-link>
              </div>
              <div class="act-item">
                <a href="javascript:;">
                  <el-dropdown trigger="hover">
                    <span class="el-dropdown-link">
                      个人中心<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <router-link to="/myAccount">
                        <el-dropdown-item>我的账户</el-dropdown-item>
                      </router-link>
                      <router-link to="/collect">
                        <el-dropdown-item>我的收藏</el-dropdown-item>
                      </router-link>
                      <router-link to="/wantToBuyProduct">
                        <el-dropdown-item>商品求购</el-dropdown-item>
                      </router-link>
                    </el-dropdown-menu>
                  </el-dropdown>
                </a>
              </div>
              <div class="act-item">
                <a href="javascript:;">
                  <el-dropdown trigger="hover">
                    <span class="el-dropdown-link">
                      客户服务<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">

                      <router-link to="/articleInfo">
                        <el-dropdown-item>帮助中心</el-dropdown-item>
                      </router-link>
                      <router-link to="/feedback">
                        <el-dropdown-item>投诉建议</el-dropdown-item>
                      </router-link>
                    </el-dropdown-menu>
                  </el-dropdown>
                </a>
              </div>
              <div class="act-item">
                <router-link to="/articleInfo">
                  <i class="el-icon-mobile"></i>
                  掌上{{$configOptions.appName}}
                  <div class="phoneEcode">
                    <!-- <img src="../../assets/images/headerTop/appDownload.png" alt="手机端二维码" /> -->
                    <img v-if="companyConfigData.appDownloadImgUrl" class="ercode" :src="companyConfigData.appDownloadImgUrl" alt="手机端二维码" />
                    <img v-else class="ercode" :src="$configOptions.resourcePath + '/config/qr-code/app-download.png'" alt="手机端二维码" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getUserInfo } from "../../assets/js/common/common";
import { getAction } from "../../api/manage";
export default {
  name: "HeaderTop",
  data() {
    return {
      ecodeShow: false,
      userName: "",
      companyName: "",
      sliderStatus: true,
      userInfo: null,
      loginState: false,
      advertImg: "",
      advertUrl: "",
      showUserCardStatus: false, // 用户信息卡片显示状态
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
      this.userInfo = userInfo;
    } else {
      this.loginState = false;
    }
    let router = this.$route.path;
    if (router === "/" || router === "/index" || router === "/productList" || router === "/productDetail" || router === "/specialArea") {
      this.getAdertList();
    }
  },
  computed: {
    ...mapGetters(["advertTopData", "salemanToken", "companyConfigData"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    getAdertList() {
      let _this = this;
      let data = {
        type: 1,
      };
      let url = "/homeShow/ad/list";
      getAction(url, data).then((res) => {
        if (
          res.code === 200 &&
          res.success &&
          res.result &&
          res.result.length > 0
        ) {
          let result = res.result;
          _this.$store.commit("ADVERT_TOP_DATA", result[0]);
        } else {
          _this.$store.commit("ADVERT_TOP_DATA", null);
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      let userInfo = getUserInfo();
      if (userInfo) {
        this.loginState = true;
        this.userInfo = userInfo;
      } else {
        this.loginState = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/headerTop";
</style>