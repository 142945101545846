<template>
  <div class="flash-sale-zone">
    <div class="banner">
      <img src="~@/assets/images/activity/flash-sale/flash-sale-banner.png" alt="秒杀专区">
    </div>
    <div class="container">
      <div class="activity-header">
        <div class="flash-time-header clear">
          <div v-for="(item, index) of 5" class="time-tab fl" :key="index" :class="{ act: index === 0 }">
            <p>08:00-09:00</p>
            <span class="status">
              已结束
            </span>
          </div>
        </div>
        <div class="more">
          <p>更多 <span class="right-icon"></span></p>
        </div>
      </div>
      <div class="activity-goods-list">
        <activity-goods/>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityGoods from '../../components/product/ActivityGoods.vue'
export default {
  components: {ActivityGoods },
  name: 'flash-sale',
  data() {
    return {

    }
  },
  methods: {

  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
.flash-sale-zone {
  background-color: #fe5737;

  .banner {
    min-width: 1190px;

    // border-top: 3px solid #fe5737;
    img {
      min-width: 1190px;
      max-width: 100%;
      height: auto;
    }
  }

  .container {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .activity-header {
      width: 1190px;
      height: 60px;
      margin: 10px auto;
      background: #ffffff;

      .flash-time-header {
        width: 1120px;
        float: left;
        height: 60px;
        overflow: hidden;

        .clear {
          &:after {
            display: block;
            content: '';
            clear: both;
          }
        }

        .time-tab {
          float: left;
          width: 280px;
          height: 60px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: #E1060660;
          }
          p {
            font-size: 18px;
          }

          .status {
            display: inline-block;
            height: 30px;
            line-height: 28px;
            border-radius: 10px;
            font-size: 17px;
            background-color: #ffffff;
            color: #585858;
            padding: 0 10px;
            border: 1px solid #DCDCDC;
          }

          &.act {
            background-color: #E10606;
            color: #ffffff;

            &.status {
              border: 1px solid #E10606;
              background-color: #ffffff;
              color: #E10606;
            }
          }
        }
      }

      .more {
        float: left;
        width: 70px;
        height: 60px;
        line-height: 60px;
        color: #E10606;
        text-align: center;
        padding-left: 10px;
        box-shadow: -8px 0 8px #58585850;
        cursor: pointer;
        &:hover {
          opacity: .6;
        }
        p {
          font-size: 16px;
          .right-icon {
            margin-bottom: -3px;
            display: inline-block;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-left: 8px solid #E10606;
          }
        }
      }
    }
  }
}
</style>