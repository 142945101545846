<template>
  <div class="tableListBox">
    <div class="listBox">
      <ul v-for="(item, index) of productList"
        :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }" :key="'productLi' + index">
        <li v-if="item.inventoryList && item.inventoryList.length > 0" class="activity">
          <el-tooltip v-if="
            item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
          " class="item" effect="dark" content="该产品为秒杀产品" placement="right">
            <p class="tip1">秒杀</p>
          </el-tooltip>

          <el-tooltip v-else-if="
            item.inventoryList[0].newCustomerPriceFlag &&
            item.inventoryList[0].newCustomerPriceFlag == '1'
          " class="item" effect="dark" content="新用户特价产品" placement="right">
            <p class="tip3">特价</p>
          </el-tooltip>

          <el-tooltip v-else-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
            class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="right">
            <p class="tip3">促销</p>
          </el-tooltip>
          <p class="tip2" v-else-if="
            item.inventoryList[0].priceTitle &&
            item.inventoryList[0].priceTitle !== ''
          ">
            {{ item.inventoryList[0].priceTitle }}
          </p>

          <el-tooltip v-else-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
            placement="right">
            <p class="tip4">近效期</p>
          </el-tooltip>
        </li>
        <li class="activity" v-else>
          <p class=""></p>
        </li>
        <li class="name">
          <img v-if="item.picUrl && item.picUrl !== ''" class="imgIcon" src="@/assets/images/product/list/proImg.png"
            alt="产品图片" />
          <img v-else class="imgIcon" src="@/assets/images/product/list/noProImg.png" alt="产品图片" />
          <router-link :to="{
            path: '/productDetail',
            query: { productId: item.productId },
          }" :title="item.name" target="_blank">
            {{ item.name }}
          </router-link>
          <div v-if="item.picUrl && item.picUrl !== ''" class="productImg">
            <span class="listImgBox">
              <!-- 查看专区产品遮罩图 -->
              <img v-if="specialAreaMaskImg && specialAreaMaskImg !== ''" :src="specialAreaMaskImg" class="maskImg"
                alt="产品遮罩图" />
              <!-- 查看产品标签遮罩图 -->
              <img v-else-if="
                item.productLabelList && item.productLabelList.length > 0 && prolabelMaskImg(item.productLabelList) !== ''
              " :src="prolabelMaskImg(item.productLabelList)" class="maskImg" alt="产品遮罩图" />
              <img :src="productImg(item)" />
            </span>
          </div>
        </li>
        <li :title="item.format" class="format">
          {{ (item.format && item.format !== '') ? item.format : "暂无信息" }}
        </li>
        <li class="unit">
          {{ item.unit ? item.unit : "无" }}
        </li>
        <li class="manufacturer">{{ testReturnData(item.manufacturer) }}</li>
        <li class="validity">
          <div v-if="
            item.inventoryList &&
            item.inventoryList.length > 0 &&
            item.inventoryList[0].endDate &&
            item.inventoryList[0].endDate !== ''
          ">
            {{
              item.inventoryList[0].endDate &&
              item.inventoryList[0].endDate !== ""
              ? item.inventoryList[0].endDate
              : ""
            }}
          </div>
          <div v-else>
            {{ loginState ? "----/--/--" : "会员可见" }}
          </div>
        </li>
        <li class="package">{{ item.mediumPkgSize }}/{{ item.bigPkgSize }}</li>
        <li class="price">
          {{ item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1' ? '请联系开票员' :
            showPrice(item) }}
        </li>
        <li v-if="false" class="discountPrice">
          {{
            item.inventoryList &&
            item.inventoryList.length > 0 &&
            item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ""
            ? "￥" + item.inventoryList[0].discountPrice
            : "暂无优惠"
          }}
        </li>

        <li v-if="loginState" class="discountPrice">
          <div v-if="item.inventoryList && item.inventoryList.length > 0" class="fhsj" :style="
            item.inventoryList[0].deliveryTime === '24'
              ? 'color:#009944'
              : 'color:#18a97b'
          ">
            <span class="fhsjSpan">{{
              item.inventoryList[0].deliveryTime
            }}</span>
          </div>
        </li>
        <li v-else class="discountPrice">
          <div class="fhsj" style="color:#009944">
            <span class="fhsjSpan"></span>
          </div>
        </li>
        <li v-if="loginState" class="inventory">
          {{ showInventoryText(item.inventoryNum, item) }}
        </li>
        <li v-else class="inventory">
          会员可见
        </li>

        <li v-if="loginState" class="amount">
          <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'" class="amoutBox">
            <span @click="lessNum(item.inventoryList[0], item)">-</span>
            <input :class="{
              shoppingHas:
                item.inventoryList[0].shoppingCartAmount &&
                item.inventoryList[0].shoppingCartAmount > 0,
            }" :value="
  item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)" oninput="value=value.replace(/[^0-9.]/g,'')"
              @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
              @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
            <span @click="addNum(item.inventoryList[0], item)">+</span>
          </div>
          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0'" class="amoutBox">
            请联系开票员订货
          </div>
          <div v-else class="amoutBox">
            暂无库存
          </div>
        </li>
        <li v-else class="amount">
          <div class="amoutBox">
            <span>-</span>
            <input oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
            <span>+</span>
          </div>
        </li>

        <li v-if="loginState" class="operation actioveBox">
          <a v-if="
            item.inventoryList &&
            item.inventoryList.length > 0
          " href="javascript:;">
            <img @click="jionShopCart(item.inventoryList[0], item, $event)"
              src="@/assets/images/product/list/jionShop.png" alt="" />
          </a>
          <router-link v-else class="qiugou" :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
            <img src="@/assets/images/product/list/search.png" alt="" />
            找货
          </router-link>
        </li>
        <li v-else class="operation actioveBox">
          <router-link to="/login">
            <img src="@/assets/images/product/list/jionShop.png" alt="" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  name: "TableProList",
  props: ["productList", 'specialAreaMaskImg'],
  mixins: [ProductMixin],
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/product/list/list";
</style>