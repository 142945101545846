var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footerContent container"},[_vm._m(0),_c('div',{staticClass:"common-footer container_box"},[_c('div',{staticClass:"footer-code"},[_c('div',{staticClass:"weixinCode"},[(
            _vm.companyConfigData &&
            _vm.companyConfigData.wechatDownloadImgUrl &&
            _vm.companyConfigData.wechatDownloadImgUrl !== ''
          )?_c('img',{attrs:{"src":_vm.companyConfigData.wechatDownloadImgUrl,"alt":"关注微信公众号"}}):_c('img',{attrs:{"src":_vm.$configOptions.resourcePath + '/config/qr-code/wx_erweima.jpg',"alt":"关注微信公众号"}}),_c('p',[_vm._v("关注微信公众号")])]),_c('div',{staticClass:"appCode"},[(
          _vm.companyConfigData &&
          _vm.companyConfigData.appDownloadImgUrl &&
          _vm.companyConfigData.appDownloadImgUrl !== ''
        )?_c('img',{attrs:{"src":_vm.companyConfigData.appDownloadImgUrl,"alt":""}}):_c('img',{attrs:{"src":_vm.$configOptions.resourcePath + '/config/qr-code/app-download.png',"alt":"关注微信公众号"}}),_c('p',[_vm._v("移动端APP下载")])])]),_c('div',{staticClass:"footer-cert-list"},_vm._l((_vm.articleList),function(item,index){return _c('div',{key:'articleItem' + index,staticClass:"cert-i"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"actList"},_vm._l((item.noticeDetailList),function(itemC,indexC){return _c('router-link',{key:'noticeDetail' + indexC,attrs:{"to":{
              name: 'articleInfo',
              query: { id: itemC.id },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(itemC.title))])}),1)])}),0)]),_c('div',{staticClass:"copy-right container_box"},[(
        _vm.companyConfigData &&
        _vm.companyConfigData.recordNoList &&
        _vm.companyConfigData.recordNoList.length > 0
      )?_c('p',{staticClass:"record-no"},_vm._l((_vm.companyConfigData.recordNoList),function(item,index){return _c('span',{key:'recordNo' + index},[_vm._v(" "+_vm._s(item.name)+"： "),(item.url && item.url !== '')?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.value)+" ")]):_c('a',{attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$configOptions.copyrightValue)+" "),_c('a',{attrs:{"href":_vm.$configOptions.companyUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$configOptions.copyrightName))]),_vm._v(" 版权所有 ")]),_c('p',[_vm._v(" 本网站未发布毒性药品、麻醉药品、精神药品、放射性药品、戒毒药品和医疗机构制剂的产品信息 ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footerTop"},[_c('div',{staticClass:"container_box"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/footer/economize.png"),"alt":"省"}}),_vm._v(" 品类齐全 省心购物 ")]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/footer/excellent.png"),"alt":"优"}}),_vm._v(" 品质保证 省心售后 ")]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/footer/fast.png"),"alt":"快"}}),_vm._v(" 物流直配 轻松到家 ")]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/footer/integrity.png"),"alt":"信"}}),_vm._v(" 诚信经营 值得信赖 ")])])])}]

export { render, staticRenderFns }