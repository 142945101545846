import {login, logout, phoneLogin,ywyLogin} from "@/api/login"
import {SHOPPING_ACCESS_TOKEN,USER_INFO,SALEMAN_INFO} from "@/store/mutation-types"
import router from '@/router/index'
import store from '@/store/index'
import {getUserCompany} from "@/api/login";
import Vue from "vue";

const user = {
  state: {
    token: '',
    salemanToken: '',
    salemanInfo: {},
    userName: '',
    info: {},
    loginState: false,
    errorData: "", // 报错信息提示需要跳转到登录页面
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SALEMAN_TOKEN: (state, token) => {
      state.salemanToken = token
    },
    SET_SALEMAN_INFO: (state, info) => {
      state.salemanInfo = info
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_LOGIN_STATE: (state, info) => {
      state.loginState = info
    },
    SET_ERROR_DATA: (state, info) => {
      state.errorData = info
    }
  },

  actions: {
    // 登录
    Login({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if(response.code == 200 && response.success){ 
            commit('SET_LOGIN_STATE', true)
          }
          resolve(response)
        }).catch(error => {
          console.log("失败")
          commit('SET_LOGIN_STATE', false)
          reject(error)
        })
      })
    },
    // 业务员登录
    YwyLogin({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        ywyLogin(userInfo).then(response => {
          if(response.code == 200 && response.success){ 
            commit('SET_LOGIN_STATE', true)
          }
          resolve(response)
        }).catch(error => {
          console.log("失败")
          commit('SET_LOGIN_STATE', false)
          reject(error)
        })
      })
    },
    //手机号登录
    PhoneLogin({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        phoneLogin(userInfo).then(response => {
          if (response.code == '200') {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出登录
    Logout({commit, state}) {
      return new Promise((resolve) => {
        let logoutToken = state.token;
        commit('SET_TOKEN', '')
        commit('SET_INFO', {})
        commit('SET_SALEMAN_TOKEN', '')
        commit('SET_SALEMAN_INFO', {})
        commit('SET_ERROR_DATA', '')
        commit('SET_LOGIN_STATE', false)
        commit('SET_LOGIN_STATE', false)
        store.dispatch('ClearShoppingCartCount')
        store.dispatch('ClearPendingOrderCount')
        store.dispatch('ClearCompanyConfigData')
        Vue.ls.remove(SHOPPING_ACCESS_TOKEN)
        Vue.ls.remove(USER_INFO)
        Vue.ls.remove(SALEMAN_INFO)
        Vue.ls.remove('hideAdvertStatus')
        Vue.ls.remove('articleListData')
        Vue.ls.remove('productShowType')
        Vue.ls.remove('companyNoticeList')
        Vue.ls.remove('companyConfigData')
        logout(logoutToken).then(() => {
          // 成功退出后  清空store里面的数据
          router.push('/login')
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    // 获取用户所属的公司列表
    GetUserCompanyList({commit, state}, token) {
      return new Promise((resolve) => {
        getUserCompany(token).then((res) => {
          resolve(res)
        }).catch((error) => {
          resolve(error)
        })
      })
    },
    // 登录报错的信息提示
    loginErrorData({ commit }, errorMsg) {
      commit('SET_ERROR_DATA', errorMsg)
    }
  }
}

export default user
