<template>
  <div class="walletBox">
    <div class="walletHeader">
      <div class="balanceTitle">
        <p class="myBalance">
          当前账户余额：<span>{{ balanceData.balanceMoney }}</span>元
        </p>
        <!-- <p class="coupons">
          当前药券：<span>{{ balanceData.ticketMoney }}</span>
        </p> -->
      </div>
      <div class="rule">
        余额充值可享受2.100%的药券返还，余额转入当日开始计算药券，每天24点以当前余额计算。药券和余额均可直接支付订单。
      </div>
    </div>
    <div class="domainList">
      <div class="tabbar">
        <p @click="changeWalletType(1)" :class="{ active: walletType == 1 }">变动记录</p>
        <p @click="changeWalletType(2)" :class="{ active: walletType == 2 }">充值</p>
        <p @click="changeWalletType(3)" :class="{ active: walletType == 3 }">提现</p>
      </div>
      <div class="tabbarContent">
        <!-- 变动记录 -->
        <div v-if="walletType == 1" class="changeRecord">
          <div class="changeRightBox">
            <div class="changeListBox">
              <ul class="title">
                <li class="time">时间</li>
                <li class="type">操作类型</li>
                <li class="explain">变动前金额</li>
                <li class="change">资金变动</li>
                <li class="changeAfter">变动后余额</li>
                <li class="remarks">备注</li>
              </ul>
              <div class="listMain" v-if="changeList.length > 0" v-loading="listLoading">
                <ul class="listContent" v-for="(item, index) of changeList" :key="'giftItem' + item.id + index">
                  <li class="time">{{ item.createTime }}</li>
                  <li class="type">{{ item.typeName }}</li>
                  <li class="explain">{{ item.originalMoney }}</li>
                  <li class="change">{{ item.money }}</li>
                  <li class="changeAfter">{{ item.afterMoney }}</li>
                  <li class="remarks">{{ item.remark }}</li>
                </ul>
              </div>
              <div v-else-if="changeList == 0" class="dataNone">
                <div class="dataImg "></div>
              </div>
            </div>
            <div class="pagination-bottom">
              <el-pagination background :hide-on-single-page="true" v-if="pageCount > 0"
                layout="prev, pager, next, jumper" :current-page="pageNo" @current-change="currentChange"
                :page-size="pageSize" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>

        <!-- 充值 -->
        <div v-else-if="walletType == 2" class="rechargeBox">
          <div class="headerBg"></div>
          <div class="ruleForm2">
            <el-form ref="ruleForm2" label-width="100px">
              <el-form-item class="valBox" label="充值金额" prop="val">
                <el-input placeholder="请输入充值金额" v-model="rechargeNumber"></el-input>
                <p class="tip">*最低充值1000元</p>
              </el-form-item>
            </el-form>
            <span @click="tipError('暂未开通，敬请期待！')" class="rechargeBtn">
              生成二维码
            </span>
            <div class="ercodeBox">
              <img :src="`${$configOptions.resourcePath}/config/qr-code/wx_erweima.jpg`" alt="">
            </div>
          </div>
        </div>
        <!-- 提现 -->
        <div v-else-if="walletType == 3" class="withDrawContent">
          <div class="headerBg"></div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
            <p class="title">提现金额</p>
            <el-form-item class="contact" label="" prop="withdrawalAmount">
              <el-input placeholder="请输入提现金额" v-model="ruleForm.withdrawalAmount"></el-input>
              <p class="tip">当前账户余额<span>{{ balanceData.balanceMoney }}</span>元</p>
            </el-form-item>
            <p class="title">银行信息</p>
            <el-form-item class="contact" label="" prop="bankName">
              <el-input placeholder="请输入开户行信息" v-model="ruleForm.bankName"></el-input>
              <p class="tip"> *请输入正确的银行卡开户行信息，如遗忘请联系银行客服确认后再输入。</p>
            </el-form-item>
            <el-form-item class="contact" label="" prop="bankCardNumber">
              <el-input placeholder="请输入银行卡号" v-model="ruleForm.bankCardNumber"></el-input>
              <p class="tip"> *请输入正确的银行卡号。</p>
            </el-form-item>
            <el-form-item class="contact" label="" prop="cardAccountName">
              <el-input placeholder="请输入户名" v-model="ruleForm.cardAccountName"></el-input>
              <p class="tip"> *请输入正确的银行卡对应的户名。</p>
            </el-form-item>
          </el-form>
          <span @click="tipError('暂未开通，敬请期待！')" class="submitBtn">提交申请</span>
          <div class="withdrawalList">
            <p class="title">提现记录</p>

            <div class="withdrawalRightBox">
              <div class="withdrawalListBox">
                <ul class="title">
                  <li class="time">时间</li>
                  <li class="txje">提现金额</li>
                  <li class="shsj">审核时间</li>
                  <li class="shjg">审核结果</li>
                  <li class="remarks">备注</li>
                </ul>
                <div class="listMain" v-if="withdrawalList.length > 0" v-loading="listLoading">
                  <ul class="listContent" v-for="(item, index) of withdrawalList" :key="'giftItem' + item.id + index">

                    <li class="time">时间</li>
                    <li class="txje">提现金额</li>
                    <li class="shsj">审核时间</li>
                    <li class="shjg">审核结果</li>
                    <li class="remarks">备注</li>
                  </ul>
                </div>
                <div v-else-if="withdrawalList == 0" class="dataNone">
                  <div class="dataImg "></div>
                </div>
              </div>
              <div class="pagination-bottom">
                <el-pagination background :hide-on-single-page="true" v-if="pageCount > 0"
                  layout="prev, pager, next, jumper" :current-page="pageNo" @current-change="currentChange"
                  :page-size="pageSize" :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from '../api/manage'
export default {
  name: 'MyWallet',
  data() {
    return {
      walletType: 3, // 1.变动记录 2.充值  3.提现
      listLoading: false,
      changeList: [], // 变动记录
      withdrawalList: [], // 提现记录
      ruleForm: {
        withdrawalAmount: "",
        bankName: '', // 开户行名称
        bankCardNumber: '', // 银行卡号
        cardAccountName: '' // 户名
      },
      rules: {},
      rechargeNumber: "",
      pageCount: 0,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      balanceData: {
        balanceMoney: 0,
        ticketMoney: 0
      }, // 余额药券数据
    }
  },
  created() {
    this.getCustomerBalance()
  },
  methods: {
    tipError(title) {
      this.$message({
        message: title,
        offset: 400,
        duration: 1000,
        type: "error",
      });
    },
    // 切换钱包选项卡
    changeWalletType(type) {
      this.walletType = type
      if (type == 1) {
        this.pageNo = 1;
        this.getCapitalList()
      }
    },
    // 获取用户资金变动数据
    async getCapitalList() {
      let _this = this
      this.listLoading = true
      let pageNo = this.pageNo
      let pageSize = this.pageSize
      let url = '/balance/customerBalanceRecord/list'
      let data = {
        pageNo: pageNo,
        pageSize: pageSize
      }
      // let rechargeDate = this.rechargeDate;
      // if (rechargeDate && rechargeDate !== '' && rechargeDate.length > 0) {
      //   let dateStart = moment(rechargeDate[0]).format('YYYY-MM-DD')
      //   let dateEnd = moment(rechargeDate[1]).format('YYYY-MM-DD')
      //   data.dateStart = dateStart + ' 00:00:00'
      //   data.dateEnd = dateEnd + ' 23:59:59'
      // }
      let res = await getAction(url, data);
      console.log("获取用户资金变动的数据为++++++", res)
      _this.listLoading = false
      if (res.code === 200 && res.success) {
        let resData = res.result
        _this.changeList = resData.records
        _this.pageNo = resData.current
        _this.totalCount = resData.total
      }
    },
    // 获取用户余额和药券数据
    async getCustomerBalance() {
      let _this = this
      let url = '/balance/customerBalance/query'
      let res = await getAction(url)
      if (res.code === 200 && res.success) {
        console.log("获取用户余额药券的数据为++++++", res)
        let resData = res.result
        if (resData && resData !== null) {

          _this.balanceData.balanceMoney = resData.balanceMoney
          _this.balanceData.ticketMoney = resData.ticketMoney
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/center/myWallet.scss"
</style>