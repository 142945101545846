<template>
  <div class="order-detail-cont">
    <div class="container_box">
      <div class="detail-header">
        <div class="order-status">
          <div class="order-now-status">
            <img
              v-if="saleOrder.orderStatus == '1'"
              src="~@/assets/images/center/dph.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '2'"
              src="~@/assets/images/center/dcl.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '3'"
              src="~@/assets/images/center/ywc.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '-1'"
              src="~@/assets/images/center/shdd.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '0'"
              src="~@/assets/images/center/qbdd.png"
              :alt="saleOrder.orderStatusName"
            />

            <span>{{ saleOrder.orderStatusName }}</span>
          </div>
          <p v-if="saleOrder.orderStatus == '1'">
            该订单处于待确认状态，请先进行订单确认！
          </p>
          <!-- <p>该订单包裹待配货，出库单号: FKLXSG03119894。</p> -->
        </div>
        <div class="order-header-info">
          <div class="card-header">
            <p class="order-code">
              订单号：<span>{{ saleOrder.id }}</span>
              <i
                :data-clipboard-text="saleOrder.id"
                @click="onCopyText('el-icon-document-copy')"
                class="el-icon-document-copy"
              ></i>
            </p>
            <p class="create-time">下单时间：{{ saleOrder.createTime }}</p>
          </div>
          <div class="card-cont">
            <div class="order-info">
              <div class="info-item-box">
                <p class="info-item">
                  <span class="info-label">支付方式:</span>
                  <span class="info-content">{{
                    saleOrder.feeType === 1 ? "线下支付" : "在线支付"
                  }}</span>
                </p>
                <p class="info-item">
                  <span class="info-label">创建时间:</span>
                  <span class="info-content">{{saleOrder.createTime}}</span>
                </p>
              </div>
              <div class="order-address info-item">
                <span class="info-label">配送地址:</span>
                <span class="info-content">{{
                  saleOrder.logisticsAddress
                    ? saleOrder.logisticsAddress
                    : "暂无地址信息"
                }}</span>
              </div>
            </div>
            <!-- <div v-if="saleOrder.logisticsStatus == '1'" class="logistics-info">
              <div class="package-code">
                <p>包裹号：FKLXSG03119894</p>
              </div>
              <div class="logistics-type">
                <span class="logistics-company">顺丰速运</span>
                <p class="line"></p>
                <p class="logistics-company">
                  <span>运单号：</span>
                  <span
                    >{{
                      saleOrder.logisticsWaybillId
                        ? saleOrder.logisticsWaybillId
                        : "暂无运单号信息"
                    }}
                    <i class="el-icon-document-copy"></i>
                  </span>
                </p>
              </div>
              <div class="logistics-node">
                <i class="el-icon-success"></i>
                <div class="node-info">
                  <p class="node-type">
                    <span class="node-status">已签收</span>
                    <span>2024-01-24 09:00:07</span>
                  </p>
                  <p>
                    您的包裹签收完成，感谢您在{{$configOptions.appName}}平台购物，期待再次为您服务
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="logistics-info">
              <div class="package-code">
                <p>配送方式：线下配送</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="stock-list">
        <div
          class="stock-content"
          v-for="(itemF, indexF) of companySaleOrderList"
          :key="indexF"
        >
          <!-- 商品列表 -->
          <div
            v-if="itemF.saleOrderItemList && itemF.saleOrderItemList.length > 0"
            class="store-info"
          >
            <div class="store-info-head">
              <div class="title">{{$configOptions.appName}}-{{ itemF.companyName }}商品清单</div>
              <div v-if="false" class="store-btns">
                <div class="head-btn">商品首营资料</div>
                <div class="head-btn">企业首营资料</div>
                <div class="head-btn">下载全部报告单</div>
                <div class="head-btn">导出明细</div>
              </div>
            </div>
            <div class="store-info-content">
              <div class="header">
                <span class="store-name">商品信息</span>
                <span style="width: 140px">单价</span>
                <span style="width: 100px">数量</span>
                <span style="width: 140px">优惠金额</span>
                <span style="width: 120px">实付金额</span>
                <span style="width: 140px; text-align: center">操作</span>
              </div>
              <!-- 商品列表 -->
              <div class="content">
                <div class="shop-page">
                  <div class="commodity-box">
                    <div
                      class="li"
                      v-for="(item, index) of itemF.saleOrderItemList"
                      :key="'sales-goods-' + item.id"
                    >
                      <div class="center shop-info" style="width: 410px">
                        <div class="picture">
                          <div class="el-image">
                            <img
                              v-lazy="productDataImg(item)"
                              class="el-image__inner"
                            />
                          </div>
                        </div>
                        <div class="commodity-content">
                          <div class="trade-name">{{ item.productName }}</div>
                          <div class="specifications">
                            {{ item.productFormat }} {{ item.productUnit }}
                          </div>
                          <div class="company">
                            {{ item.productManufacturer }}
                          </div>

                          <div class="bottom">
                            <span>批准文号:</span>
                            <span style="margin-right: 6px">{{
                              item.productLicense
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="center danjia" style="width: 140px">
                        <span class="unit">¥{{ item.salePrice }} </span>          
                        <span v-if="item.updatePrice && item.updatePrice !== ''" class="unit czj">储值价：￥{{ item.updatePrice }} </span>
                      </div>
                      <div class="center shuliang" style="width: 100px">
                        <span class="unit"> x{{ item.saleAmount }}</span>
                      </div>
                      <div class="center youhuijine" style="width: 140px">
                        <span class="unit"
                          >-¥{{
                            item.discountMoney ? item.discountMoney : "0.00"
                          }}</span
                        >
                      </div>
                      <div class="center shifujine" style="width: 120px">
                        <span class="unit">¥{{ item.confirmMoney }} </span>
                      </div>
                      <div
                        class="center caozuo"
                        @click="jionOneGoodsCart(item)"
                        style="width: 140px"
                      >
                        <span> 加入购物车 </span>
                        <div v-if="false" class="other">
                          <div class="other-btn">预览</div>
                          <div class="line"></div>
                          <div class="other-btn">下载报告单</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品缺货列表 -->
          <div
            v-if="
              itemF.saleOrderItemOosList &&
              itemF.saleOrderItemOosList.length > 0
            "
            class="store-info"
          >
            <div class="store-info-head">
              <div class="title">商品缺货列表</div>
            </div>
            <div class="store-info-content">
              <div class="header">
                <span class="store-name">商品信息</span>
                <span style="width: 140px">单价</span>
                <span style="width: 100px">数量</span>
                <span style="width: 140px">优惠金额</span>
                <span style="width: 120px">缺货金额</span>
                <span style="width: 140px; text-align: center">操作</span>
              </div>
              <!-- 商品列表 -->
              <div class="content">
                <div class="shop-page">
                  <div class="commodity-box">
                    <div
                      class="li"
                      v-for="(item, index) of itemF.saleOrderItemOosList"
                      :key="index"
                    >
                      <div class="center shop-info" style="width: 410px">
                        <div class="picture">
                          <div class="el-image">
                            <img
                              v-lazy="productDataImg(item)"
                              class="el-image__inner"
                            />
                          </div>
                        </div>
                        <div class="commodity-content">
                          <div class="trade-name">{{ item.productName }}</div>
                          <div class="specifications">
                            {{ item.productFormat }} {{ item.productUnit }}
                          </div>
                          <div class="company">
                            {{ item.productManufacturer }}
                          </div>

                          <div class="bottom">
                            <span>批准文号:</span>
                            <span style="margin-right: 6px">{{
                              item.productLicense
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="center danjia" style="width: 140px">
                        <span class="unit">¥{{ item.salePrice }} </span>
                      </div>
                      <div class="center shuliang" style="width: 100px">
                        <span class="unit"> x{{ item.oosAmount }}</span>
                      </div>
                      <div class="center youhuijine" style="width: 140px">
                        <span class="unit"
                          >-¥{{
                            item.discountMoney ? item.discountMoney : "0.00"
                          }}</span
                        >
                      </div>
                      <div class="center shifujine" style="width: 120px">
                        <span class="unit">¥{{ item.oosMoney }} </span>
                      </div>
                      <div
                        @click="shortageRegistration"
                        class="center caozuo"
                        style="width: 140px"
                      >
                        <span> 缺货登记 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="total-content">
          <div class="order-remark">订单备注：{{ saleOrder.remark }}</div>
          <div class="total-info">
            <div class="left flex">
              <span class="special">
                <!-- <span class="sr">总规格：1种</span> -->
                <span class="sr"
                  >订单商品品种：{{ saleOrderItemList.length }}个，</span
                >
                <span class="sl">商品原价</span>
              </span>
              <span class="sl">运费</span>
              <span class="sl">缺货金额</span>
              <span class="sl">优惠金额</span>
              <span class="sr">实付金额</span>
            </div>
            <div class="right flex">
              <span class="normal-price"
                >¥ {{ saleOrder.originalSaleMoney }}</span
              >
              <span class="normal-price"
                >¥
                {{
                  saleOrder.logisticsMoney ? saleOrder.logisticsMoney : "0.00"
                }}</span
              >
              <span class="normal-price">¥ {{ saleOrder.oosMoney }}</span>
              <span class="normal-price"
                >¥ {{ saleOrder.confirmDiscountMoney }}</span
              >
              <span class="other-price">¥ {{ saleOrder.payMoney }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="store-info">
        <div class="store-info-head">
          <div class="title">发票信息</div>
        </div>
        <div class="order-detail-invoice-data">
          <div class="invoice-wapper">
            <div
              class="electronic-invoice-issuance-completed invoice-common-show"
            >
              <div class="invoice-common-item">
                <span>发票状态：</span
                ><span style="color: rgb(51, 51, 51)">已开</span>
              </div>
              <div class="invoice-common-item">
                <span>发票形式：</span><span>电票</span>
              </div>
              <div class="invoice-common-item">
                <span>发票金额：</span><span>¥808.65</span>
              </div>
              <div class="invoice-common-item last-invoice-common-item">
                <div class="invoice-label">发票号码：</div>
                <div
                  class="show-invoice-number"
                  style="display: flex; flex-wrap: wrap"
                >
                  <div>
                    76903462
                    <span style="display: none">,</span>
                  </div>
                </div>
              </div>
              <div
                class="invoice-common-item invoice-common-item-btn"
                style="min-width: auto"
              >
                <span size="small" type="text" class="el-button-no-view-invoice"
                  >查看发票
                  <i class="el-icon-arrow-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="bottomHover"></div>
      <div class="bottom-hover" :class="{ fixed: fixedStatus }">
        <div class="bottom-btns">
          <!-- <div class="head-btn">
            评价
          </div> -->
          <div
            v-if="
              saleOrder.feeType == 2 &&
              saleOrder.status == 2 &&
              saleOrder.payStatus == 1
            "
            @click="navToApplyReturn"
            class="head-btn"
          >
            申请退款
          </div>
          <!---->
          <div
            v-if="saleOrder.invoiceUrl && saleOrder.invoiceUrl !== ''"
            @click="downloadInvoiceUrl"
            class="head-btn"
          >
            下载发票
          </div>
          <div @click="gobackShoppingCart" class="head-btn">返回购物车修改</div>
          <div @click="confirmOrderAction" class="head-btn">确认订单</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
export default {
  name: "ConfirmOrder",
  data() {
    return {
      saleOrderId: "",
      interestMoney: 0, // 优惠的金额
      companySaleOrderList: [], // 分仓的订单明细数据
      saleOrder: {}, // 订单头部数据
      customerAddress: {}, // 订单头部数据
      saleOrderItemList: [], //创建的产品列表数据
      saleOrderItemOosList: [], // 缺货列表的数据
      ticketMoneyFlag: false,
      ticketMoney: 0,
      confirmOrderStatus: false,
      orderSuccess: false,
      discountTicketList: [],
      shopLoading: false,
      fixedStatus: false,
      shoppingCartType: 0, // 添加购物车的类型
    };
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    let shoppingCartType = this.$route.query.shoppingCartType;
    if (shoppingCartType && shoppingCartType !== "") {
      this.shoppingCartType = parseInt(shoppingCartType);
    }
    this.saleOrderId = saleOrderId;
    if (saleOrderId) {
      this.getCustomerBalance();
      this.getConfirmOrderData();
    }
  },
  computed: {
    accmulPayorderMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let confirmDiscountMoney = this.saleOrder.confirmDiscountMoney
        ? this.saleOrder.confirmDiscountMoney
        : 0;
      let oosMoney = this.saleOrder.oosMoney ? this.saleOrder.oosMoney : 0;
      let ticketMoney =
        this.ticketMoneyFlag && this.ticketMoney ? this.ticketMoney : 0;
      let logisticsMoney = this.saleOrder.logisticsMoney
        ? this.saleOrder.logisticsMoney
        : 0;
      let plusMoney = accAdd(originalSaleMoney, logisticsMoney);
      let lessMoney = accAdd(confirmDiscountMoney, oosMoney);
      let lessMoney2 = accAdd(lessMoney, ticketMoney);
      let payOrderMoney = accAdd(plusMoney, -lessMoney2);
      if (payOrderMoney > 0) {
        return payOrderMoney;
      } else {
        return "0.00";
      }
    },
    renderTicketMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let confirmDiscountMoney = this.saleOrder.confirmDiscountMoney
        ? this.saleOrder.confirmDiscountMoney
        : 0;
      let oosMoney = this.saleOrder.oosMoney ? this.saleOrder.oosMoney : 0;
      let ticketMoney =
        this.ticketMoneyFlag && this.ticketMoney ? this.ticketMoney : 0;
      let logisticsMoney = this.saleOrder.logisticsMoney
        ? this.saleOrder.logisticsMoney
        : 0;
      let plusMoney = accAdd(originalSaleMoney, logisticsMoney);
      let lessMoney = accAdd(confirmDiscountMoney, oosMoney);
      let lessMoney2 = accAdd(lessMoney, ticketMoney);
      let payOrderMoney = accAdd(plusMoney, -lessMoney2); // 查看药券
      let ticketShowMoney = accAdd(plusMoney, -lessMoney); // 查看药券
      if (payOrderMoney > 0) {
        return ticketMoney;
      } else {
        return ticketShowMoney;
      }
    },
    originalOosMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let originalConfirmMoney = this.saleOrder.originalConfirmMoney
        ? this.saleOrder.originalConfirmMoney
        : 0;
      let originalOosMoney = accAdd(originalSaleMoney, -originalConfirmMoney);
      return originalOosMoney;
    },
  },
  methods: {
    shortageRegistration() {
      this.$router.push({ path: "/wantToBuyProduct" });
    },
    navToApplyReturn() {
      let saleOrderId = this.saleOrderId;
      this.$router.push({
        name: "applyReturn",
        query: {
          saleOrderId: saleOrderId,
        },
      });
    },
    downloadInvoiceUrl() {
      let invoiceUrl = this.saleOrder.invoiceUrl;
      window.open(invoiceUrl);
    },
    // 显示产品图片
    productDataImg(item) {
      if (item.productPic && item.productPic !== "") {
        let imgsArr = item.productPic.split(",");
        let imgSrc = this.$util.resizeImg(imgsArr[0], 200);
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    handleScroll() {
      const element = this.$refs.bottomHover; // 获取元素的DOM对象
      if (element) {
        const rect = element.getBoundingClientRect(); // 获取元素相关的位置信息
        const distanceToBottom = window.innerHeight - rect.bottom; // 计算距离页面底部的距离
        // console.log(distanceToBottom); // 输出距离页面底部的距离
        if (distanceToBottom < 0) {
          this.fixedStatus = true;
        } else {
          this.fixedStatus = false;
        }
      } else {
        // console.error('未能找到指定的元素');
      }
    },
    linkProDetail(item) {
      let text = this.$router.resolve({
        path: "productDetail",
        query: { productId: item.productId },
      });
      window.open(text.href, "_blank");
    },
    showCxInfo(cxInfo) {
      this.$alert(cxInfo ? cxInfo : "", "促销信息", {
        type: "info",
      });
    },
    // 展示商品缺货原因
    showErrorInfo(item) {
      this.$alert(
        item.errorInfo ? item.errorInfo : "缺货原因不明，请联系开票员！",
        "缺货原因",
        {
          type: "error",
        }
      );
    },
    // 获取产品批次效期的数据
    lookProductBatchData(item) {
      let url = "/saleOrder/saleOrderItemBatch/listBySaleOrderItemId";
      let data = {
        saleOrderItemId: item.id,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          if (resData && resData.length > 0) {
            let batchData = res.result[0];
            let productBatchData = `批次：${batchData.batchId}<br>批号：${batchData.batchNumber}<br>生产日期：${batchData.productDate}<br>有效期：${batchData.expireDate}`;
            this.$alert(productBatchData, "产品批次信息：", {
              dangerouslyUseHTMLString: true,
            });
          } else {
            this.$alert("暂无批次效期数据！", "产品批次信息：", {
              type: "error",
            });
          }
        }
      });
    },
    // 获取用户的余额和药券数据
    getCustomerBalance() {
      let _this = this;
      let url = "/balance/customerBalance/query";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          console.log("res", res);
          let resData = res.result;
          _this.ticketMoney = resData ? resData.ticketMoney : 0;
        }
      });
    },
    // 获取确认订单数据
    getConfirmOrderData() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          that.companySaleOrderList = resData.companySaleOrderList;
          that.saleOrder = resData.saleOrder;
          that.customerAddress = resData.customerAddress;
          that.saleOrderItemList = resData.saleOrderItemList;
          that.saleOrderItemOosList = resData.saleOrderItemOosList;
          that.discountTicketList = resData.discountTicketList;
        }
      });
    },
    // 确认订单操作
    linkConfirmOrder(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "/confirmOrder",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 确定订单的操作
    confirmOrderAction() {
      let that = this;
      let saleOrderId = this.saleOrder.id;
      let ticketMoneyFlag = this.ticketMoneyFlag;
      let data = {
        saleOrderId: saleOrderId,
      };
      if (ticketMoneyFlag) {
        data.ticketMoneyFlag = 1;
      } else {
        data.ticketMoneyFlag = 0;
      }
      this.confirmOrderStatus = true;
      let url = "/saleOrder/saleOrder/confirmSaleOrder";
      postAction(url, data, true).then((res) => {
        that.confirmOrderStatus = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let saleOrder = resData.saleOrder;
          that.orderNextFlowRouter(saleOrder);
        } else {
          that.$alert(res.message, "确认订单失败：");
        }
      });
    },

    orderNextFlowRouter(saleOrder) {
      let _this = this;
      // 获取订单id，轮询请求订单配货状态
      // 判断订单下一节点状态
      let nextFlow = saleOrder.nextFlow;
      let saleOrderId = saleOrder.id;
      let payOrderId = saleOrder.payOrderId;
      // 判断订单审核状态
      if (nextFlow && nextFlow !== "") {
        if (nextFlow === "audit") {
          // 去审核
          _this.$router.push({
            path: "/orderList",
            query: { state: 2 },
          });
        } else if (nextFlow === "allot") {
          // 获取配货状态
          _this.$router.push({
            path: "/createOrder",
            query: { saleOrderId: saleOrderId },
          });
        } else if (nextFlow === "confirm") {
          // 去确认订单
          // 创建订单成功后跳转确认订单页面
          _this.$router.push({
            name: "confirmOrder",
            query: {
              saleOrderId: saleOrderId,
            },
          });
        } else if (nextFlow === "pay") {
          // 去支付订单
          // 线上支付
          _this.$router.push({
            path: "/orderPay",
            query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
          });
        }
      } else {
        _this.$router.push({
          path: "/orderList",
          query: { state: 3 },
        });
      }
    },
    // 跳转支付操作
    linkPayOrder(item) {},
    // 返回购物车修改
    gobackShoppingCart() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      this.shopLoading = true;
      // 1.先取消订单
      let url =
        "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
      postAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          // 2.返回购物车修改
          that.jionShoppingCart();
        } else {
          this.shopLoading = false;
          that.$message({
            message: res.message,
            offset: 400,
            duration: 3000,
            type: "error",
          });
        }
      });
    },
    // 批量加入购物车操作
    jionShoppingCart() {
      let _this = this;
      let saleOrderItemList = this.saleOrderItemList;
      let shoppingCartType = _this.shoppingCartType;
      let jionProList = [];
      for (let key of saleOrderItemList) {
        let data = {
          uid: key.inventoryUid,
          addPrice: key.confirmMoney,
          amount: key.confirmAmount,
        };
        if (shoppingCartType == 2) {
          data.shoppingCartType = 2;
        }
        jionProList.push(data);
      }
      let url = "/shopping/cart/addList";
      postAction(url, jionProList).then((res) => {
        this.shopLoading = false;
        if (res.code === 200 && res.success) {
          if (shoppingCartType == 2) {
            _this.$router.push("/fastShopping");
          } else {
            _this.$router.push("/shoppingCart");
          }
        }
      });
    },
  },
  // mounted() {
  //   this.$refs.ticketModal.open()
  // }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/detail";
</style>