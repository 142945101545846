<template>
  <div v-if="flashSaleList.length > 0 && flashSaleDetail.nowStatus" class="flash-floor-content">
    <div class="floor-header">
      <div class="title">
        <img src="../../assets/images/index/collage-title.png" alt="">
      </div>
      <div @click="linkFlashSale" class="count-down">
        <i class="tips">秒杀倒计时：</i>
        <div class="time">
          <div class="timebox">
            <span>{{ flashSaleDetail.suH ? flashSaleDetail.suH : '00' }}</span>
            <span>{{ flashSaleDetail.suF ? flashSaleDetail.suF : '00' }}</span>
            <span>{{ flashSaleDetail.suM ? flashSaleDetail.suM : '00' }}</span>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'secshaZone', query: { id: flashSaleDetail.id } }" target="_blank" class="add-more">
        <span>查看更多</span>
        <i class="el-icon-arrow-right"></i>
      </router-link>
    </div>
    <div class="floor-content">
      <el-carousel :interval="8000" indicator-position="none" arrow="always" trigger="click" height="335px" @change="changeSwiper">
        <el-carousel-item v-for="(item, index) of sliceArrs2(flashSaleList, displaySize, startIndex)"
          :key="'xsqgItem' + index">
          <router-link class="goods-content" v-for="(itemAC, indexAC) of item" :key="'xsqgProduct' + itemAC.id + indexAC"
            target="_blank" :to="{ name: 'productDetail', query: { productId: itemAC.productId } }">
            <div class="flash-goods">
              <div class="goods-pic">
                <!-- 查看专区产品遮罩图 -->
                <img v-if="flashSaleDetail.productBackgroundPic && flashSaleDetail.productBackgroundPic !== ''"
                  :src="flashSaleDetail.productBackgroundPic" class="mask-img" alt="产品遮罩图" />
                <!-- 查看产品标签遮罩图 -->
                <img v-else-if="item.productLabelList && item.productLabelList.length > 0
                  && prolabelmask - img(item.productLabelList) !== ''
                  " :src="prolabelmask - img(item.productLabelList)" class="mask-img" alt="产品遮罩图" />

                <img v-lazy="productImg(itemAC)" alt="产品图片" />
              </div>
              <div class="goods-info">
                <div class="price-main">
                  <price-show-text :salesPrice="itemAC.price" :lineThrough="true"
                    :originalPrice="itemAC.originalPrice"></price-show-text>
                </div>
                <p class="name">{{ itemAC.productName }}</p>
                <p class="manufacturer">{{ itemAC.productFormat }}</p>
                <p class="manufacturer">{{ itemAC.productManufacturer }}</p>
                <div class="operate">
                  <span v-if="itemAC.customerBuyAmountAstrict >= 0" class="limit">
                    限购数量：
                    <i>{{ itemAC.customerBuyAmountAstrict }}{{ itemAC.productUnit }}</i>
                  </span>
                  <span v-else></span>
                  <span class="btn">立即抢购</span>
                </div>
              </div>
            </div>
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import PriceShowText from '../product/PriceShowText.vue'
export default {
  components: { PriceShowText },
  props: {
    flashSaleDetail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    flashSaleList: {
      type: Array,
      default: function () {
        return []
      }
    },
    oldFlashSaleList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      startIndex: 0,
      displaySize: 5, // 秒杀拆分
    }
  },
  methods: {
		changeSwiper(current) {
			let index = current;
			if (index == 0) {
				let arr = this.oldFlashSaleList;
				let displaySize = this.displaySize;
				let lessAmount = Math.ceil(arr.length / displaySize) * displaySize - arr.length;
				this.startIndex = (this.startIndex + lessAmount) % arr.length;
			}
		},
    // 循环填充数组
    sliceArrs2(array, size, startindex) {
      let result = [];
      let arr = this.oldFlashSaleList;
      if (array.length > 3) {
        let srcarr = [];
        if (array.length < size) {
          result.push(arr.slice(0, arr.length));
          return result;
        }
        for (let x = 0; x < Math.ceil(array.length / size) * size; x++) {
          srcarr.push(arr[(x + startindex + array.length) % array.length]);
          if ((x + 1) % size == 0) {
            result.push(srcarr);
            srcarr = [];
          }
        }
      } else {
        result.push(array);
      }
      return result;
    },
    // 跳转秒杀专区
    linkFlashSale() {
      let flashSaleDetail = this.flashSaleDetail;
      let text = this.$router.resolve({ name: 'secshaZone', query: { id: flashSaleDetail.id } });
      window.open(text.href, '_blank');
    },
    // 显示产品图片
    productImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let imgsArr = item.productPicUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 轮播产品楼层样式
    floorStyle(item) {
      let data = item.floorDetailList;
      if (data.length > 5) {
        let oldArrs = this.sliceArrs(data, 5);
        let oldL = oldArrs.length;
        let Fwidth = (oldL + 2) * 1190;
        let initialL = -1190;
        let style = {
          width: Fwidth + "px",
          left: initialL + "px",
        };
        return style;
      }
    },
    // 均分数组
    sliceArrs(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/index/flash-sale-floor";
</style>