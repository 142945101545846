<template>
  <div class="container">
    <div class="process-content container_box">
      <div class="logo-box">
        <router-link to="/">
          <img :src="`${$configOptions.resourcePath}/config/logo/company-logo.png`" alt="" />
        </router-link>
        <div class="process-nodes-title">
          {{ nodesName }}
        </div>
      </div>
      <div class="excel-operate">
        <a @click="showExcelImportModal" href="javascript:;">
            EXCEL导入商品
        </a>
      </div>
      <div class="process-nodes">
        <div
          v-for="(item, index) in processNodes"
          :key="index"
          class="node-items"
          :class="{ selected: nodesNumber === index + 1 }"
        >
          <p class="nodes-number">{{ index + 1 }}</p>
          <p class="nodes-title">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nodesName: {
      require: false,
      type: String,
      default: "购物车",
    },
    nodesNumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      processNodes: [
        { title: "查看购物车" },
        { title: "确认订单信息" },
        { title: "成功提交订单" },
      ],
    };
  },
  methods: {
    showExcelImportModal() {
        this.$emit('showExcelImportModal')
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 109px;
  padding-top: 20px;
  box-shadow: rgb(242, 242, 242) 0px 2px 4px 0px;
  margin-bottom: 2px;

  .process-content {
    .logo-box {
      float: left;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;

      &::after {
        display: block;
        content: "";
        clear: both;
      }

      img {
        height: 50px;
        width: auto;
        margin-right: 50px;
      }

      .process-nodes-title {
        font-size: 18px;
        color: #181818;
        font-weight: 400;
        border-left: 1px solid #333;
        padding-left: 50px;
      }
    }
    .excel-operate {
      float: left;
      height: 70px;
      line-height: 70px;
      a {
        margin-left: 20px;
        color: $act-color;
        &:hover {
            text-decoration: underline;
        }
      }
    }
    .process-nodes {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;

      .node-items {
        width: 160px;
        height: 45px;
        margin-top: 15px;
        border-top: 5px solid #ccc;
        text-align: center;
        font-size: 14px;
        position: relative;

        &.selected {
          border-color: $base-color;

          .nodes-number {
            width: 38px;
            height: 38px;
            background-color: $base-color;
            border-radius: 50%;
            border: 6px solid #99d1bf;
            text-align: center;
            line-height: 25px;
            top: -22px;
          }

          .nodes-title {
            color: $base-color;
          }
        }

        .nodes-number {
          margin: 0 auto;
          width: 28px;
          height: 28px;
          background-color: #ccc;
          border-radius: 50%;
          text-align: center;
          line-height: 28px;
          position: absolute;
          top: -17px;
          left: 50%;
          transform: translate(-50%);
          color: #ffffff;
        }

        .nodes-title {
          font-size: 14px;
          text-align: center;
          color: #999;
          height: 65px;
          line-height: 65px;
        }
      }
    }
  }
}
</style>